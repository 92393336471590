<div class="goal-details">
    
    <div class="line1">
        <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Kompetencia - [értékelések eredménye]</mat-label>
            <mat-select [disabled]="!data.isSelf || currentPeriod.isClosed" [(value)]="goal.questionId" (selectionChange)="saveGoal(goal)" name="question">
                <mat-option [value]="null">-</mat-option>
                <mat-optgroup *ngFor="let qSet of currentPeriod.questionSets" [label]="qSet.name">
                    <mat-option *ngFor="let question of qSet.childQuestions" [value]="question.id" [title]="question.name + ' - [' + question.ratingAverage + ']'">{{ question.name + ' - [' + question.ratingAverage + ']' }}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    
        <div class="flex-h part2">
            <mat-form-field appearance="outline" class="field-goal">
                <mat-label>Cél</mat-label>
                <input matInput [disabled]="!data.isSelf || currentPeriod.isClosed" decimalMarker="," mask="separator.1" separatorLimit="6" title="Kompetencia cél" 
                [(ngModel)]="goal.targetValue" 
                [ngModelOptions]="{allowInvalid: false}"
                (ngModelChange)="goalChanged()">
            </mat-form-field>
            
            <div class="form-field flex-v justify-content-center field-kpi">
                <mat-checkbox 
                    name="isKpi"
                    [(ngModel)]="goal.isKpi"
                    [disabled]="!data.isSelf || currentPeriod.isClosed || goal.questionId == null || goal.targetValue == null || goal.targetValue === ''"
                    labelPosition="before"
                    class="label-wrap label-lh-18"
                    title="Ezt kiválasztva a következő értékelésnél az új kompetencia célból KPI lesz"
                    (change)="saveGoal(goal)">KPI</mat-checkbox>
            </div>
        </div>
    </div>

    <div class="goal-row">
        
        <div class="text-area-row">
            <mat-form-field appearance="outline" class="width-max">
                <mat-label>Egyéni fejlődési célkitűzés: mit szeretnél elérni?</mat-label>
                <textarea matInput rows="3" autocomplete="off"
                    [(ngModel)]="goal.goalText.name"
                    [ngModelOptions]="{allowInvalid: false }"
                    (ngModelChange)="goalChanged()"
                    [disabled]="!data.isSelf || currentPeriod.isClosed"
                ></textarea>
            </mat-form-field>
        
            <button mat-flat-button color="primary" [disabled]="!data.isSelf" (click)="selectGoal()" title="Célok kiválasztása">
                minta célkitűzések
            </button>
        </div>

    </div>

    <div class="goal-row">
        <app-group-goal-field 
            [data]="data"
            [isDisabled]="!data.isSelf || currentPeriod.isClosed"
            [groupGoal]="goal.groupGoal"
            (change)="groupGoalChanged($event)"
        ></app-group-goal-field>
    </div>
 
    <!-- floating button -->
    <button mat-icon-button color="primary" class="goal-delete hidden-print icon-button" *ngIf="data.isSelf" [disabled]="loading || currentPeriod.isClosed" (click)="deleteGoal(goal.id)" title="Célkitűzés törlése">
        <mat-icon>delete</mat-icon>
    </button>
</div>

<div>
    <div class="actions-title">Megvalósítás, tevékenységek</div>

    <div class="panel-actions panel-header">
        <app-goal-action-edit
            [loading]="data.loading"
            [isSelf]="data.isSelf"
            [action]="action"
            [leaders]="leaders"
            [currentPeriod]="currentPeriod"
            [goal]="goal"
            (deleted)="actionDeleted($event)"
            *ngFor="let action of goal.actions"
        ></app-goal-action-edit>
    </div>

    <div class="action-buttons">
        <button mat-flat-button color="accent" [disabled]="!data.isSelf || currentPeriod.isClosed || goal.id === 0 || (goal.questionId == null && !goal.goalText.name)" (click)="addAction(goal)" class="show-add-button">
            <mat-icon>add</mat-icon>
            Tevékenység
        </button>        
    </div>
    
</div>
