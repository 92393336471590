import { formatDate } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Employee } from 'src/app/models/employee.model';
import { IdName } from 'src/app/models/general/id-name.model';
import { ReviewPeriodEmployeeForEmployee } from 'src/app/models/review-period-employee-for-employee.model';
import { Search } from 'src/app/models/search.model';
import { AssessmentService, EmployeeService, ReviewPeriodService } from 'src/app/services';
import { SearchComponent } from 'src/app/shared/components';
import { ActionStateModel, ListItem, SearchConfig, SearchOption } from 'src/app/shared/models';

@Component({
  selector: 'app-multi-item-selector',
  templateUrl: './multi-item-selector.component.html',
  styleUrls: ['./multi-item-selector.component.scss']
})
export class MultiItemSelectorComponent implements OnInit, AfterViewInit {

  private readonly itemsSubject = new BehaviorSubject<ListItem<IdName>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();
  public actionState = {editMode: true, currentItemIndex: null} as ActionStateModel

  @Input()
  public rpId = 0;

  @Input()
  public mode: 'ReviewPeriod' | 'RPEmployee';

  @Input()
  public hiddenRows: number[] = [];

  @Output()
  public add = new EventEmitter<IdName>();

  @Output()
  public remove = new EventEmitter<IdName>();
  
  public searchConfig: SearchConfig<IdName, number>;  

  public searchRpConfig: SearchConfig<IdName, number> = {
    mapItem: (model: IdName) => {
      return {
        id: model.id, 
        text: `${model.name} (${model.description})`,
        dropText: `${model.name} (${model.description})`,
        itemSource: model } as SearchOption<IdName, number>;
    },
    getList: this.reviewPeriodService.getListAsIdName,
  };

  public searchRPEmployeeConfig: SearchConfig<IdName, number> = {
    mapItem: (model: IdName) => {
      return {
        id: model.id, 
        text: `${model.name} (${model.description})`,
        dropText: `${model.name} (${model.description})`,
        itemSource: model } as SearchOption<IdName, number>;
    },
    getList: this.assessmentService.getListForEmployee
  };

  @ViewChild('search')
  search: SearchComponent<IdName, number>;
  
  constructor(private reviewPeriodService: ReviewPeriodService, private assessmentService: AssessmentService) { 

  }

  ngOnInit(): void {

    if (this.mode === 'ReviewPeriod') {      
      this.searchConfig = this.searchRpConfig;
      this.searchRpConfig.options = {reviewPeriodId: this.rpId} as Search;
    } else if (this.mode === 'RPEmployee') {
      this.searchRPEmployeeConfig.options = {reviewPeriodId: this.rpId} as Search;
      this.searchConfig = this.searchRPEmployeeConfig;
    }
    
  }

  ngAfterViewInit(): void {
    if (this.hiddenRows.length > 0) {
      this.search.hiddenRows = this.hiddenRows;
    }
  }

  public addItem(option: any) {
      let list = this.itemsSubject.getValue();

      this.add.emit(option.itemSource);

      let item = new ListItem<IdName>(option.itemSource);
      list.push(item);

      if (this.mode === 'RPEmployee')
      {
        this.search.hiddenRows = list.map(x => x.itemSource.id).concat(this.hiddenRows);
      }

      this.itemsSubject.next(list);
  }

  public removeItem(item) {
    this.remove.emit(item.itemSource);

    let list = this.itemsSubject.getValue();
    list = list.filter(x => x.itemSource.id !== item.itemSource.id);

    if (this.mode === 'RPEmployee')
    {
        this.search.hiddenRows = list.map(x => x.itemSource.id).concat(this.hiddenRows);
    }

    this.itemsSubject.next(list);
  }
  
  // public getRPEmployeeDetails(data: ReviewPeriodEmployeeForEmployee): string {
  //   return ` (${data.email}) kitöltés dátuma: ${formatDate(data.closedOn, 'yyyy.MM.dd', 'en-US')}`;
  // }

  public isAllowedOnList(item: IdName): boolean {
    let items = this.itemsSubject.getValue();

    return !items.find(x => x.itemSource.id === item.id);
  }

}
