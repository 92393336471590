<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>

    <div class="msg">
        {{ showMainMessage() }}
    </div>

    <div class="details">                
        <div *ngFor="let resultItem of resultItems" class="result-block">
            <h4>{{ resultItem.title }}</h4>
            <div class="subtitle">{{ resultItem.subtitle }}</div>
            <div class="details-content-item" *ngFor="let key of resultItem.keys">
                <div class="detail-message" [ngClass]="{'text-warning': !resultItem.isError, 'text-danger': resultItem.isError }">{{ getDetailMessage(key) }}</div>

                <ul *ngIf="resultItem.items[key] && resultItem.items[key].length > 0" class="employee-list">
                    <li *ngFor="let employee of resultItem.items[key]" class="employee-item">
                        {{ employee.name}} <span *ngIf="employee.email">({{ employee.email }})</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="flex-h flex-end">
    <button mat-flat-button color="primary" (click)="continue()" *ngIf="!hasError && hasWarning"  type="submit">Folytatás</button>
    <button mat-flat-button color="primary" (click)="close()" type="submit">Befejezés</button>
</div>
