import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings', 
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  isReviewPeriodCardEdit = false;

  constructor() {}

  ngOnInit(): void {}

  onReviewPeriodCardEditToggle(val: boolean) {
    this.isReviewPeriodCardEdit = val;
  }
}
