import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { of, catchError } from 'rxjs';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { NgChartsModule } from 'ng2-charts';
import { NgxPermissionsModule } from 'ngx-permissions';
import { JwtModule } from './jwt/angular-jwt.module';
import { JWT_OPTIONS } from './jwt/jwtoptions.token';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';

// Services
import {
    StateService,
    AppInitService,
    AuthService
} from './services';

import {
  EmployeeNamePipe,
  EmployeeDisplayNamePipe,
  EmployeeEmailPipe,
  EmployeeTypePipe,
  EmployeeReviewStatePipe,
  UserDisplayNamePipe,
  EmployeeIdentifierPipe,  
  NoZeroPipe,
  ReviewPeriodTypePipe,
  AssessmentStatusPipe,
  PeriodTypeDatePipe,
  ReviewPeriodNamePipe,
  RoleDisplayNamePipe,
  AssessmentStatusNamePipe,  
} from './pipes';

// Components
import { 
    AppComponent,    
    HeaderComponent,
    SubHeaderComponent,
    MenuComponent,
    ContactDetailsComponent,
    KpiComponent,
    EmployeePositionComponent,
    ReviewPeriodComponent,
    ReviewPeriodEditComponent,
    EmployeeSearchComponent,    
    EmployeeGroupComponent,
    EmployeeHierarchyComponent,
    LeaderComponent,
    ChangePasswordComponent,
    EmployeeReviewPeriodComponent,
    MissingReviewsComponent,
    GoalEditComponent,    
    GoalActionEditComponent,
    GoalActionQuartersComponent,    
    QuestionComponent,
    KpiFormComponent,
    ProductGuideComponent,
    PageProgressComponent,
    GroupGoalsComponent,
    GroupGoalFieldComponent,    
    GroupGoalEditComponent,
    UserRolesComponent,
    BackNavComponent,
    GoalKpiEditComponent,
    LegendComponent,
    MultiItemSelectorComponent,
    SystemComponent,
    CompletedReviewsComponent,
    TextEditPanelComponent,
    EmployeeListComponent,
    DashboardIndicatorGroupComponent,
    AssessmentListItemComponent,
    AssessmentListItemDetailsComponent
  } from './components';

// Dialogs 
import {
  EmployeeGroupAddComponent,
  UserBulkInsertComponent,
  EmployeeReviewDialogComponent,
  ActionForReviewPeriodDialogComponent,
  CompanyUserRoleDialogComponent,
  KpiTypeAddDialogComponent,
  ErrorDetailsDialogComponent,
  AboutEmployeeAddDialogComponent,
  CompanyEditDialogComponent,
  EmailAdminDialogComponent,
  UploadDialogComponent,
} from './dialogs';

// Pages
import {     
    AssessmentComponent,
    AssessmentsComponent,
    ConfirmEmailComponent,
    CreateRoleUserComponent,
    GoalsComponent,
    LoginComponent,    
    ReportsComponent,
    EmployeeComponent,
    EmployeeAddComponent,
    SettingsComponent,
    PositionComponent,
    PasswordResetComponent,
    CompanySelectorComponent,
    SimpleSearchTestComponent,
    CancelUserDeleteComponent
 } from './pages';

 // Reports
 import {
    ReviewDateComponent,
    FreetextPanelComponent,

    ReportComponent,
    PersonalityTestDetailsComponent,
    PersonalityTestDiagramComponent,
    ReportFrameComponent,
    TeamDysfunctionsComponent,
    TeamStressMapComponent,
 } from './reports';

import { SharedModule } from './shared/shared.module';
import { TokenService } from './shared/services';

import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { environment } from 'src/environments/environment';
import { NgxGaugeModule } from 'ngx-gauge';

 const cookieConfig:NgcCookieConsentConfig = 
    {
    "cookie": {
      "domain": environment.config.domain
    },
    "position": "bottom-right",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#3a3a50",
        "text": "#ffffff",
        "link": "#ffffff"
      },
      "button": {
        "background": "#D94646",
        "text": "#ffffff",
        "border": "transparent"
      }
    },
    "type": "info",
    "content": {
      "message": "Az adatkezelési tájékoztatónkat itt tudja megtekinteni:",
      "dismiss": "Rendben",
      "deny": "Refuse cookies",
      "link": "Tájékoztató",
      "href": "/assets/adatkezelesi-tajekoztato.pdf",
      "policy": "Cookie Policy"
    }
  }

  export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

  export function appInit(appInitService: AppInitService) {
      return () => {      
        return appInitService.load().toPromise();
      }
  }
  
  export function userInit(authService: AuthService) {
    return () => {      
      return authService.loadUser().pipe(
        catchError(error => {        
          authService.logOut();
          return of(null);
        })
      ).toPromise();
    }
  }

export function jwtOptionsFactory(tokenService: TokenService, stateService: StateService) {
    return {
      tokenGetter: () => {
        return tokenService.getToken();
      },
      allowedDomains: () => {        
        const host = new URL(stateService.state.config.rest_end_point).host;
        return [host];
      }
    };
}

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    SharedModule,
    AppRoutingModule,
    NgChartsModule,
    NgxGaugeModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    NgxPermissionsModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenService, StateService]
      }
    })
  ],
  providers: [
      {
        provide: APP_INITIALIZER,
        useFactory: userInit,
        multi: true,
        deps: [AuthService]
        },
        {
          provide: APP_INITIALIZER,
          useFactory: appInit,
          multi: true,
          deps: [AppInitService]
        },
        provideEnvironmentNgxMask(),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true
        }
  ],
  declarations: [

    // Pipes
    EmployeeNamePipe,
    EmployeeDisplayNamePipe,
    EmployeeEmailPipe,
    EmployeeTypePipe,
    UserDisplayNamePipe,
    RoleDisplayNamePipe,
    EmployeeReviewStatePipe,
    EmployeeIdentifierPipe,
    ReviewPeriodTypePipe,
    ReviewPeriodNamePipe,
    PeriodTypeDatePipe,
    AssessmentStatusPipe,
    AssessmentStatusNamePipe,
    NoZeroPipe,

    //Dialogs
    EmployeeGroupAddComponent,
    UserBulkInsertComponent,
    EmployeeReviewDialogComponent,
    ActionForReviewPeriodDialogComponent,
    ErrorDetailsDialogComponent, 
    AboutEmployeeAddDialogComponent,
    KpiTypeAddDialogComponent, 
    CompanyUserRoleDialogComponent, 
    CompanyEditDialogComponent,
    EmailAdminDialogComponent,
    UploadDialogComponent,

    //Public
    AppComponent,
    LoginComponent,
    PasswordResetComponent,
    CancelUserDeleteComponent,

    //Common
    ReviewPeriodComponent,
    EmployeeSearchComponent,
    ReportFrameComponent, 
    PersonalityTestDiagramComponent, 
    PersonalityTestDetailsComponent,  
    BackNavComponent,
    LegendComponent, 
    MultiItemSelectorComponent, 
    PageProgressComponent, 
    TextEditPanelComponent,

    //LoggedIn Users
    MenuComponent,
    HeaderComponent,
    SubHeaderComponent,
    CompanySelectorComponent,
    ConfirmEmailComponent,
    ReportComponent,
    ReportsComponent,

    //LoggedIn Assessment
    AssessmentsComponent,
    AssessmentListItemComponent,
    AssessmentListItemDetailsComponent,
    AssessmentComponent,
    QuestionComponent, 
    ProductGuideComponent, 
    FreetextPanelComponent,

    //LoggedIn - Employees
    EmployeeComponent,
    ContactDetailsComponent,
    PositionComponent,
    UserRolesComponent, 
    EmployeeAddComponent,
    EmployeeHierarchyComponent,
    EmployeePositionComponent,
    CreateRoleUserComponent,
    LeaderComponent,
    ChangePasswordComponent,

    //LoggedIn - Goals
    GoalsComponent,
    GoalEditComponent,
    GoalActionEditComponent,
    GoalActionQuartersComponent,    
    GroupGoalsComponent, 
    GroupGoalFieldComponent, 
    GroupGoalEditComponent,
    KpiFormComponent, 
    KpiComponent,
    GoalKpiEditComponent,
    
    //Admin
    ReviewPeriodEditComponent, 
    SystemComponent,
    EmployeeGroupComponent,
    EmployeeGroupAddComponent,
    EmployeeReviewPeriodComponent,  
    MissingReviewsComponent,
    CompletedReviewsComponent,
    SettingsComponent,
    EmployeeListComponent,
    DashboardIndicatorGroupComponent,

    TeamDysfunctionsComponent, 
    TeamStressMapComponent,
    
    //--
    SimpleSearchTestComponent, 
    ReviewDateComponent, 
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {   
}
