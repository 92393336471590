<div class="container-fluid main-container">
    <div class="w-full-hd">
        <div class="row" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
            <div class="col-xl-4" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                <app-review-period [configMode]="true"></app-review-period>
            </div>
            <div class="col-xl-8" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                <!-- <app-dashboard-indicator-group></app-dashboard-indicator-group> -->
            </div>
        </div>
        <div class="row" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
            <div class="col-xl-4">
                <app-employee-review-period></app-employee-review-period>
            </div>
            <div class="col-xl-4" >
                <app-missing-reviews></app-missing-reviews>
            </div>
            <div class="col-xl-4" >
                <app-completed-reviews></app-completed-reviews>
            </div>
        </div>
        <div class="row" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
            <div class="col-xl-8" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                <app-employee-list></app-employee-list>
            </div>
            <div class="col-xl-4" *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                <app-employee-group></app-employee-group>
            </div>
        </div>
    </div>
</div>
