<div [formGroup]="editForm" class="flex-vertical">
                
    <input type="hidden" name="id" formControlName="id">    
    
    <mat-form-field appearance="outline" class="flex-fill">
        <mat-label>Típus</mat-label>
        <mat-select formControlName="reviewType" disableOptionCentering class="drop-fix" (selectionChange)="reviewTypeChanged()" required>
            <mat-option *ngFor="let item of reviewTypes" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>       
    </mat-form-field>
   
    <mat-form-field appearance="outline" class="flex-fill" *ngIf="editForm.controls['reviewType']?.value == reviewTypesEnum.TER">
        <mat-label>Előző értékelés (sorozat folytatása esetén)</mat-label>
        <mat-select formControlName="previousId" disableOptionCentering class="drop-fix" title="Itt adható meg ha egy Panoráma értékelésnek volt már korábban előzménye rögzítve. (Q1, Q2, stb.) Ebből tudjuk, melyik értékelésből kell átmásolni az előzményeket, pl: pl. csoporttagság">
            <mat-option *ngFor="let item of prevReviewPeriodList" [value]="item.id">{{ item | periodName }}</mat-option>
        </mat-select>
        <mat-error msg></mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Érvényességi időszak</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="startDate" placeholder="Kezdés">
          <input matEndDate formControlName="endDate" placeholder="Befejezés">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline" title="Nevezzük el az értékelést, hogy megkülönböztethető legyen később a listában, pl: 2023 Q1">
        <mat-label>Megnevezés</mat-label>
        <input matInput type="text" name="name" formControlName="name" required>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Leírás</mat-label>
        <input matInput type="text" name="description" formControlName="description">
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-max hidden-print">
        <mat-label>Instrukciók</mat-label>
        <textarea matInput name="instructions" formControlName="instructions" autocomplete="off"></textarea>
    </mat-form-field>

    <div *ngIf="assessmentActions && assessmentActions.options" class="settings-panel">
        <div class="panel-title">Általános beállítások 
            <ng-container *ngIf="!allowSettingsEdit">
                <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
            </ng-container>
        </div>
        <div class="panel-content simple-border">            
            <mat-checkbox class="multiline" formControlName="isPositionBasedAssessment" *ngIf="assessmentActions.isPositionBasedAssessment" [disableControl]="!allowSettingsEdit">Panoráma</mat-checkbox>
            <mat-checkbox class="multiline" formControlName="isSpecialQuestionSetBasedAssessment" *ngIf="assessmentActions.isSpecialQuestionSetBasedAssessment" [disableControl]="!allowSettingsEdit">Aura</mat-checkbox>
            <mat-checkbox class="multiline" formControlName="isFreeTextAssessmentAllowed" *ngIf="assessmentActions.isFreeTextAssessmentAllowed" [disableControl]="!allowSettingsEdit">szabadszavas visszajelzés lehetősége</mat-checkbox>
            <mat-checkbox class="multiline" formControlName="copyEmployees" *ngIf="assessmentActions.copyEmployees && addMode" [disableControl]="!allowSettingsEdit" title="Új értékelés esetén a cég minden dolgozóját automatikusan felveszi az értékelésbe. Panoráma értékelés folytatása esetén, viszont az *Előző Értékelés* mezőnél kiválasztott értékelésből fogja automatikusan felvenni a dolgozókat.">
                dolgozók automatikus hozzáadása</mat-checkbox>
            <mat-checkbox class="multiline" formControlName="updateRpe" *ngIf="assessmentActions.updateRpe && !addMode" [disableControl]="!allowSettingsEdit">ki-kit értékel frissítése</mat-checkbox>
        </div>
    </div>

    <div formArrayName="questionSets" class="settings-panel">
        <div class="panel-title">Kérdőívek 
            <ng-container *ngIf="!allowSettingsEdit">
                <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
            </ng-container>
        </div>
        <div class="panel-content simple-border scroll-box max-height-300">
            <ng-container *ngFor="let child of questionSets.controls; let i = index">
                <ng-container [formGroupName]="i">
                    <mat-checkbox class="multiline" formControlName="isSelected" [ngClass]="{'subQuestionSet': isSubQuestionSet(child.value)}" [disableControl]="!allowSettingsEdit" (change)="toggleModuleSettings(child.value)">{{ getQuestionSetName(child.value) }} <span class="desc">{{ getQuestionSetDesc(child.value) }}</span></mat-checkbox>
                </ng-container>

            </ng-container>
           
        </div>
    </div>     
    
    <div formArrayName="questionSettings" class="settings-panel" *ngIf="assessmentActions && assessmentActions.questionModuleSettings && questionSettingsArray.controls.length > 0">
        <div class="panel-title">Kérdés modul beállítások 
            <ng-container *ngIf="!allowSettingsEdit">
                <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
            </ng-container>
        </div>
        <div class="panel-content simple-border">
            <div *ngFor="let child of questionSettingsArray.controls; let i = index">
                <div class="settings-group" [formGroupName]="i">
                    <div class="group-title">{{ removeHtml(child.get("questionName").value) }}</div>
                    <mat-checkbox class="multiline" formControlName="isSelfAssessmentAllowed" [disableControl]="!allowSettingsEdit">önértékelés</mat-checkbox>
                    <mat-checkbox class="multiline" formControlName="isLeaderAssessmentAllowed" [disableControl]="!allowSettingsEdit">beosztottak értékelik a vezetőket</mat-checkbox>
                    <mat-checkbox class="multiline" formControlName="isPeerAssessmentAllowed" [disableControl]="!allowSettingsEdit">peer-ek értékelik egymást</mat-checkbox>
                    <mat-checkbox class="multiline" formControlName="isSpecialQuestion" [disableControl]="!allowSettingsEdit" title="Ezzel jelezhetjük, hogy az adott kérdésmodul csak a résztvevő személyek egy bizonyos csoportja esetén jelenjen meg. Pl: Aura kérdőív, csak a vezetők esetén jelenik meg.">
                        egyénileg célzott kérdésmodul (pl: Aura)</mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <mat-form-field appearance="outline" class="flex-fill">
        <mat-label>Állapot</mat-label>
        <mat-select formControlName="status" disableOptionCentering class="drop-fix" required>
            <mat-option *ngFor="let status of statusList" [value]="status.id">{{ status.name }}</mat-option>
        </mat-select>
        <mat-error msg></mat-error>
    </mat-form-field>
   
    <div class="action-buttons flex-end">
        <button mat-flat-button color="primary" (click)="save()" [disabled]="editForm.invalid || (allowSettingsEdit && !isQuestionSetSelected)">Ok</button>
        <button mat-flat-button (click)="cancel()">Mégsem</button>
    </div>
   
</div>

<ng-template #reviewInProgressInfo>
    <mat-icon matTooltip="Létező értékelés esetén ezen beállítások már nem módosíthatók.">error_outline</mat-icon>
</ng-template>
