<div class="input-with-buttons">
    <mat-form-field [appearance]="appearance" *ngIf="isAllowSearch" class="searchfield" [ngClass]="{'input-lg': isLargeInput}">
        <mat-label *ngIf="title" >{{ title }}</mat-label>
        <input 
                matInput
                *ngIf="controlType === 'input'"
                type="text"
                [placeholder]="placeholder"
                [formControl]="searchControl"
                [readonly]="disabled"
                [matAutocomplete]="auto"
                [disableControl]="disabled"
                (keydown.enter)="onAdd()"
                (blur)="onTouched()"
                autocomplete="new-password"
                >
        <textarea matInput 
                *ngIf="controlType === 'textarea'"
                [placeholder]="placeholder"
                [formControl]="searchControl"
                [readonly]="disabled"
                [disableControl]="disabled"
                [matAutocomplete]="auto"
                (blur)="onTouched()"
                autocomplete="off"
                [rows]="rows"
                (change)="onTextChanged($event)"
                ></textarea>
        <div *ngIf="{loading: loading$ | async} as loadingContext" matSuffix class="suffix-panel">
            <mat-icon matSuffix *ngIf="showSearchIcon && !loadingContext.loading">search</mat-icon>
            <mat-spinner diameter="18" *ngIf="loadingContext.loading" matSuffix></mat-spinner>
        </div>
            
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOptionSelected($event.option.value)" panelWidth="400px">
            <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option" [ngClass]="{'multi-line': multilineDropdown, 'hidden': isHidden(option)}">
                {{ option.dropText }}
            </mat-option>        
        </mat-autocomplete>
    </mat-form-field>
    
    <div class="search-action-buttons" *ngIf="showAddButton || isCustomAdd">
        <button *ngIf="!isCustomAdd && isAllowSearch && showAddButton" [disabled]="disabled || disabledAddButton || searchControl.pristine" mat-icon-button color="primary" (click)="onAdd()" [title]="addButtonTooltip">
            <mat-icon>{{ addIcon }}</mat-icon>
        </button>

        <ng-content *ngIf="isCustomAdd" select="[slot=custom-add]"></ng-content>
    </div>
</div>

