import { ReviewPeriodStatusEnum } from '../enums/review-period-status.enum';
import { EmployeeForAssessments } from './employee-for-assessments.model';
import { ISelectable } from './general/selectable.model';
import { RatingSet } from './rating-set.model';
import { ReviewPeriodPositionQuestionSet } from './review-period-position-questionset.model';
import { ReviewPeriodQuestionSettings } from './review-period-question-settings.model';
import { Temporal } from "@js-temporal/polyfill";

export interface ReviewPeriod extends ISelectable {
    id?: number;
    companyName: string;
    employees: EmployeeForAssessments[];

    name: string;
    description: string;
    instructions: string;

    startDate?: Temporal.PlainDate;
    endDate?: Temporal.PlainDate;
    createdOn?: Date;

    reviewType: number;

    isFreeTextAssessmentAllowed: boolean;    

    isPositionBasedAssessment: boolean;
    isGoalsEnabled: boolean;
    isSpecialQuestionSetBasedAssessment: boolean;

    panoramaAuraStartDate?: Temporal.PlainDate;
    panoramaAuraEndDate?: Temporal.PlainDate;
    goalsStartDate?: Temporal.PlainDate;
    goalsEndDate?: Temporal.PlainDate;

    //actions
    copyEmployees: boolean;
    updateRpe: boolean;

    status: ReviewPeriodStatusEnum;

    nextId?: number;
    previousId?: number;

    ratingSet: RatingSet;

    questionSettings: ReviewPeriodQuestionSettings[];    
    selectedQuestionSets?: ReviewPeriodPositionQuestionSet[];
}

export class ReviewPeriodModel implements ReviewPeriod {
    id = 0;
    companyName = '';
    employees: EmployeeForAssessments[] = [];

    name = '';
    description = '';
    instructions = '';

    startDate?: Temporal.PlainDate = null;
    endDate?: Temporal.PlainDate = null;
    createdOn?: Date = null;

    reviewType: number = 1;
    
    isFreeTextAssessmentAllowed = false;    

    isPositionBasedAssessment = false;
    isGoalsEnabled = false;
    isSpecialQuestionSetBasedAssessment = false;

    panoramaAuraStartDate?: Temporal.PlainDate = null;
    panoramaAuraEndDate?: Temporal.PlainDate = null;
    goalsStartDate?: Temporal.PlainDate = null;
    goalsEndDate?: Temporal.PlainDate = null;

    //actions
    copyEmployees = false;
    updateRpe = false;

    status = ReviewPeriodStatusEnum.New;

    nextId?: number = null;
    previousId?: number = null;

    ratingSet = null;   //?

    questionSettings: ReviewPeriodQuestionSettings[] = [];
    selectedQuestionSets: ReviewPeriodPositionQuestionSet[] = [];
}