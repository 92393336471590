import { Component, Inject, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Company } from 'src/app/models/company.model';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { CompanyService } from 'src/app/services';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-company-edit-dialog',
  templateUrl: './company-edit-dialog.component.html',
  styleUrl: './company-edit-dialog.component.scss'
})
export class CompanyEditDialogComponent {
  public mainForm: UntypedFormGroup;

  public type: 'edit' | 'create' = 'create';

  public get initValue(): string {
    return this.type === 'edit' ? this.appState.company.value?.name : '';
  }
  
  constructor(
    private fb: UntypedFormBuilder, 
    public dialogRef: MatDialogRef<CompanyEditDialogComponent>,   
    public appState: AppState, 
    private companyService: CompanyService, 
    private msgService: ErrorMessageService,
    @Inject(MAT_DIALOG_DATA) public data: Company
  ) { 
    if (data) {
      this.type = 'edit';
    }
  }

  ngOnInit(): void {
    this.mainForm = this.createForm();

    if (this.type === 'edit') {
        this.mainForm.patchValue(this.data);
    }
  }

  public createForm(): UntypedFormGroup {
    return this.fb.group({
      'name': ['', [Validators.required]],
    });
  }

  public save() {    
    let formValue = this.mainForm.value;

    //if update
    if (this.type === 'edit')
    {
      let model = { ...this.data, name: formValue.name } as Company;

      this.companyService.update(model).subscribe({ next: () => {        
          this.editComplete(model, 'A cég módosítva.');
          this.dialogRef.close(true);
        }, error: (err: IResultModel<ResultModel>) => {                   
          this.msgService.handleError<ResultModel>(err);
      }});
    }
    else // new
    {
      let model = { name: formValue.name } as Company;

      this.companyService.add(model).subscribe({next: (result) => {
        this.editComplete(result, 'A cég sikeresen létrehozva.');
      }, error: (err) => {
        this.msgService.showSnackBarMessage('unsuccessfulAction');
      }});
    }
        
  }

  private editComplete(model: Company, msg: string) {
    if (model.id === this.appState.company.value?.id)
      this.appState.company.value = model;
    
    this.msgService.showSnackBarInfo(msg);
  }
}
