import { AssessmentStatusEnum } from "../enums/assessment-status.enum";
import { AssessmentTypeEnum } from "../enums/assessment-type.enum";
import { Employee } from "./employee.model";
import { ReviewPeriod } from "./review-period.model";

export class ReviewPeriodEmployee {
    reviewPeriodEmployeeId?: number;

    reviewPeriod: ReviewPeriod;
    byEmployee: Employee;
    aboutEmployee: Employee;

    endDate?: Date;
    closedOn?: Date;

    assessmentType?: AssessmentTypeEnum;
    status: AssessmentStatusEnum;

    deadline?: Date;
}