import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, tap } from 'rxjs';
import { ReviewPeriodStatusEnum } from 'src/app/enums/review-period-status.enum';
import { Employee } from 'src/app/models/employee.model';
import { Report } from 'src/app/models/report.model';
import { PersonLegend } from 'src/app/models/report/person-legend.model';
import { ReportOptions } from 'src/app/models/report/report-options.model';
import { State } from '../../models/state.model';
import { ReportService, StateService } from '../../services';
import { TextGuideService } from 'src/app/services/text-guide.service';
import { QuestionSetForReport } from 'src/app/models/question-module-report.model';
import { ViewHelper } from 'src/app/shared/helpers';
import { ReviewPeriod } from 'src/app/models/review-period.model';

@UntilDestroy()
@Component({
    selector: 'report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
        
    public state: State;
    public data$: Observable<Report>;
    public legend: PersonLegend[] = [];
    public reportOptions: ReportOptions;
    public isError = false;
    public errorMessage: string;
    
    constructor(
        private reportService: ReportService, 
        stateService: StateService,
        private route: ActivatedRoute,
        private guideService: TextGuideService) {
        this.state = stateService.state;
    }

    ngOnInit() {

        this.route.params
        .pipe(untilDestroyed(this))
        .subscribe(params => {
            if (params['employeeId'] && params['reviewPeriodId'])
            {
                this.reportOptions = this.guideService.getGuideAsReportOptions('assessment:TER');

                this.data$ = this.reportService.getReport(params['employeeId'], params['reviewPeriodId']).pipe(
                    tap(data => {
                      
                        // this.isError = true;
                        // if (err?.error?.errorData?.key)
                        // {
                        //     const msg = this.msgService.getErrorMessage(err.error.errorData.key);
                        //     this.errorMessage = msg.text;
                        // }

                        this.calculateIndex(data.questionSets);

                        const employee = {
                            id: params['employeeId'],
                            name: data.name,
                            email: data.email
                        } as Employee;
                        
                        // legend                       
                        this.legend.push({ person: employee });

                        this.reportOptions = {
                            ...this.reportOptions, 
                            reviewPeriodIntervalText: data.reviewPeriod.dateText, 
                        } as ReportOptions;

                    })
                );

            }

            ViewHelper.scrollToTop();
        })
    }
    
    calculateIndex(qSets: QuestionSetForReport[]) {
        let index = 1;
        qSets.forEach(qSet => {
            qSet.childQuestionSets.forEach(module => {
                module.childQuestionSets.forEach(group => {
                    group.questions.forEach(question => {
                        question.index = index++;
                    });
                });
            });
        });
    }  

    hasItems(items: any[]) {
        return items && items.length > 0;
    }

    getColumnCount(leadersCount: number): string {
        let count = 3;
        
        if (this.state.config.c.reports.separatePeerAndSubordinate)
            count += 1;
        
        count += leadersCount != 0 ? leadersCount : 1;

        return count.toString();
    }

    getIndex(groupIndex: number, groupLength: number, questionIndex: number): number {
        console.log('groupIndex')
        return (groupIndex * groupLength) + questionIndex + 1;
    }

    isRpClosed(reviewPeriod: ReviewPeriod): boolean {
        return reviewPeriod.status === ReviewPeriodStatusEnum.Closed;
    }
}
