<div>
    <mat-card class="card-light" #card>
        <mat-card-header>
            <mat-card-title>Dolgozók listája</mat-card-title>
        </mat-card-header>
        <mat-card-content class="responsive-size">

            <!-- <mat-list role="list" *ngIf="companies$ | async as items">
                <mat-list-item role="listitem" *ngFor="let company of items">{{ company.name }}</mat-list-item>
                </mat-list> -->

                <div class="flex-horizontal">
                <div class="flex-fill">
                    <mat-form-field appearance="outline">
                        <mat-label>Kereső</mat-label>
                        <input matInput type="text" [formControl]="searchControl" autocomplete="off">
                        <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Törlés" (click)="clearText()">
                        <mat-icon>close</mat-icon>
                        </button>                
                    </mat-form-field>
                </div>
                <div class="view-button-panel">
                    <mat-checkbox [(ngModel)]="filterExpired" (change)="filterExpiredRp($event)">Lejárt határidő</mat-checkbox>
                    <div>
                        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [value]="viewMode">
                            <mat-button-toggle value="companyIndependent" aria-label="Cégfüggetlen kereső" title="Cégfüggetlen kereső" (click)="viewCompanyIndependent()">
                            <mat-icon>domain</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                </div>
                
                <div class="message-panel">
                {{ searchMessage }}
                </div>

                <ng-container *ngIf="items$ | async as items">

                    <table mat-table [dataSource]="items" matSort
                        class="table-default table-hover hover-pointer" multiTemplateDataRows
                        matSortActive="name" matSortDirection="asc"
                        matSortDisableClear
                        (matSortChange)="announceSortChange($event)" >
                                                            
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Név</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="text-16">{{ element.employee.name }}</div>
                            <div class="text-description text-10">{{ element.employee.employeeIdentifier ? element.employee.employeeIdentifier + ' | ' : ''}} {{ element.email }}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isCompanyIndependentMode">Cég</th>
                        <td mat-cell *matCellDef="let element" [hidden]="!isCompanyIndependentMode">
                            {{ element.company }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="sziluettState">
                        <th mat-header-cell *matHeaderCellDef class="sziluett padding-left-4" [hidden]="!isAllowSziluettDiagramEdit">
                            Állapot</th>
                        <td mat-cell *matCellDef="let element" class="sziluett padding-left-4 hover-bk dark-hover-text" [hidden]="!isAllowSziluettDiagramEdit">
                            {{ element.employee.lastSziluettState | assessmentStatusName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastSziluettFinishDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header 
                            class="sziluett"
                            [hidden]="!isAllowSziluettDiagramEdit">                                
                            Befejezés</th>
                        <td mat-cell *matCellDef="let element" class="sziluett hover-bk dark-hover-text" [hidden]="!isAllowSziluettDiagramEdit">
                                {{ element.employee.lastSziluettFinishDate | date:'yyyy.MM.dd' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="isSziluettDiagramAllowed">
                        <th mat-header-cell *matHeaderCellDef class="sziluett" [hidden]="!isAllowSziluettDiagramEdit || isCompanyIndependentMode">
                            Diagram<br>látható</th>
                        <td mat-cell *matCellDef="let element" class="sziluett hover-bk" [hidden]="!isAllowSziluettDiagramEdit || isCompanyIndependentMode">
                            
                            <div *ngxPermissionsOnly="['Assistant']">
                                {{ element.employee.isSziluettDiagramAllowed !== null ? (element.employee.isSziluettDiagramAllowed ? 'Igen': 'Nem') : '' }}
                            </div>
                            <ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? changeAllowSziluettDiagram($event, element.employee) : null"
                                    [checked]="element.employee.isSziluettDiagramAllowed">
                                </mat-checkbox>
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="isOwnPanoramaAuraReportAllowed">
                        <th mat-header-cell *matHeaderCellDef class="panorama" [hidden]="!isAllowPanoramaSettingsEdit || isCompanyIndependentMode">
                            Saját riport<br>látható</th>
                        <td mat-cell *matCellDef="let element" class="panorama hover-bk" [hidden]="!isAllowPanoramaSettingsEdit || isCompanyIndependentMode">
                            
                            <div *ngxPermissionsOnly="['Assistant']">
                                {{ element.employee.isOwnPanoramaAuraReportAllowed !== null ? (element.employee.isOwnPanoramaAuraReportAllowed ? 'Igen': 'Nem') : '' }}
                            </div>
                            <ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? changeOwnPanoramaAuraReportAllowed($event, element.employee) : null"
                                    [checked]="element.employee.isOwnPanoramaAuraReportAllowed">
                                </mat-checkbox>
                            </ng-container>
                        </td>
                    </ng-container> -->
                    
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="padding-left-4" [hidden]="!hasElevatedAccess || isCompanyIndependentMode"></th>
                        <td mat-cell *matCellDef="let element" class="padding-left-4" [hidden]="!hasElevatedAccess || isCompanyIndependentMode">
                            <button mat-flat-button color="primary" (click)="$event.stopPropagation(); selectEmployee(element)">
                                <mat-icon class="normal no-margin" title="Dolgozó kiválasztása">add</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <!-- Header row first group -->
                    <ng-container matColumnDef="header-row-first-group">
                        <th mat-header-cell *matHeaderCellDef                                 
                            [attr.colspan]="firstHeaderGroupColSpan"                                
                            >
                        </th>
                    </ng-container>

                    <!-- Header row Sziluett group -->
                    <ng-container matColumnDef="header-row-sziluett-group">
                        <th mat-header-cell *matHeaderCellDef 
                            [style.text-align]="center"
                            class="sziluett"
                            [attr.colspan]="sziluettHeaderGroupColSpan">
                        Sziluett
                        </th>
                    </ng-container>

                    <!-- Header row Panorama group -->
                    <!-- <ng-container matColumnDef="header-row-panorama-group">
                        <th mat-header-cell *matHeaderCellDef 
                        [hidden]="isCompanyIndependentMode"
                        [style.text-align]="center"
                        class="panorama">
                        Panoráma<br>
                        és Aura
                        </th>
                    </ng-container> -->

                    <ng-container matColumnDef="header-row-action-group">
                        <th mat-header-cell *matHeaderCellDef 
                            [hidden]="!isAllowUserMigration || isCompanyIndependentMode"
                            [style.text-align]="center" >
                        </th>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 'header-row-sziluett-group', 'header-row-action-group']"></tr>
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: tableColumns;"
                        class="element-row"                         
                        [ngClass]="{'selected': element.isSelected, 'no-pointer': !isEmployeeInCurrentCompany(element) }"
                        (click)="onMasterRowClick(element)"
                        ></tr>
                </table>
                <mat-paginator #paginator [length]="total"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="pagingSettingsChanged($event)"
                            aria-label="Válasszon oldalt">
                </mat-paginator>

                </ng-container>

                <div class="action-buttons">
                    <button mat-flat-button color="primary" (click)="addEmployee()">
                        <mat-icon>add</mat-icon>
                        Új dolgozó
                    </button>
                </div>

        </mat-card-content>
    </mat-card>
</div>

<ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
    <mat-card class="card-light margin-top-m" *ngIf="!isCompanyIndependentMode">
        <mat-card-header>
            <mat-card-title>Csoportos műveletek</mat-card-title>
        </mat-card-header>
        <mat-card-content>

            <div class="flex-horizontal gap-10">
                <div class="fields flex1">
                    <mat-form-field appearance="outline" class="flex1 full-width">
                        <mat-label>Művelet</mat-label>
                        <mat-select [(value)]="selectedAction" disableOptionCentering class="drop-fix" (selectionChange)="actionChanged()">
                            <mat-option *ngFor="let item of actions" [value]="item">{{ item.name }}</mat-option>
                        </mat-select>       
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" class="flex1 full-width" *ngIf="selectedAction && selectedAction.id === 1">
                        <mat-label>Cég (migrálás célja)</mat-label>
                        <mat-select [(value)]="selectedCompany" disableOptionCentering class="drop-fix">
                            <mat-option *ngFor="let item of companyList" [value]="item.id">{{ item.name }}</mat-option>
                        </mat-select>       
                    </mat-form-field>
                </div>
                <div class="flex1 text-16 desc-panel">                    
                    <ng-container *ngIf="selectedAction">
                        <div *ngIf="selectedAction.id == 1">
                            <p>{{ selectedAction.description }}</p>
                        </div>
                        <div *ngIf="selectedAction.id == 2" class="text-14">
                            <p>{{ texts["deleteEmployeeBatchLine1"] }}</p>
                            <p class="text-danger">{{ texts["deleteEmployeeBatchLine2"] }}</p>
                            <p>{{ texts["deleteEmployeeBatchLine3"] }}</p>
                            <p>{{ texts["deleteEmployeeBatchLine4"] }}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
    
            <h5>Kiválasztott dolgozók</h5>
            <div class="scroll-panel" *ngIf="basket.obs$ | async as basketItems">
                <app-dynamic-list-item 
                    (delete)="unselectEmployee(item.itemSource.employee.id)"
                    [actionState]="listState" 
                    [noEdit]="true"
                    *ngFor="let item of basketItems; index as i" [index]="i" [source]="item">
                    <div slot="readonly" class="flex-horizontal">
                        <div class="field-index"></div>
                        <div class="field-text"><span class="name">{{ item.itemSource.employee.name }}</span><span class="email"> ({{ item.itemSource.email }})</span></div>
                        <div class="field-id">{{ item.itemSource.employee.employeeIdentifier ?? '' }}</div>
                    </div>
                </app-dynamic-list-item>
                <div *ngIf="!basket.value || basket.value.length === 0">Nincs kiválasztott dolgozó</div>
            </div>

            <button mat-flat-button color="primary" (click)="startAction()" [disabled]="!isStartEnabled">
                <mat-icon>play_arrow</mat-icon>
                Indítás
            </button>
        </mat-card-content>
    </mat-card>
</ng-container>