import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { State } from 'src/app/models/state.model';
import { StateService } from 'src/app/services';
import { ErrorMessageService } from 'src/app/shared/services';
import { LoginDto } from '../../models/login.model';
import { AuthService } from '../../services/auth.service';
import { AppError } from 'src/app/models/general/app-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IResultModel } from 'src/app/models/general/result.model';
import { LoginResult } from 'src/app/models/other/login-result.model';
import { UserProfile } from 'src/app/models/user-profile.model';
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {    

    public loginForm: UntypedFormGroup;
    public passwordForm: UntypedFormGroup;

    public state: State;

    public actions: {[key: string]: {title: string, okButtonText: string, switchModeButtonText: string}} = 
    {
        'login': {
            title: 'Bejelentkezés',
            okButtonText: 'Bejelentkezés',
            switchModeButtonText: 'Jelszóemlékeztető'
        },
        'forgottenPassword': {
            title: 'Jelszóemlékeztető',
            okButtonText: 'Küldés',
            switchModeButtonText: 'Vissza'
        }
    };
    
    public currentMode: 'login' | 'forgottenPassword'= 'login';

    @ViewChild('tabs') tabGroup: MatTabGroup;

    constructor(
        private authService: AuthService,
        stateService: StateService,
        private router: Router, 
        private fb: UntypedFormBuilder,
        private msgService: ErrorMessageService
    ) {
        this.state = stateService.state;
    }
    
    ngOnInit() {
        this.loginForm = this.createLoginForm();
        this.passwordForm = this.createPasswordForm();
    }

    createLoginForm(): UntypedFormGroup{
        return this.fb.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });        
    }

    createPasswordForm(): UntypedFormGroup{
        return this.fb.group({
            username: ['', [Validators.required, Validators.email]],
        });        
    }

    public ok() {
        if (this.currentMode === 'login')
        {
            if (this.loginForm.invalid)
                return;

            let formData = { ...this.loginForm.value, grant_type: 'password' } as LoginDto;
            formData.username = formData.username.toLowerCase();
                    
            this.authService.logIn(formData).subscribe({next: () => {

                if (this.state.isAdmin || this.state.isAssistant || this.state.isSuperAdmin)
                    this.router.navigate(['/settings']);
                else
                    this.router.navigate(['/']);
            }, error: (err: IResultModel<LoginResult>) => {
                this.msgService.handleError<LoginResult>(err);
            }});
        }
        else if (this.currentMode === 'forgottenPassword') {
            if (this.passwordForm.invalid)
                return;
            let username = (this.passwordForm.value.username as string).toLowerCase();
            
            this.authService.forgottenPassword(username)
                .subscribe({ next: () => { 
                    this.msgService.showSnackBarMessage('passwordReminderSent', 8000);
                    this.switchMode();
                }, error: () => {
                    this.msgService.showSnackBarMessage('forgottenPasswordError', 8000);
                }});
            
        }
    };

    public switchMode() {
        if (this.currentMode === 'login')
        {
            this.currentMode = 'forgottenPassword';
            this.tabGroup.selectedIndex = 1;
        }
        else if (this.currentMode === 'forgottenPassword')
        {
            this.currentMode = 'login';
            this.tabGroup.selectedIndex = 0;
        }
    }

}
