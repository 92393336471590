import { Component, OnInit, ViewChild } from '@angular/core';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { State } from 'src/app/models/state.model';
import { ErrorMessageService, TokenService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';
import { SettingsState } from 'src/app/store/settings.state';
import { StateService } from '../../services/state.service';
import { AngularFileUploaderComponent } from 'src/app/shared/components';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { EmployeeImportResult } from 'src/app/models/other/employee-import-result.model';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDetailsDialogComponent } from 'src/app/dialogs/error-details-dialog/error-details-dialog.component';
import { ErrorDialogData } from 'src/app/models/dialog/error-dialog.model';
import { UploadDocType } from 'src/app/enums/upload-doc-type.enum';
import { UploadResultType } from 'src/app/enums/upload-result-type.enum';
import { ErrorDetailsDialogResult } from 'src/app/models/dialog/error-details-dialog-result.model';

@Component({
    selector: 'upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {
    public state: State;
    public afuConfig: any;    
    
    @ViewChild('fileUpload')
    private fileUpload:  AngularFileUploaderComponent;
    public docType?: UploadDocType = null;
   
    //config
    public assignToReviewPeriod = false;
    public isOverwriteAllowed = false;
    //public updateInReviewPeriod = false;
    public inProgress = false;

    public approved = false;

    public endpointList: UploadEndpoint[] = [
        {id: UploadDocType.Ratings, name: '1. Értékelési skálák [Panoráma]', url: '/api/files/upload-scales'},
        {id: UploadDocType.Questions, name: '2. Kompetenciaértékelés munkaköri kérdések [Panoráma]', url: '/api/files/upload-questions'},
        {id: UploadDocType.Employees, name: '3. Dolgozók, jelöltek adatai', url: '/api/files/upload-employees'},
        {id: UploadDocType.KPI, name: '4. KPI adatok [Panoráma]', url: '/api/files/upload-kpi'}
    ]

    public get reviewPeriodName(): string {
        return this.settingsState.reviewPeriod.value ? ReviewPeriodHelper.getDisplayText(this.settingsState.reviewPeriod.value) : '';
    }

    constructor(
        stateService: StateService, 
        public settingsState: SettingsState, 
        public appState: AppState, 
        private tokenService: TokenService, 
        private msgService: ErrorMessageService,
        private dialog: MatDialog,
        ) 
    {
        this.state = stateService.state;
    }
    
    ngOnInit() {
        this.setUploadConfig();
    }

    public getUploadUrl(docId: UploadDocType): string {
        let url = this.endpointList.filter(item => item.id === docId)[0].url;

        const rp = this.settingsState.reviewPeriod.value;

        // if (this.docType == UploadDocType.KPI) {
        //     if (rp) {
        //         url = url.replace('{rp}', rp.id.toString());
        //     }
        // }
        // else if (this.docType == UploadDocType.Employees) {
        //     if (rp && this.assignEmployeesToReviewPeriod) {
        //         url = url.replace('{rp}', rp.id.toString());
        //     } else
        //         url = url.replace('{rp}', '');
        // }
        // else if (this.docType == UploadDocType.Positions) {
        //     if (rp && this.updateInReviewPeriod) {
        //         url = url.replace('{rp}', rp.id.toString());
        //     } else
        //         url = url.replace('{rp}', '');
        // }
        
        return this.state.config.rest_end_point + url;
    }   

    private setUploadConfig() {
        this.afuConfig = {
            multiple: false,
            formatsAllowed: ".xlsx",
            maxSize: "3",
            uploadAPI:  {
              url: '',
              method:"POST",
              headers: {             
                'Authorization' : `Bearer ${this.tokenService.getToken()}`,
                //'content-type': 'multipart/form-data'
              },
              responseType: 'json',
            },
            theme: "dragNDrop",
            hideProgressBar: true,
            hideResetBtn: true,
            hideSelectBtn: true,
            fileNameIndex: false,
            replaceTexts: {
              selectFileBtn: 'Tallózás...',
              resetBtn: 'Reset',
              uploadBtn: 'Feltöltés',
              dragNDropBox: 'Drag N Drop',
              attachPinBtn: 'Attach Files...',
              afterUploadMsg_success: ' ',
              afterUploadMsg_error: ' ',
              sizeLimit: 'Méret limit'
            }
        };
    }
  
    public handleResult(data: any) {
        this.inProgress = false;
        if (!data.ok) {
            this.fileUpload.enableUploadBtn = true;           
        }
        else {            
            this.settingsState.reloadReviewPeriodEmployeeList.value = true;
        }

        if (this.docType === UploadDocType.Employees || this.docType === UploadDocType.Questions || this.docType === UploadDocType.KPI)
        {
            if (!data.ok)
                this.handleUploadResult(data.error);
            else
                this.handleUploadResult(data.body);
        }
        else
        {
            if (!data.ok)
                this.setUpdateState(UploadResultType.Error);
            else
                this.setUpdateState(UploadResultType.Success);
        }
    }

    public setUpdateState(uploadResultType: UploadResultType) {
        if (uploadResultType === UploadResultType.Success)
        {
            this.fileUpload.uploadMsgText = 'Sikeresen feltöltve!';
            this.fileUpload.uploadMsgClass = 'text-success lead';

            this.msgService.showSnackBarMessage('successfulAction');
        }
        else if (uploadResultType === UploadResultType.Error)
        {   
            this.fileUpload.uploadMsgText = 'Feltöltés sikertelen!';
            this.fileUpload.uploadMsgClass = 'text-danger lead';
        }
        else if (uploadResultType === UploadResultType.Interrupted)
        {   
            this.fileUpload.uploadMsgText = 'Feltöltés megszakítva!';
            this.fileUpload.uploadMsgClass = 'text-danger lead';
        }
    }

    public handleUploadResult(result: EmployeeImportResult) {
        const errorKeys = Object.keys(result.employeeErrorList);
        const warningKeys = Object.keys(result.employeeWarningList);

        if (result.errorData == null && errorKeys?.length == 0 && warningKeys?.length == 0)
        {
            this.approved = false;
            this.setUpdateState(UploadResultType.Success);
            return;
        }
        //const errorKey = err.error?.errorData?.key;

        /*if (errorKey)
        {
            const defaultMsg = this.msgService.getErrorMessage(errorKey);
            const dict = err.error.employeeErrorList;
            if (dict){

                const criticalErrors = ['badEmail', 'employeeExistsInDefaultCompany', 'employeeExistsInOtherCompany'];

                //check critical errors, these will stop the import process
                for (let key of criticalErrors) {
                    let list = dict[key];

                    if (list)
                    {
                        let msg = this.msgService.getErrorMessage(key);
                        let emailList = list.map(item => item.email).join(', ');
                        let textToDisplay = `${msg.text}\r\n (${emailList})`;

                        this.msgService.showSnackBarError(textToDisplay, 20000);
                        return;
                    }
                };

                //check other errors
                for (let key in dict) {
                    let value = dict[key];
                    
                    this.msgService.showSnackBarMessage(key);
                    // now only the first is interesting (later we can show all)
                    return;
                }
            }

            this.msgService.displayMessage(defaultMsg, 20000);
            return;
        }
        
        this.msgService.showSnackBarMessage('importError');*/

        const dialogData = {
            title: 'Import eredmények',
            errorData: result?.errorData,
            employeeErrorList: result?.employeeErrorList,
            employeeWarningList: result?.employeeWarningList,
            defaultErrorKey: 'importDataWarningOrError'
        } as ErrorDialogData;

        const dialogRef = this.dialog.open(ErrorDetailsDialogComponent, {
            maxWidth: "800px",
            width: "800px",
            data: dialogData,
          });

        dialogRef.afterClosed().subscribe( (result: ErrorDetailsDialogResult) => {
            if (!result)
                return;
            
            if (result.dialogCloseResult == 'continue')
            {
                this.approved = true;
                this.fileUpload.uploadFiles();
            }
            else if (result.dialogCloseResult == 'close')
            {
                this.approved = false;
                this.setUpdateState(result.uploadResultType);
            }
            
        });
    }

    public resetUpload() {
        this.fileUpload.resetFileUpload();
        this.docType = null;
        this.approved = false;
        this.assignToReviewPeriod = false;
        this.isOverwriteAllowed = false;
    }

    public docTypeChanged(value: any) {
        this.assignToReviewPeriod = false;
        this.isOverwriteAllowed = false;

        if (this.docType == UploadDocType.KPI)
            this.assignToReviewPeriod = true;
    }  

    // set upload config or post data based on selected document type and UI config
    public beforeUpload()
    {
        const rp = this.settingsState.reviewPeriod.value;

        const url = this.getUploadUrl(this.docType);
        this.fileUpload.config.uploadAPI.url = url;
        this.fileUpload.uploadAPI = url;

        // assign data to reviewPeriod
        if (this.settingsState.reviewPeriod && this.assignToReviewPeriod)
        {
            this.fileUpload.postData['reviewPeriodId'] = rp.id.toString();
        }

        if (this.isOverwriteAllowed)
        {
            this.fileUpload.postData['isOverwriteAllowed'] = (true).toString();
        }

        this.fileUpload.postData['approved'] = this.approved.toString();
        this.inProgress = true;
    }

}

interface UploadEndpoint {
    id: UploadDocType;
    name: string;
    url: string;    
}
