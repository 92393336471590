﻿<div class="container container-extra main-container">

    <h1 class="page-title">
        <span *ngIf="(activeReviewsCount$ | async) > 0" [matBadge]="activeReviewsCount$ | async" matBadgeSize="large" [matBadgeOverlap]="false">Aktív mérések</span>
        <span *ngIf="(activeReviewsCount$ | async) === 0">Aktív mérések</span>
    </h1>
    <div class="page-subtitle">Ezek a felmérések, értékelések még folyamatban vannak.</div>

    <ng-container *ngIf="itemsOpen$ | async as itemsOpen">
        <mat-card class="card-light mb-4" *ngIf="itemsOpen.length === 0">
            <mat-card-content class="text-subtitle">Jelenleg nincsenek kitöltendő felmérések, értékelések.</mat-card-content>
        </mat-card>
    
        <div class="active-list">
            <ng-container *ngFor="let item of itemsOpen" >
                <app-assessment-list-item [item]="item" [employee]="currentEmployee" [closedGroup]="false"></app-assessment-list-item>
            </ng-container>
        </div>
    </ng-container>


    <h1 class="page-title">Lezárt mérések</h1>
    <div class="page-subtitle">Ezek a felmérések, értékelések már befejeződtek.</div>

    <ng-container *ngIf="itemsClosed$ | async as itemsClosed">
        <mat-card class="card-light mb-4" *ngIf="itemsClosed.length === 0">
            <mat-card-content class="text-subtitle">Jelenleg nincsenek lezárt felmérések, értékelések.</mat-card-content>
        </mat-card>
    
        <ng-container *ngFor="let item of itemsClosed">
            <app-assessment-list-item [item]="item" [employee]="currentEmployee" [closedGroup]="true"></app-assessment-list-item>
        </ng-container>
    </ng-container>
    
</div>
