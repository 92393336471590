import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Temporal } from '@js-temporal/polyfill';
import { Observable } from 'rxjs';
import { AssessmentStatusEnum } from 'src/app/enums/assessment-status.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { EmployeeForAssessments } from 'src/app/models/employee-for-assessments.model';
import { Employee } from 'src/app/models/employee.model';
import { ReviewFilterOptions } from 'src/app/models/other/review-filter-options.model';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { State } from 'src/app/models/state.model';
import { AssessmentService, StateService } from 'src/app/services';
import { ReviewTypeStrategyBase } from 'src/app/services/products/review-type-strategy-base';
import { ReviewTypeStrategyFactory } from 'src/app/services/products/review-type-strategy.factory';
import { DateHelper } from 'src/app/shared/helpers';

@Component({
  selector: 'app-assessment-list-item-details',
  templateUrl: './assessment-list-item-details.component.html',
  styleUrl: './assessment-list-item-details.component.scss'
})
export class AssessmentListItemDetailsComponent implements OnInit {

  @Input() public reviewPeriod: ReviewPeriod;
  @Input() public employee: Employee;
  @Input() public closedGroup = false;

  public state: State;
  public details$: Observable<EmployeeForAssessments[]>;
  public displayedColumns = ['name', 'email', 'deadline', 'status', 'action'];

  public reviewTypeStrategy: ReviewTypeStrategyBase;

  public get isReviewPeriodOpen(): boolean {
    return ReviewPeriodHelper.isRpOpen(this.reviewPeriod);
  }

  public isFinished = AssessmentHelper.isFinished;
  public isSziluett = ReviewPeriodHelper.isSziluett;
  public isPanoramaAuraGoals = ReviewPeriodHelper.isPanoramaAuraGoals;
  public isDateExpired = DateHelper.isDateExpired;

  constructor( 
    private assessmentService: AssessmentService,
    private stateService: StateService,
    private router: Router, 
  ) { 
    this.state = stateService.state;
  }

  ngOnInit(): void {
    this.reviewTypeStrategy = ReviewTypeStrategyFactory.create(this.reviewPeriod?.reviewType);
    this.loadDetails();
  }

  getDeadline(item: EmployeeForAssessments): Temporal.PlainDate {
    if (ReviewPeriodHelper.isSziluett(this.reviewPeriod.reviewType) && item.deadline) return item.deadline;
    else if (ReviewPeriodHelper.isPanoramaAuraGoals(this.reviewPeriod.reviewType) && this.reviewPeriod.panoramaAuraEndDate) return this.reviewPeriod.panoramaAuraEndDate;
    else 
          return null;
  } 

  showDeadline(item: EmployeeForAssessments): boolean {
    return this.getDeadline(item) != null && !this.isFinished(item.status);
  }

  loadDetails() {
    let options = {
      reviewPeriodId: this.reviewPeriod.id,
      onlyClosedRpe: this.closedGroup === true,
      onlyNotClosedRpe: this.closedGroup === false,
    } as ReviewFilterOptions;
    this.details$ = this.assessmentService.getEmployeesToReview(options);
  }

  onReviewClick(item: EmployeeForAssessments) {
    if (this.isFinished(item.status)) return;

    this.router.navigate(['/assessment', item.reviewPeriodEmployeeId]);
  }

  onReportClick(item: EmployeeForAssessments) {
    this.router.navigate(['/reports/panorama-aura', item.id, this.reviewPeriod.id]);
  }

  isPanoramaReportAllowed(element: EmployeeForAssessments): boolean {
    if (!this.isPanoramaAuraGoals(this.reviewPeriod.reviewType) || !this.isReportReady(element)) return false;

    if (this.state.isSuperAdmin || this.state.isAdmin || this.state.isAssistant) return true;

    // a saját riportját láthatja, ha engedélyezték neki ezt külön.
    if (element.isSelf && this.employee.isOwnPanoramaAuraReportAllowed) return true;

    // Minden más esetben: ha a bejelentkezett felhasználó a vezetője az elemben szereplő személynek.
    return element.isSubordinate;
  }

  // Akkor érhető el a riport gomb Panoráma esetén ha mindenki értékelte már személyt, vagy az időszak már lezárt 
  public isReportReady(element: EmployeeForAssessments): boolean {
    return element.isAllReviewClosed || ReviewPeriodHelper.isRpClosed(this.reviewPeriod) || this.isDateExpired(this.reviewPeriod.panoramaAuraEndDate);
  }

  public isReviewAllowed(element: EmployeeForAssessments): boolean {
    if (!this.reviewPeriod || !this.isReviewPeriodOpen || this.isFinished(element?.status)) 
      return false;

    return this.reviewTypeStrategy.isReviewAllowed(this.reviewPeriod, element.isSelf);
  }

  public getStatusText(element: EmployeeForAssessments): string {
    //element.status == 0 && (closedGroup || !element.isSelf)
    const values = ['Nincs kitöltve', 'Félbehagyott', 'Kitöltött'];

    if (element.status != AssessmentStatusEnum.New) {
      return values[element.status];
    }
    else 
    { // New
      if (this.closedGroup) {
        return values[element.status];
      }
      else {
        if (this.isPanoramaAuraGoals(this.reviewPeriod.reviewType)) {
          return '';
        }
        else if (this.isSziluett(this.reviewPeriod.reviewType)) {
          if (element.isSelf) 
            return '';
          else
            return values[element.status];
        }
      }
    }

    return '';
  }
}
