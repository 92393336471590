﻿<div class="container padding-top-lg report-container">

    <app-back-nav linkText="Vissza az értékelésekhez"></app-back-nav>
    
    <mat-card *ngIf="isError" class="card-light error-panel">
        <mat-card-title>Hiba</mat-card-title>
        <mat-card-content>
            {{ errorMessage }}<br>
            Az értékelés jelenleg nem elérhető.
        </mat-card-content>
    </mat-card>

    <app-report-frame 
        class="sub-bk-light"
        [reportTypeName]="data.isContractor ? 'vállalkozói riport' : 'egyéni riport'"
        [noContentCss]="true"
        [headerRatio21]="true"
        [reportOptions]="reportOptions"
        [legend]="legend"        
        [showLegend]="true"
        [showLegendIcon]="false"
        *ngIf="data$ | async as data">
    
        <div class="content">
                   
            <ng-container *ngFor="let questionSet of data.questionSets">
                <!-- body -->
                <ng-container *ngFor="let module of questionSet.childQuestionSets">

                    <div class="row module-row" *ngIf="module.childQuestionSets.length > 0">
                            <div class="main-table">                                
                                <div class="t-body">

                                    <div class="module-title">
                                        {{ module.name.toUpperCase() }}
                                    </div>

                                    <!-- module legend -->
                                    <mat-card class="full-width card-legend break-inside-avoid">
                                        <mat-card-content>
                                            <div class="legend flex-h">
                                                <div *ngFor="let band of module.ratingBands" class="legend-block">
                                                    <div class="legend-value flex-h align-items-center justify-content-center" [ngStyle]="{ 'background-color': band.color}">
                                                        {{ band.bandMin }} - {{ band.bandMax }}
                                                    </div>
                                                    <span>{{ band.name }}</span>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>

                                    <mat-card class="card-table break-inside-avoid" *ngFor="let questionGroup of module.childQuestionSets; let groupIndex = index">
                                        <!-- question group -->
                                        <mat-card-header>
                                            <div class="t-row font-semibold">
                                                <div class="t-h mobile-flex-full q-group-name">{{ questionGroup.name }}</div>
                                                <div class="t-h">Önértékelés</div>
                                                <div class="t-h">
                                                    <span *ngIf="data.leaders[0] && data.leaders[0].name">
                                                        {{data.leaders[0]?.name}}<br>
                                                        {{ data.leaders[0]?.employeeIdentifier }}
                                                    </span>
                                                    <span *ngIf="!data.leaders[0] || !data.leaders[0].name">Főnök 1</span>
                                                </div>
                                                <div class="t-h" *ngIf="data.leaders[1]">
                                                    <span *ngIf="data.leaders[1].name">
                                                        {{ data.leaders[1]?.name }}<br>
                                                        {{ data.leaders[1]?.employeeIdentifier }}
                                                    </span>
                                                    <span *ngIf="!data.leaders[1].name">Főnök 2</span>
                                                </div>
                        
                                                <div class="t-h" *ngIf="!state.config.c.reports.separatePeerAndSubordinate">360°</div>
                                                <div class="t-h" *ngIf="state.config.c.reports.separatePeerAndSubordinate">Munkatársak<br>(peerek)</div>
                                                <div class="t-h" *ngIf="state.config.c.reports.separatePeerAndSubordinate && data.isBoss">Beosztottak</div>
                                                <div class="t-h" >Egyéni átlag</div>
                                                <div class="t-h" *ngIf="isRpClosed(data.reviewPeriod)">Vállalati átlag</div>
                                            </div>
                                        </mat-card-header>
    
                                        <mat-card-content *ngIf="questionGroup.questions.length > 0">
                                            <!-- question group: questions -->
                                            <div class="t-row" *ngFor="let question of questionGroup.questions; let questionIndex = index">
                                                <div class="t-d mobile-flex-full flex-horizontal qname">
                                                    <div class="q-index text-left">{{ question.index }}</div><div class="text-left">{{ question.name}}</div>
                                                </div>

                                                <div class="t-d colored-field" [ngStyle]="{ 'background-color': question.ownRating.color }">{{ question.ownRating.value | number:'1.0-0' | nozero }}</div>                    
                                                <div class="t-d colored-field" [ngStyle]="{ 'background-color': question.leaderRatings[0].color }">{{ question.leaderRatings[0].value | number:'1.0-0' | nozero }}</div>
                                                <div class="t-d colored-field" *ngIf="data.leaders[1]" [ngStyle]="{ 'background-color': question.leaderRatings[1].color }">{{ question.leaderRatings[1].value | number:'1.0-0' | nozero }}</div>
                    
                                                <div class="t-d colored-field" *ngIf="!state.config.c.reports.separatePeerAndSubordinate" [ngStyle]="{ 'background-color': question.fullRating.color }">{{ question.isDeviationLarge ? '*' : ''}} {{ question.fullRating.value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d colored-field" *ngIf="state.config.c.reports.separatePeerAndSubordinate" [ngStyle]="{ 'background-color': question.peerRating.color }">{{ question.isPeerDeviationLarge ? '*' : ''}} {{ question.peerRating.value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d colored-field" *ngIf="state.config.c.reports.separatePeerAndSubordinate && data.isBoss" [ngStyle]="{ 'background-color': question.subordinateRating.color }">{{ question.isSubordinateDeviationLarge ? '*' : ''}} {{ question.subordinateRating.value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d">{{ question.ratingAverage | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d" *ngIf="isRpClosed(data.reviewPeriod)">{{ question.companyRatingAverage | number:'1.1-1' | nozero }}</div>
                                            </div>
                                        </mat-card-content>

                                        <mat-card-footer *ngIf="questionGroup.questions.length > 0" class="break-inside-avoid">
                                            <!-- question group: sum -->
                                            <div class="t-row font-semibold">
                                                <div class="t-d mobile-flex-full flex-horizontal gap-1.5" [attr.colspan]="getColumnCount(data.leaders.length)">
                                                    <div class="q-index text-left"></div><div class="text-left">Kérdéscsoport átlag</div>
                                                </div>

                                                <div class="t-d">{{ questionGroup.overall.ownRating.value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d">{{ questionGroup.overall.leaderRatings[0].value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d" *ngIf="data.leaders[1]">{{ questionGroup.overall.leaderRatings[1].value | number:'1.1-1' | nozero }}</div>
                    
                                                <div class="t-d" *ngIf="!state.config.c.reports.separatePeerAndSubordinate">{{ questionGroup.overall.fullRating.value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d" *ngIf="state.config.c.reports.separatePeerAndSubordinate">{{ questionGroup.overall.peerRating.value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d" *ngIf="state.config.c.reports.separatePeerAndSubordinate && data.isBoss">{{ questionGroup.overall.subordinateRating.value | number:'1.1-1' | nozero }}</div>
                                                <div class="t-d"></div>
                                                <div class="t-d" *ngIf="isRpClosed(data.reviewPeriod)"></div>
                                            </div>
                                        </mat-card-footer>
                                    </mat-card>

                                    <!-- module sum -->
                                    <mat-card class="card-table break-inside-avoid">
                                        <mat-card-footer>
                                            <div class="t-row sum-row">
                                                <div class="t-d mobile-flex-full flex-horizontal q-group-name">
                                                    <div class="q-index text-left"></div><div>Összesített eredmény</div>
                                                </div>
                                                <div class="t-d"><strong>{{ module.overall.ownRating.value | number:'1.1-1' | nozero }}</strong></div>
                        
                                                <div class="t-d"><strong>{{ module.overall.leaderRatings[0].value | number:'1.1-1' | nozero }}</strong></div>
                                                <div class="t-d" *ngIf="data.leaders[1]"><strong>{{ module.overall.leaderRatings[1].value | number:'1.1-1' | nozero }}</strong></div>
                        
                                                <div class="t-d" *ngIf="!state.config.c.reports.separatePeerAndSubordinate"><strong>{{ module.overall.fullRating.value | number:'1.1-1' | nozero }}</strong></div>
                                                <div class="t-d" *ngIf="state.config.c.reports.separatePeerAndSubordinate"><strong>{{ module.overall.peerRating.value | number:'1.1-1' | nozero }}</strong></div>
                                                <div class="t-d" *ngIf="state.config.c.reports.separatePeerAndSubordinate && data.isBoss"><strong>{{ module.overall.subordinateRating.value | number:'1.1-1' | nozero }}</strong></div>
                                                <div class="t-d"><strong>{{ module.overall.ratingAverage | number:'1.1-1' | nozero }}</strong></div>
                                                <div class="t-d" *ngIf="isRpClosed(data.reviewPeriod)"><strong>{{ module.overall.companyRatingAverage | number:'1.1-1' | nozero }}</strong></div>
                                            </div>
                                        </mat-card-footer>
                                    </mat-card>

                                </div>
                            </div>
                    </div>
                </ng-container>
            </ng-container>

            <div class="row break-inside-avoid">
                <mat-card class="full-width">
                    <mat-card-content>
                        <div class="legend-text">
                            <p>
                                <strong>* jelöli:</strong> ha a peer vagy a beosztott válaszadói csoporton belül kapott értékeléseknél előfordul legalább 3 értéknyi különbség.<br>
                            </p>
                            <p>
                                <strong>Egyéni átlag:</strong> az adott kompetenciában a beosztottaktól és a peerektől kapott átlagértékelés, valamint az 1. és 2. főnöktől kapott értékelés átlaga. Az egyéni átlag az önértékelést nem tartalmazza.<br>
                            </p>
                            <p>
                                <strong>Vállalati átlag:</strong> A legutóbb lezárt értékelési ciklusban az adott kompetenciában elért egyéni átlagok átlaga.
                            </p>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
                
            <!-- kpi -->
            <!-- <div class="row" *ngIf="hasItems(data.keyPerformanceIndicators)">            
                <table class="separator">
                    <caption><h4>KULCS MUTATÓK, TERVSZÁMOK (KPI)</h4></caption>
                    <div class="t-head"ead>
                        <div class="t-row">
                            <div class="t-head"></div>
                            <div class="t-head">mértékegység</div>
                            <div class="t-head">terv</div>
                            <div class="t-head">teljesült</div>
                            <div class="t-head">teljesítmény</div>
                        </div>
                    </divead>
                    <tbody>
                        <tr *ngFor="let kpi of data.keyPerformanceIndicators">
                            <td>{{kpi.kpiType.name}}</div>
                            <td>{{kpi.kpiType.unit}}</div>
                            <td>{{kpi.planned | number:'1.1-1' | nozero }}</div>
                            <td>{{kpi.achieved | number:'1.1-1' | nozero }}</div>
                            <td>{{(100 * kpi.achieved/kpi.planned) | number:'1.1-1' }}%</div>
                        </div>
                        <div class="t-row">
                            <td><strong>Kulcs mutatók, tervszámok (KPI) összesített teljesítmény</strong></div>
                            <td></div>
                            <td></div>
                            <td></div>
                            <td><strong>{{ data.kpiOverallPercent | number:'1.1-1' }}%</strong></div>
                        </div>
                    </tbody>                    
                </table>            
            </div> -->
        
            <div class="row" *ngIf="hasItems(data.freeTexts)">
                
                <mat-card class="full-width">
                    <mat-card-content>
                        <!-- freeText -->
                        <app-freetext-panel [freeTexts]="data.freeTexts" [noMargin]="true"></app-freetext-panel>
                        
                    </mat-card-content>
                </mat-card>
            </div>
                      

        </div>
    
    </app-report-frame>

</div>