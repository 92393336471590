
<h1 mat-dialog-title>Dolgozó hozzáadása az időszakhoz</h1>
<div mat-dialog-content>

    <div>
        Dolgozó: {{ data.aboutEmployeeName }}
    </div>

    <div class="about-employee-options">
        <mat-checkbox class="multiline" [(ngModel)]="isAuraLeader">Aura 360 értékelt vezető</mat-checkbox>
    </div>

</div>
<div mat-dialog-actions class="flex-h flex-end">
    <button mat-flat-button color="primary" (click)="ok()" type="submit">Ok</button>
    <button mat-flat-button [mat-dialog-close]>Mégsem</button>
</div>

  