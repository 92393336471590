import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EmployeeGroupMember } from 'src/app/models/employee-group-member.model';
import { EmployeeGroup } from 'src/app/models/employee-group.model';
import { EmployeeBulkInsertDialogConfig } from 'src/app/models/emplyee-bulk-insert-dialog-config.model';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeGroupService, EmployeeService, StateService } from 'src/app/services';
import { CardComponent } from 'src/app/shared/components';
import { ComponentState } from 'src/app/shared/enum/component-state.enum';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { StateValue } from 'src/app/shared/models/state-value.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';
import { SettingsState } from 'src/app/store/settings.state';
import { EmployeeGroupAddComponent } from '../../dialogs/employee-group-add/employee-group-add.component';
import { UserBulkInsertComponent } from '../../dialogs/user-bulk-insert/user-bulk-insert.component';
import { State } from 'src/app/models/state.model';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';

@UntilDestroy()
@Component({
  selector: 'app-employee-group',
  templateUrl: './employee-group.component.html',
  styleUrls: ['./employee-group.component.scss']
})
export class EmployeeGroupComponent implements OnInit {
  
  public groupState = new StateValue<EmployeeGroup>(null);  
  public groupList$: Observable<EmployeeGroup[]>;

  private readonly itemsSubject = new BehaviorSubject<ListItem<EmployeeGroup>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();
  public state: State;
  
  public searchMemberConfig: SearchConfig<Employee, number> = {
    mapItem: (model: Employee) => {
      return {
        id: model.id, 
        text: model.name,
        dropText: `${model.name} ${(model.employeeIdentifier ?? '')}`,
        itemSource: model } as SearchOption<Employee, number>;
    },
    getList: this.employeeService.getList
  };

  @ViewChild('card')
  card: CardComponent;
    
  public get allowAddMember(): boolean {
    return this.itemsSubject.getValue().length === 0 || this.itemsSubject.getValue().every(x => x.state === ComponentState.View);
  }

  public get isEditable(): boolean {
    return ReviewPeriodHelper.isBlockEditable(this.settingsState, this.state);
  }

  constructor(    
    public dialog: MatDialog,

    public appState: AppState,
    public settingsState: SettingsState,
    public stateService: StateService,

    private employeeGroupService: EmployeeGroupService, 
    private employeeService: EmployeeService,
    private msgService: ErrorMessageService) {
      this.state = stateService.state;
  }

  ngOnInit(): void {      

    combineLatest([this.settingsState.reviewPeriod.obs$, this.groupState.obs$])
    .pipe(untilDestroyed(this))
    .subscribe(([reviewPeriod, group]) => {
      if (reviewPeriod && group)
        this.loadList(group.id);
    });
          
    this.loadGroups();
  }

  private loadGroups(data?: EmployeeGroup) {
    this.groupList$ = this.employeeGroupService.getList().pipe(
      map(result => result.list),
      tap(list => {
        if (list && list.length > 0) {
          const initVal = !data ? list[0] : list.filter(x => x.id == data.id)[0];          
          this.groupState.value = initVal;
        }
      })
    );

  }

  private loadList(groupId: number | null) {
    if (!groupId)
    {
      this.itemsSubject.next([]);
      return;
    }

    this.employeeGroupService.getMembers(groupId, this.settingsState.reviewPeriod.value.id).pipe(
      map(list => list.map(x => new ListItem(x)))
    )
    .subscribe(itemArray => {
      this.itemsSubject.next(itemArray);
    });
  }

  public deleteMember(item: ListItem<EmployeeGroup>) {
    this.employeeGroupService.deleteMember(this.groupState.value.id, item.itemSource.id, this.settingsState.reviewPeriod.value.id).subscribe(result => {
      
      this.loadList(this.groupState.value.id);
      
      this.msgService.showSnackBarMessage('deleteSuccessful');
    }, err => {
      this.msgService.showSnackBarMessage('unsuccessfulAction');
    });
  }
  
  public addGroup() {
    //popup
    const dialogRef = this.dialog.open(EmployeeGroupAddComponent, {
      width: '270px',
      data: {id: 0, name: ''} as EmployeeGroup
    });

    dialogRef.afterClosed().subscribe( (result: EmployeeGroup) => {
      if (result)
        this.loadGroups(result);
    });
  }

  public addMember(option: SearchOption<Employee, number>) {
    let item = {
      group: this.groupState.value,
      groupMember: option.itemSource,
      reviewPeriodId: this.settingsState.reviewPeriod.value.id
    } as EmployeeGroupMember;
        
    // if (!item.group)
    // {
    //   this.msgService.showSnackBarMessage('noEmployeeGroupSelected');
    //   return;
    // }

    // if (!item.groupMember)
    // {
    //   this.msgService.showSnackBarMessage('noUserSelected');
    //   return;
    // }

    this.employeeGroupService.addMember(item).subscribe(data => {
      this.loadList(this.groupState.value.id);
    }, error => {
      this.msgService.showErrorResponse(error);
    });
  }

  public addMembers() {
        //popup
        const dialogRef = this.dialog.open(UserBulkInsertComponent, {
          width: '770px',
          data: {
            id: this.groupState.value.id, 
            title: 'Felhasználók hozzáadása a ' + this.groupState.value.name + ' csoporthoz',
            validateAction: this.employeeService.checkEmails
           } as EmployeeBulkInsertDialogConfig
        });

        dialogRef.afterClosed().subscribe(employeeIdList => {
          if (employeeIdList) {
            this.employeeGroupService.addMembers(this.groupState.value.id, employeeIdList, this.settingsState.reviewPeriod.value.id).subscribe(data => {
              this.loadList(this.groupState.value.id);
            });
          }
        });
  }
  
}
