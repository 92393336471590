<app-card #card cardTitle="KPI célok" [useExternalButtons]="true" [editDisabled]="!allowEdit">
    <div *ngIf="items$ | async as items">
        <div [formGroup]="kpiForm">
            <app-dynamic-list-item (ok)="saveItem($event, item)" (cancel)="cancelItem(item)" (delete)="deleteItem(item)" (edit)="editItem(item.itemSource)" [actionState]="card.actionState" *ngFor="let item of items; index as i" [index]="i" [source]="item">
                <div slot="readonly" class="flex-horizontal width-max read-only-area">
                    <div class="field-name">{{ item.itemSource.kpiType.name }}</div>
                    <div class="field-value">{{ item.itemSource.planned | mask: 'separator' }}</div>
                    <div class="field-unit">{{ item.itemSource.kpiType.unit }}</div>
                </div>
                <div slot="edit">
                    <div class="flex-horizontal edit-area">
                        <div class="field-name">{{ item.itemSource.kpiType.name }}</div>
                            
                        <mat-form-field appearance="outline" class="field-value field-simple">
                            <input matInput formControlName="planned" mask="separator.2" placeholder="Mennyiség" required>
                        </mat-form-field>
        
                        <div class="field-unit">{{ item.itemSource.kpiType.unit }}</div>
                    </div>
                </div>
                <div slot="add">
                    <div class="flex-horizontal add-area">
                        <div class="field-name">{{ item.itemSource.kpiType.name }}</div>
                            
                        <mat-form-field appearance="outline" class="field-value field-simple">
                            <input matInput formControlName="planned" mask="separator" placeholder="Mennyiség" required>
                        </mat-form-field>
                            
                        <mat-form-field appearance="outline" class="field-unit field-simple" *ngIf="item.itemSource.kpiType.id === 0">
                            <input matInput formControlName="unit" placeholder="Egység" required>
                        </mat-form-field>
                        <div class="field-unit" *ngIf="item.itemSource.kpiType.id != 0">{{ item.itemSource.kpiType.unit }}</div>
                    </div>
                </div>
            </app-dynamic-list-item>
        </div>
        <app-search #search *ngIf="card.actionState.editMode" (add)="addItem($event)" [inlineButtons]="true" [isAllowSearch]="true" [disabledAddButton]="!allowAdd" [config]="kpiSearchConfig"></app-search>
    </div>
</app-card>
