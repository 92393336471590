import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { SignalRMethods } from "../enums/signalR-methods.enum";
import { MailProgress } from "../models/general/mail-progress.model";
import { BehaviorSubject } from "rxjs";
import { StateService } from "./state.service";
import { State } from "../models/state.model";
import { TokenService } from "../shared/services";
import { JwtHelperService } from "../jwt/jwthelper.service";
import { NavigationEnd, Router } from "@angular/router";
import { OnlineUsers } from "../models/general/online-users.model";

@Injectable({
    providedIn: 'root'
})
export class MainHubService {
    private hubConnection: signalR.HubConnection;

    public mailProgressSubject: BehaviorSubject<MailProgress[]> = new BehaviorSubject<MailProgress[]>([]);
    public mailLastProgressSubject: BehaviorSubject<MailProgress> = new BehaviorSubject<MailProgress>(null);

    public onlineUsersSubject: BehaviorSubject<OnlineUsers> = new BehaviorSubject<OnlineUsers>({ onlineUsers: 0, companyOnlineUsers: 0 });

    public get clientId(): string {
        return this.hubConnection?.connectionId || '';
    }

    public state: State;

    constructor(private stateService: StateService, private tokenService: TokenService, private jwtHelper: JwtHelperService, private router: Router)
    {
        this.state = stateService.state;
    
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              const token = this.tokenService.getToken();
              if (token && !this.jwtHelper.isTokenExpired(token)) {
                if (!this.hubConnection || this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
                  this.startConnection();
                }
              } else {
                this.stopConnection();
              }
            }
          });
        
    }

    public startConnection() {
        const token = this.tokenService.getToken();

        if (token && !this.jwtHelper.isTokenExpired(token)) {
            const baseUrl = this.state.config.rest_end_point;
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${baseUrl}/main-hub`, { accessTokenFactory: () => token })
                .withAutomaticReconnect()
                .build();
    
            this.addListeners();

            this.hubConnection.start()
                .then(() => {
                    console.info('SignalR connection estabilished');
                })
                .catch(err => {
                    console.error('SignalR connection error:', err);
                });

            this.hubConnection.onreconnected(() => {
                console.info('SignalR reconnected');
            });
        }
    }
    
    public stopConnection() {
        if (this.hubConnection) {
            this.hubConnection.stop().then(() => {
                console.info('SignalR connection stopped');                 
            })
            .catch(err => {
                console.error('SignalR connection stop error');
            });
        }
    }

    private addListeners() {

        //MailProgress
        this.hubConnection.on(SignalRMethods.MailProgress, (progressDetails: MailProgress) => {
            const currentList = this.mailProgressSubject.value;
            this.mailLastProgressSubject.next(progressDetails);
            this.mailProgressSubject.next([...currentList, progressDetails]);
        });

        //OnlineUsers
        this.hubConnection.on(SignalRMethods.UpdateUserCount, (data: OnlineUsers) => {
            this.onlineUsersSubject.next(data);

            //log data
            console.log(data);
        });
    }

    public resetMailProgress() {
        this.mailLastProgressSubject.next(null);
        this.mailProgressSubject.next([]);
    }
    
    public UpdateUserCount() {
        this.hubConnection.invoke(SignalRMethods.UpdateUserCount);
    }
}