import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { UserProfile } from 'src/app/models/user-profile.model';
import { State } from '../../models/state.model';
import { AuthService } from '../../services/auth.service';
import { StateService } from '../../services/state.service';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store/app.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Company } from 'src/app/models/company.model';
import { EmployeeHelper } from 'src/app/helpers/employee.helper';

@UntilDestroy()
@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {        
    public state: State;
    public userProfile: UserProfile;
    public company: Company
    public role: string;

    @Input()
    public isMobile = false;

    @Output()
    public menuItemClicked = new EventEmitter();

    public get hasPanoramaOrAura(): boolean {
        return this.appState?.company.value?.config?.hasPanoramaOrAura;
    }

    public get hasElevatedAccess(): boolean {
        return this.state?.isSuperAdmin || this.state?.isAdmin || this.state?.isAssistant;
    }

    public get hasElevatedAccessOrBoss(): boolean {
        return this.hasElevatedAccess || this.state?.me.value?.isBoss;
    }

    constructor(
        private authService: AuthService,         
        public appState: AppState,
        stateService: StateService,
        private router: Router        
    ) {
        this.state = stateService.state;
    }

    ngOnInit() {       

        combineLatest([this.state.me.obs$, this.appState.company.obs$])
        .pipe(untilDestroyed(this))
        .subscribe(([userProfile, company]) => {

            if (userProfile && company)
            {
                this.userProfile = userProfile;
                this.company = company;
                this.role = EmployeeHelper.getRoleDisplayName(this.userProfile.role);
            }
        });
    }      
    
    logOut() {
        this.mobileMenuClick();
        this.authService.logOut();
        this.router.navigate(['/login']);
    };

    mobileMenuClick() {
        this.menuItemClicked.emit();
    }
}
