import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { CompanyUserRoleDialogComponent } from 'src/app/dialogs';
import { CompanyUserRole } from 'src/app/models/company-user-role.model';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { PagingRequest } from 'src/app/models/paging-request.model';
import { Search } from 'src/app/models/search.model';
import { State } from 'src/app/models/state.model';
import { StateService } from 'src/app/services';
import { UserRolesService } from 'src/app/services/user-roles.service';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';
import { EmployeeState } from 'src/app/store/employee.state';

@UntilDestroy()
@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {

  private readonly itemsSubject = new BehaviorSubject<ListItem<CompanyUserRole>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();
    
  public searchControl: UntypedFormControl;
  public minCharCount = 3;
  
  // paging
  public total = 0;
  public pageSize = 10;
  public pageSizeOptions = [5, 10, 20, 50];
  public page = 0;
  
  public searchMessage = '';
  
  public get isValidCharacterCount(): boolean {
    return this.searchControl.value.length === 0 || this.searchControl.value.length >= this.minCharCount;
  }

  @ViewChild('paginator')
  paginator: MatPaginator;   
    
  public get searchValue(): string {
    return this.searchControl.value;
  }

  public setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  public state: State;

  public get allowEdit(): boolean {
    return this.state.isSuperAdmin || this.state.isAdmin || (this.employeeStore.isBoss.value && this.employeeStore.employee.value.hasBoss);
  }

  public isAllowChange = false;
    
  constructor(
    stateService: StateService,
    public appState: AppState, 
    public employeeState: EmployeeState,
    private userRolesService: UserRolesService,
    private employeeStore: EmployeeState,
    private msgService: ErrorMessageService,
    private dialog: MatDialog,
    ) {
      this.state = stateService.state;
  }

  ngOnInit(): void {     
    this.searchControl = new UntypedFormControl('');    
    
    combineLatest([this.employeeState.employee.obs$, this.state.me.obs$])
    .pipe(untilDestroyed(this))
    .subscribe(([employee, me]) => {
      if (employee && me)
      {
        this.isAllowChange = me.isSA || employee.id != me.id;

        this.loadList();
      }
      else      
        this.isAllowChange = false;
    });

    this.searchControl.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.firstPage();
          this.setValidationMessage();
        }),
        switchMap(searchString => searchString.length === 0 || searchString.length >= this.minCharCount ? this.companyUserRoleSearch(searchString): of([])),
        untilDestroyed(this)
    ).subscribe(itemArray => {
      this.itemsSubject.next(itemArray);
    });
    
  }

  public pagingSettingsChanged(data: PageEvent)
  {
    this.page = data.pageIndex;
    this.pageSize = data.pageSize;
    this.loadList();
  }

  private companyUserRoleSearch(searchString: string): Observable<ListItem<CompanyUserRole>[]> {
        
    return this.userRolesService.getList(this.buildPageRequest(searchString)).pipe(
      tap(data => {
        this.total = data.total;
        this.searchMessage = this.getResultMessage(data.list.length);
      }),
      map(result => result.list.map(x => new ListItem(x)) )
    );    
  }

  private buildPageRequest(searchString: string): Search {
    let data = {
      search: searchString,
      userRef: this.employeeState.employee.value.id,
      pagingRequestInfo: {
        pageSize: this.pageSize,
        page: this.page + 1
      } as PagingRequest
    } as Search;

    return data;
  }

  public clearText() {
    this.searchControl.setValue('');
    //this.loadList();
  }

  private loadList() {
    this.companyUserRoleSearch(this.searchControl.value)
    .pipe(untilDestroyed(this))
    .subscribe(itemArray => {
      this.itemsSubject.next(itemArray);      
    });
  }
   
  public setValidationMessage() {
    if (!this.isValidCharacterCount)
      this.searchMessage = `Minimum ${ this.minCharCount } karakter szükséges`;
  }

  public getResultMessage(count: number): string {
      if (this.total > count)
        return `${ this.total } találatból ${ count } megjelenítve, kérem szűkítse a keresési feltételt.`;
      else if (count === 0)
        return 'Nincs találat';
      else
        return '';
  }

  public rowEdit(data: CompanyUserRole) {    
    const dialogRef = this.dialog.open(CompanyUserRoleDialogComponent, {
      minWidth: "500px",
      data: data,
    });

    dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
      if (dialogResult)
      {         
        this.loadList();
      }
    });

  }

  public add() {    
    var data = {
      userId: this.employeeState.employee.value.id,
      companyId: null,
      roleId: null,
    } as CompanyUserRole;

    const dialogRef = this.dialog.open(CompanyUserRoleDialogComponent, {
      minWidth: "500px",      
      data: data
    });

    dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
      if (dialogResult)
      {         
        this.loadList();
      }
   });
  }

  public rowDelete(data: CompanyUserRole) {
    this.userRolesService.deleteUserRole(data)
    .pipe(untilDestroyed(this))
    .subscribe({
      next: () => {
        this.loadList();
      },
      error: (err: IResultModel<ResultModel>) => {
        this.msgService.handleError<ResultModel>(err);
      }
    });    
  }

}
