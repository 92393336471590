import { QueryList } from "@angular/core";
import { QuestionComponent } from "../components";
import { AssessmentStatusEnum } from "../enums/assessment-status.enum";
import { ReviewPeriodStatusEnum } from "../enums/review-period-status.enum";
import { ReviewTypeEnum } from "../enums/review-type.enum";
import { Assessment, AssessmentModel } from "../models/assessment.model";
import { IdName } from "../models/general/id-name.model";
import { QuestionOptions } from "../models/question-options.model";
import { Question } from "../models/question.model";
import { QuestionsForAssessment } from "../models/questions-for-assessment.model";
import { ReviewPeriodForEmployee } from "../models/review-period-for-employee.model";
import { AssessmentActions } from "../models/other/assessment-actions.model";
import { DateHelper } from "../shared/helpers/date.helper";
import { ReviewPeriodEmployee } from "../models/review-period-employee.model";

export abstract class AssessmentHelper {
    
    public static assessmentStatusList: IdName[] = [
        {id: AssessmentStatusEnum.New, name: 'Új'},
        {id: AssessmentStatusEnum.InProgress, name: 'Folyamatban'},
        {id: AssessmentStatusEnum.Finished, name: 'Kitöltött'}
      ];

    public static assessmentActionList: { [key: number]: AssessmentActions; } = {
        [ReviewTypeEnum.TER]: {
            options: true,
            questionModuleSettings: true,

            isPositionBasedAssessment: true,
            isGoalsEnabled: true,
            isSpecialQuestionSetBasedAssessment: true,

            isFreeTextAssessmentAllowed: true,
            copyEmployees: true,
            updateRpe: true,
        },
        [ReviewTypeEnum.PersonalityTest]: {
            options: false,
            questionModuleSettings: false,
            
            isPositionBasedAssessment: false,
            isGoalsEnabled: false,
            isSpecialQuestionSetBasedAssessment: false,
            
            isFreeTextAssessmentAllowed: false,
            copyEmployees: false,
            updateRpe: false,
        },
        [ReviewTypeEnum.MilioStressMap]: {
            options: true,
            questionModuleSettings: false,

            isPositionBasedAssessment: false,
            isGoalsEnabled: false,
            isSpecialQuestionSetBasedAssessment: false,
            
            isFreeTextAssessmentAllowed: true,
            copyEmployees: false,
            updateRpe: false,
        },
        [ReviewTypeEnum.MilioTeamDysfunction]: {
            options: true,
            questionModuleSettings: false,

            isPositionBasedAssessment: false,
            isGoalsEnabled: false,
            isSpecialQuestionSetBasedAssessment: false,
            
            isFreeTextAssessmentAllowed: true,
            copyEmployees: false,
            updateRpe: false,
        },
    }
    


    public static getName(type: AssessmentStatusEnum): string {
        const item = AssessmentHelper.assessmentStatusList.find(x => x.id === type as number);
        return item.name;
    }
    
    public static isQuestionComplete(question: Question): boolean {
        const data = question.assessmentData;

        if (!data) return false;

        if (data.answerId !== null || data.answerText)
            return true;

        return false;
    }

    public static isClosed(item: ReviewPeriodForEmployee): boolean {
        if (item.reviewPeriod.reviewType === ReviewTypeEnum.TER || !item.reviewPeriodEmployee) {
            return item.reviewPeriod.status != ReviewPeriodStatusEnum.Open;
        }
        else {
            return item.reviewPeriodEmployee.status === AssessmentStatusEnum.Finished || (item.reviewPeriod.reviewType != ReviewTypeEnum.PersonalityTest && item.endDate && DateHelper.isDateExpired(item.endDate));
        }
    }

    public static isFinished(status: AssessmentStatusEnum): boolean {
        return status === AssessmentStatusEnum.Finished;
    }

    public static isRpeFinished(item: ReviewPeriodEmployee): boolean {
        return item?.status === AssessmentStatusEnum.Finished;
    }

    public static createAssessment(q: Question, reviewPeriodEmployeeId: number): Assessment {
        let model = new AssessmentModel();
        model.reviewPeriodEmployeeId = reviewPeriodEmployeeId;
        model.questionId = q.id;
        return model;
    }

    public static createQuestionOptions(data: QuestionsForAssessment): QuestionOptions {
        return {
            ratings: data.ratings, 
            reviewPeriodEmployee: data.reviewPeriodEmployee, 
            showId: false,
            questionCategories: data.questionCategories
        };
    }

    public static getCurrentQuestionComponent(questions: QueryList<QuestionComponent>, currentIndex: number): QuestionComponent {
        for (const item of questions) {
            const ret = this.getQuestionElement(item, currentIndex);

            if (ret) return ret;
        }
        
        return null;
    }

    public static getQuestionElement(cmp: QuestionComponent, currentIndex: number): QuestionComponent {
        if (cmp.textblock.questionIndex == currentIndex)
            return cmp;

        if (cmp.groupChildren && cmp.groupChildren.length > 0) {            
            for (const item of cmp.groupChildren) {
                const ret = this.getQuestionElement(item, currentIndex);

                if (ret) return ret;
            }
        }

        if (cmp.complexChildren && cmp.complexChildren.length > 0) {
            for (const item of cmp.complexChildren) {
                const ret = this.getQuestionElement(item, currentIndex);

                if (ret) return ret;
            }
        }

        return null;
    }

    public static getAssessmentActions(reviewType?: ReviewTypeEnum): AssessmentActions {
        if (!reviewType) return null;        
        return this.assessmentActionList[reviewType];
    }

    public static getAssessmentLink(reviewPeriodEmployeeId: number) {
        return ['/assessment', reviewPeriodEmployeeId];
    }
}