<app-card #card class="card-content-no-vertical-padding" [useExternalButtons]="true" (editToggle)="onCardEditToggle($event)" [editDisabled]="!allowEdit">
    
    <div *ngIf="{isAllowSearch: isAllowSearch$ | async} as context" class="padding-right-lg">
        <app-search #search 
            [ngClass]="{hidden: !context.isAllowSearch || card.actionState.editMode}" 
            [isLargeInput]="true"
            [inlineButtons]="true" 
            [isAllowSearch]="true"             
            [config]="searchConfig"
            [showAddButton]="allowAdd"
            (add)="addItem($event)" 
            (change)="onOptionClick($event)" 
            ></app-search>
        
        <app-read-only-field label="Név" *ngIf="!context.isAllowSearch && !card.actionState.editMode" [value]="searchValue" class="text-lg"></app-read-only-field>

        <div class="input-with-buttons" *ngIf="card.actionState.editMode">
            <mat-form-field appearance="outline" *ngIf="card.actionState.editMode" class="editfield input-lg">
                <mat-label>{{ title }}</mat-label>
                <input matInput [formControl]="editControl" autocomplete="off">
                <mat-error msg></mat-error>
            </mat-form-field>           
            
            <div class="row-buttons">
                <button *ngIf="inlineButtons && card.actionState.editMode" [disabled]="!editControl.dirty" mat-icon-button color="primary" (click)="onOkClick()">
                    <mat-icon>done</mat-icon>
                </button>
                
                <button *ngIf="inlineButtons && card.actionState.editMode" mat-icon-button color="primary" (click)="onCancelClick()">
                    <mat-icon>highlight_off</mat-icon>
                </button>
            </div>            
        </div>

        <div *ngIf="employeeIdentifier">
            <mat-icon>perm_identity</mat-icon> {{ employeeIdentifier }}
        </div>
        
    </div>    
    
</app-card>