import { ReviewTypeEnum } from "src/app/enums/review-type.enum";
import { ReviewTypeStrategyBase } from "./review-type-strategy-base";
import { MilioStressStrategy, MilioTeamDysfunctionStrategy, PanoramaAuraGoalsStrategy, SziluettStrategy } from "./strategies";

export abstract class ReviewTypeStrategyFactory {
  
    public static create(reviewType: ReviewTypeEnum): ReviewTypeStrategyBase {
    switch (reviewType) {
      case ReviewTypeEnum.TER:
        return new PanoramaAuraGoalsStrategy();
      case ReviewTypeEnum.PersonalityTest:
        return new SziluettStrategy();
      case ReviewTypeEnum.MilioStressMap:
        return new MilioStressStrategy();
      case ReviewTypeEnum.MilioTeamDysfunction:
        return new MilioTeamDysfunctionStrategy();
      default:
        return null;
    }
  }

}