<mat-card [ngClass]="{'card-light': lightCard}">
    <div class="header-actions">
        <div class="header-buttons">
            <button mat-icon-button *ngIf="showDelete" color="primary" class="bt-delete hidden-print icon-bt-md" (click)="onDelete()" title="törlés">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button [color]="!actionState.editMode ? 'primary': 'accent'" [disabled]="editDisabled && !actionState.editMode" class="icon-bt-md" 
                *ngIf="showEdit && !disabled" (click)="onToggleEdit()"
            >
                <mat-icon>{{ !actionState.editMode ? 'edit' : 'close' }}</mat-icon>
            </button>
        </div>
    </div>

    <mat-card-header *ngIf="cardTitle || customTitle">
        <mat-card-title>
            <ng-container *ngIf="!customTitle">{{ cardTitle }}</ng-container>
            <ng-content *ngIf="customTitle" select="[slot=title]"></ng-content>
        </mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">{{ subtitle }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content [ngClass]="{'no-bottom-margin': noContentBottomMargin}">
        <form [formGroup]="formGroup" *ngIf="!disabled">
            <ng-content *ngIf="!actionState.editMode" select="[slot=readonly]"></ng-content>
            <ng-content *ngIf="actionState.editMode" select="[slot=edit]"></ng-content>
            <ng-content></ng-content>
        </form>
    </mat-card-content>
    <mat-card-actions *ngIf="!useExternalButtons && formMode && actionState.editMode && !disabled">
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onOk()" i18n >Ok</button>
        <button mat-flat-button (click)="onCancel()">Mégse</button>
    </mat-card-actions>
    <mat-card-footer *ngIf="showStatus">
        <div *ngIf="statusMsg" class="card-status"
                [ngClass]="{'text-danger': statusMsg.type == messageTypes.error, 'text-warning': statusMsg.type == messageTypes.warning, 'text-info': statusMsg.type == messageTypes.Info, 'text-success': statusMsg.type == messageTypes.success }"
            >
            <mat-icon fontSet="material-icons-outlined" *ngIf="statusMsg.icon">{{ statusMsg.icon }}</mat-icon>
            <span>{{ statusMsg.text }}</span>
        </div>
    </mat-card-footer>
</mat-card>