<div class="text-area-row">
    <mat-form-field appearance="outline" class="width-max">
        <mat-label>Felettesem kapcsolódó csapatcélja</mat-label>
        <textarea matInput readonly rows="3" autocomplete="off" 
            (click)="selectGroupGoal()"
            [disabled]="isDisabled || !data.isSelf"
        >{{ groupGoalDisplayText }}</textarea>
    </mat-form-field>    
    
    <button mat-flat-button color="primary" [disabled]="isDisabled || !data.isSelf" (click)="selectGroupGoal()" title="Kapcsolódó csapatcél kiválasztása">
        csapatcélok
    </button>
</div>
