<app-card #card cardTitle="Hiányzó értékelések" [useExternalButtons]="true" [showEdit]="isEditable" [disabled]="!settingState.reviewPeriod.value">
    <div>
        <div>
            <div class="flex-horizontal">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Kereső</mat-label>
                    <input matInput type="text" [formControl]="searchControl" autocomplete="off">
                    <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Törlés" (click)="clearText()">
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                
                <div class="view-icons">
                    <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [value]="viewMode" class="icons" *ngIf="isPanoramaOrAura">
                        <mat-button-toggle value="byEmployee" aria-label="Értékelő személy szerint csoportosítva" title="Értékelő személy szerint csoportosítva" (click)="viewByEmployee()">
                          <mat-icon>segment</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="aboutEmployee" aria-label="Értékelt személy szerint csoportosítva" title="Értékelt személy szerint csoportosítva" (click)="viewAboutEmployee()">
                          <mat-icon>reorder</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>

            <ng-container *ngIf="items$ | async as items">
                <div class="message-panel">
                    {{ searchMessage }}
                </div>
                <div class="scroll-panel">
                    <app-dynamic-list-item [actionState]="card.actionState" [inlineEdit]="false" [noActions]="hasSubitems"
                        (edit)="rowEdit(item.itemSource, item.itemSource)"
                        (delete)="rowDelete(item.itemSource.mainItem.reviewPeriodEmployeeId)"
                        *ngFor="let item of items; index as i" [index]="i" [source]="item">
                        <div slot="readonly">
                            <div class="mainitems" [ngClass]="{'border-bottom': hasSubitems}">
                                <div class="flex-horizontal" >
                                    <div class="field-text" [title]="(viewMode === 'byEmployee' ? 'Értékelő személy: ' : 'Értékelt személy: ') + item.itemSource.mainItem.name">
                                        <div class="name"><strong>{{ item.itemSource.mainItem.name }}</strong></div>
                                        <div class="sub-text">
                                            <span>{{ item.itemSource.mainItem.email }} </span>
                                            <span title="egyedi dolgozó azonosító">{{ item.itemSource.mainItem.employeeIdentifier ?? '' }}</span>
                                        </div>
                                        <div class="sub-text" title="határidő" *ngIf="item.itemSource.mainItem.deadline" [ngClass]="{'text-danger': isDateExpired(item.itemSource.mainItem.deadline)}">
                                            {{ item.itemSource.mainItem.deadline | date:'yyyy.MM.dd' }}
                                        </div>
                                    </div>
                                    <div class="row-action" *ngIf="card.actionState.editMode"></div>
                                </div>
                            </div>

                            <div class="subitems" *ngIf="item.itemSource.subItems && item.itemSource.subItems.length > 0">
                                <div class="flex-horizontal align-items-center" *ngFor="let subitem of item.itemSource.subItems">
                                    <div class="field-text" [title]="(viewMode === 'byEmployee' ? 'Értékelendő személy: ' : 'Értékelő személy: ') + subitem.name">
                                        <div class="name"><strong>{{ subitem.name }}</strong></div>
                                        <div class="sub-text">
                                            <div class="assessmentType">{{ getEmployeeType(subitem) }}</div>
                                            <span title="dolgozó email címe">{{ subitem.email }} </span>
                                            <span title="egyedi dolgozó azonosító">{{ subitem.employeeIdentifier ?? '' }}</span>
                                        </div>
                                    </div>
                                    <div class="row-action" *ngIf="card.actionState.editMode">
                                        <button mat-icon-button class="icon-bt-md" (click)="rowEdit(item.itemSource, subitem)" title="szerkesztés">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    
                                        <button mat-icon-button color="primary" (click)="rowDelete(subitem.reviewPeriodEmployeeId)" title="törlés">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-dynamic-list-item>
                </div>
                <mat-paginator #paginator [length]="total"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="pagingSettingsChanged($event)"
                            aria-label="Válasszon oldalt">
                </mat-paginator>

                <button mat-icon-button color="primary" (click)="add()" *ngIf="card.actionState.editMode" title="Értékelési kapcsolat hozzáadása">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>

                <button mat-icon-button color="primary" *ngIf="card.actionState.editMode && isPanoramaOrAura" (click)="massAddPeers()" title="Tömeges peer hozzáadás">
                    <mat-icon>queue</mat-icon>
                </button>
                
            </ng-container>
            
        </div>        
    </div>
</app-card>

