﻿<mat-card *ngIf="useCard && content && opened" class="card-light">
    <mat-card-header></mat-card-header>
    <mat-card-content>
        <div [innerHtml]="htmlData"></div>
    </mat-card-content>    
</mat-card>

<div *ngIf="!content && opened">
    <ng-content></ng-content>
</div>

<div *ngIf="!useCard" [innerHtml]="htmlData"></div>