import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-text-edit-panel',
  templateUrl: './text-edit-panel.component.html',
  styleUrls: ['./text-edit-panel.component.scss']
})
export class TextEditPanelComponent implements OnInit {

  @Output()
  public ok = new EventEmitter<string>();

  @Output()
  public cancel = new EventEmitter();
  
  @Input()
  public content = '';
  
  public editedText: string;

  // config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   defaultParagraphSeparator: 'p',
  //   fonts: [
  //     {class: 'arial', name: 'Arial'},
  //     {class: 'times-new-roman', name: 'Times New Roman'},
  //     {class: 'calibri', name: 'Calibri'},
  //     {class: 'comic-sans-ms', name: 'Comic Sans MS'},
  //     {class: 'roboto', name: 'Roboto'},
  //   ],
  //   defaultFontName: 'Roboto',
  //   toolbarHiddenButtons: [],
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ]
  // };

  constructor() { }

  ngOnInit(): void {
  }

  onOk() {    
    this.ok.emit(this.content);
  }

  onCancel() {
    this.cancel.emit();
  }

}
