<app-card #card cardTitle="Vezető" [useExternalButtons]="true" [editDisabled]="!allowEdit">
    <div *ngIf="items$ | async as items">
        <div>                        
            <app-dynamic-list-item (delete)="deleteItem(item)" [actionState]="card.actionState" [noEdit]="true" *ngFor="let item of items; index as i" [index]="i" [source]="item">
                <div slot="readonly" class="flex-horizontal width-max read-only-area">
                    <div class="field-name">{{ displayLeaderName(item.itemSource.leader) }}</div>
                </div>
            </app-dynamic-list-item>
        </div>
        <app-search #search *ngIf="card.actionState.editMode"(add)="addItem($event)" [inlineButtons]="true" [isAllowSearch]="true" [config]="searchConfig" [disabledAddButton]="!allowAdd"></app-search>
    </div>
</app-card>
