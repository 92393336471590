﻿<form [formGroup]="mainForm">
    <h1 mat-dialog-title>Email küldés</h1>
    <div mat-dialog-content>

        <h5>Kiküldés módja</h5>
        <mat-radio-group formControlName="sendType" (change)="changeSettings()">
            <mat-radio-button value="1" title="egyéni kiküldés a kiválasztott személynek">Egyéni</mat-radio-button>
            <div class="single-mail-panel sub-panel">            
                <app-search [disabled]="mainForm.controls['sendType'].value != '1'" #search (change)="onSearchChange($event)" title="Dolgozó név vagy email" [isAllowSearch]="true" [showAddButton]="false" [config]="searchEmployeeConfig"></app-search>
            </div>
            <mat-radio-button value="2" title="tömeges kiküldés a kiválasztott értékelés résztvevőinek">Tömeges</mat-radio-button>
            <div class="mass-mail-panel sub-panel">
                <div>a kiválasztott értékelés összes résztvevője részére</div>
                <div class="flex-h margin-bottom-sm serial-interval">
                    <mat-form-field appearance="outline" class="field-simple" title="küldés sorszámtól">
                        <mat-label>Sorsz. -tól</mat-label>
                        <input matInput type="text" formControlName="sernumFrom" autocomplete="off">
                        <mat-error msg></mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="field-simple" title="küldés sorszámig">
                        <mat-label>Sorsz. -ig</mat-label>
                        <input matInput type="text" formControlName="sernumTo" autocomplete="off">
                        <mat-error msg></mat-error>
                    </mat-form-field>
                </div>            

            </div>
        </mat-radio-group>

        <h5>Email sablon</h5>
        <mat-radio-group formControlName="mailType" (change)="changeSettings()">
            <mat-radio-button value="1">Regisztrációs levél</mat-radio-button>
            <mat-radio-button value="2" *ngIf="mainForm.controls['sendType'].value != '2'">Elfelejtett jelszó</mat-radio-button>
            <mat-radio-button value="4">Emlékeztető</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="mainForm.controls['mailType'].value == '1' && mainForm.controls['sendType'].value == '2'">
            <h5>Opciók</h5>
            <div class="margin-bottom-sm">
                <mat-checkbox formControlName="onlyForNewEmployees" title="Csak az új regisztráltaknak küldjük (akik még nem kaptak levelet)">Csak az új regisztráltaknak</mat-checkbox>
            </div>
        </div>

        <div *ngIf="mailLastProgress$ | async as lastProgress" class="mb-4">
            <app-page-progress [lightMode]="true" [value]="getProgress(lastProgress)" [currentItem]="lastProgress.itemIndex"  [maxItems]="lastProgress.total"></app-page-progress>

            <div class="flex-h gap-10 progress-indicators">
                <mat-card class="flex1">
                    <mat-card-content class="flex-v">
                        <div class="indicator-panel">
                            <div class="sub-text">sikeres</div>
                            <strong class="text-success text-3xl">{{ addressWithSuccess }}</strong>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="flex1">
                    <mat-card-content class="flex-v">
                        <div class="indicator-panel">
                            <div class="sub-text">sikertelen</div>
                            <strong class="text-danger text-3xl">{{ addressWithError }}</strong>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <ng-container *ngIf="mailResultFeature">
            <div *ngIf="mailResults$ | async as mailResult" class="scroll-panel-400">
                <table mat-table [dataSource]="mailResult" *ngIf="mailResult.length > 0" class="table-default">
        
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Dolgozó</th>
                        <td mat-cell *matCellDef="let element">
                            <strong>{{ element.name }}</strong>
                            <div class="sub-text">{{ element.email }} {{element.employeeIdentifier}}</div>
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="isSuccess">
                        <th mat-header-cell *matHeaderCellDef>Küldés állapot</th>
                        <td mat-cell *matCellDef="let element">
                            <span [ngClass]="{'text-success': element.isSuccess, 'text-danger': !element.isSuccess}" >{{ element.isSuccess ? 'Sikeres' : 'Sikertelen' }}</span>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </ng-container>
  
    </div>
    <div mat-dialog-actions class="flex-h flex-end action-buttons">
        <mat-progress-spinner *ngIf="inProgress"
            diameter="28"
            color="primary"
            mode="indeterminate">
        </mat-progress-spinner>
        <button mat-flat-button color="primary" [disabled]="inProgress || !isValidSendType || !isValidMailType || !isValidEmail" (click)="sendMail()">Küldés</button>
        <button mat-flat-button color="primary" [mat-dialog-close]>Bezárás</button>
    </div>
</form>