import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssessmentTypeEnum } from 'src/app/enums/assessment-type.enum';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { AssessmentType, ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { Employee } from 'src/app/models/employee.model';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { QuestionSet } from 'src/app/models/questionset-model';
import { ReviewPeriodEmployeeAssignment } from 'src/app/models/review-period-employee-assignment.model';
import { ReviewPeriodEmployeeForCreate } from 'src/app/models/review-period-employee-for-create.model';
import { ReviewPeriodEmployee } from 'src/app/models/review-period-employee.model';
import { AssessmentService, EmployeeService } from 'src/app/services';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { SettingsState } from 'src/app/store/settings.state';

@Component({
  selector: 'app-employee-review-dialog',
  templateUrl: './employee-review-dialog.component.html',
  styleUrls: ['./employee-review-dialog.component.scss']
})
export class EmployeeReviewDialogComponent implements OnInit {

  public mainForm: UntypedFormGroup;

  public employees: Employee[];  
  public questionSetList: QuestionSet[] = [];

  public loaded = false;

  public get questionSets(): FormArray {
    return this.mainForm.get('questionSets') as FormArray;
  }

  public get assessmentTypeList(): AssessmentType[] {
    return ReviewPeriodHelper.getAssessmentTypeList();
  }

  public get reviewType(): ReviewTypeEnum {
    return this.settingsState?.reviewPeriod.value?.reviewType;
  }

  public get isPersonalQuestionSetEnabled(): boolean {
    return this.settingsState?.reviewPeriod.value?.isSpecialQuestionSetBasedAssessment;
  }

  public get isPositionBasedAssessmentEnabled(): boolean {
    return this.settingsState?.reviewPeriod.value?.isPositionBasedAssessment;
  }

  public searchEmployeeConfig: SearchConfig<Employee, number> = {
    mapItem: (model: Employee) => {
      return {
        id: model.id, 
        text: `${model.name} (${model.email})`,
        dropText: `${model.name} ${model.employeeIdentifier ?? ''}`,
        itemSource: model } as SearchOption<Employee, number>;
    },
    getList: this.employeeService.getList
};

public reviewTypes = ReviewTypeEnum;

  constructor(
    private fb: UntypedFormBuilder, 
    public dialogRef: MatDialogRef<EmployeeReviewDialogComponent>,    
    private msgService: ErrorMessageService,
    private assessmentService: AssessmentService,
    private employeeService: EmployeeService,
    private settingsState: SettingsState,
    @Inject(MAT_DIALOG_DATA) public data: ReviewPeriodEmployee) { }

  ngOnInit(): void {
    this.mainForm = this.createForm();

    this.loaded = false;

    if (this.data.reviewPeriodEmployeeId) {   // Edit
      this.assessmentService.getEmployeeAssignmentForEdit(this.data.reviewPeriodEmployeeId).subscribe({ next: result => {
                
        this.questionSetList = this.setupQuestionSetsList(result);              
        this.loaded = true;
        const formData = this.createFormData(result);
        
        this.mainForm.patchValue(formData);
        this.patchList();
                
      }, error: () => {

      }});
    }
    else
    {
      this.assessmentService.getEmployeeAssignmentForAdd(this.data.reviewPeriod.id).subscribe({ next: result => {

        this.loaded = true;        
        this.questionSetList = this.setupQuestionSetsList(result);

        const formData = this.createFormData(result);
        this.mainForm.patchValue(formData);

        this.patchList();

      }, error: () => {

      }});
    }
    
  }

  private setupQuestionSetsList(data: ReviewPeriodEmployeeAssignment) {
    return data.personalQuestionSets.map(item => {
      item.isSelected = data.selectedQuestionSets ? data.selectedQuestionSets.includes(item.id) : false;
      return item;
    });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      'id': 0,
      'reviewPeriodId': this.data.reviewPeriod.id,
      'byEmployee': [null, [Validators.required]],
      'aboutEmployee': [null],
      'assessmentType': AssessmentTypeEnum.Autodetect,
      'isAuraLeader': false,
      'deadline': [null],
      'questionSets': this.fb.array([]),
    });
  }

  private createFormData(result: ReviewPeriodEmployeeAssignment): any {

    let byEmployee: SearchOption<Employee, number> = null;
    let aboutEmployee: SearchOption<Employee, number> = null;
    
    if (this.data.byEmployee)
    {
      byEmployee = {
        id: this.data.byEmployee.id, 
          dropText: this.data.byEmployee.name, 
          text: this.data.byEmployee.name,
          itemSource: this.data.byEmployee
      } as SearchOption<Employee, number>;
    }

    if (this.data.aboutEmployee)
    {
      aboutEmployee = { 
        id: this.data.aboutEmployee.id, 
        dropText: this.data.aboutEmployee.name, 
        text: this.data.aboutEmployee.name,
        itemSource: this.data.aboutEmployee
      } as SearchOption<Employee, number>;
    }
    
    const formData = {
      id: result.reviewPeriodEmployeeId,
      reviewPeriodId: result.reviewPeriodId,      
      byEmployeeId: result.byEmployee?.id,
      aboutEmployeeId: result.aboutEmployee?.id,
      assessmentType: result.assessmentType ?? AssessmentTypeEnum.Autodetect,
      deadline: result.deadline,
      isAuraLeader: result.isAuraLeader,
    } as ReviewPeriodEmployeeForCreate;
    
    return {...formData, byEmployee: byEmployee, aboutEmployee: aboutEmployee};
  }

  patchQuestionSet(qSet: QuestionSet)
  {
    return this.fb.group({
      'id': qSet.id,
      'name': qSet.name,
      'isSelected': qSet.isSelected
    });
  }

  onByEmployeeChange(option: SearchOption<Employee, number>) {
    //this.byEmployee = option;
    //this.mainForm.get('byEmployeeId').setValue(this.byEmployee?.id);
  }

  onAboutEmployeeChange(option: SearchOption<Employee, number>) {
    //this.aboutEmployee = option;
    //this.mainForm.get('aboutEmployeeId').setValue(this.aboutEmployee?.id);
  }
  
  private patchList() {
    const list = <FormArray>this.mainForm.get('questionSets');
    list.clear();

    this.questionSetList.forEach(item => {
      list.push(this.patchQuestionSet(item));
    });
  }

  public ok() {
    if (!this.mainForm.invalid) {

      let formData = this.mainForm.value;

      const dto = {
        id: formData.id,
        reviewPeriodId: formData.reviewPeriodId,
        byEmployeeId: formData.byEmployee.id,
        aboutEmployeeId: formData.aboutEmployee?.id,
        assessmentType: formData.assessmentType,
        deadline: formData.deadline,
        selectedQuestionSets: formData.questionSets.filter(q => q.isSelected).map(q => q.id),
        isAuraLeader: formData.isAuraLeader
      } as ReviewPeriodEmployeeForCreate;
            
      const action = this.data.reviewPeriodEmployeeId ? 
        this.assessmentService.updateReviewPeriodEmployee(dto) : this.assessmentService.addReviewPeriodEmployee(dto);

      action.subscribe({ 
          next: (data) => {
            this.dialogRef.close(true);
          }, 
          error: (err: IResultModel<ResultModel>) => {
            this.msgService.handleError<ResultModel>(err);
          }
        }
      );      

    }
  }

}
