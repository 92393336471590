<div class="container-fluid main-container">
    <div class="w-full-hd card-container" *ngIf="model$ | async">
                        
            <div class="box-search">
                <app-employee-search></app-employee-search>
            </div>
            <div class="box-rp" *ngIf="selectedUserHasEmployee">
                <app-review-period componentTitle="Értékelések"></app-review-period>
            </div>        
        
            <div class="box-contact">
                <app-contact-details></app-contact-details>
            </div>
            <div class="box-position" *ngIf="selectedUserHasEmployee">
               <app-employee-position></app-employee-position>
            </div>
            
            <div class="box-password">
                <app-change-password [email]="userProfile.email" ></app-change-password>
            </div>            
            <div class="box-leader" *ngIf="selectedUserHasEmployee">
                <app-leader></app-leader>
            </div>
        
            <div class="box-roles" *ngIf="isLoggedInSA || isLoggedInAdmin">
                <app-user-roles></app-user-roles>
            </div>

            <div class="box-status" *ngIf="userProfile && (isLoggedInSA || isLoggedInAdmin || isLoggedInAssistant || isDeleteInProgress) && (!isSelf || isDeleteInProgress)">
                <app-card #card cardTitle="Állapot" [showEdit]="false">
                                        
                    <div slot="readonly">
                        <div *ngIf="!isEmployeeInactive && !isDeleteInProgress">
                            <div>
                                <button mat-flat-button color="warn" class="hidden-print icon-button vertical-middle bg-success" (click)="markForDelete()" title="Felhasználó törlése">
                                    <mat-icon>delete</mat-icon> Felhasználó törlése
                                </button>
                            </div>
                        </div>

                        <div *ngIf="isDeleteInProgress">
                            <p>
                                Ez a felhasználói fiók elő van jegyezve törlésre.<br>
                                A törlés dátuma: <strong>{{ userProfile?.employee?.employeeEvent?.deadline | date:'yyyy.MM.dd'}}</strong><br>
                                Ha továbbra is szüksége van a profiljára, kérjük kattintson a következő gombra:
                            </p>

                            <button mat-flat-button color="primary" class="hidden-print icon-button vertical-middle bg-success" (click)="cancelDelete()" title="Felhasználói fiók és a riportok megőrzése">
                                Felhasználói fiók és a riportok megőrzése
                            </button> 
                        </div>

                        <div *ngIf="isEmployeeInactive">
                            A dolgozó törölt.
                        </div>
                    </div>
                
                </app-card>
            </div>
                   
            <!-- <div class="box-kpi" *ngIf="selectedUserHasEmployee">
                <app-kpi></app-kpi>
            </div> -->
        
    
    </div>

    
</div>
