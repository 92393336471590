<div [formGroup]="editForm" class="multi-column-layout">

    <div class="flex-vertical col-box">

        <input type="hidden" name="id" formControlName="id">    
        
        <mat-form-field appearance="outline" class="flex-fill">
            <mat-label>Típus</mat-label>
            <mat-select formControlName="reviewType" disableOptionCentering class="drop-fix" (selectionChange)="reviewTypeChanged()" required>
                <mat-option *ngFor="let item of reviewTypes" [value]="item.id">{{ item.name }}</mat-option>
            </mat-select>       
        </mat-form-field>
       
        <mat-form-field appearance="outline" class="flex-fill" *ngIf="isPanorama(selectedReviewType)">
            <mat-label>Előző értékelés (sorozat folytatása esetén)</mat-label>
            <mat-select formControlName="previousId" disableOptionCentering class="drop-fix" title="Itt adható meg ha egy Panoráma értékelésnek volt már korábban előzménye rögzítve. (Q1, Q2, stb.) Ebből tudjuk, melyik értékelésből kell átmásolni az előzményeket, pl: pl. csoporttagság">
                <mat-option *ngFor="let item of prevReviewPeriodList" [value]="item.id">{{ item | periodName }}</mat-option>
            </mat-select>
            <mat-error msg></mat-error>
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Érvényességi időszak</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate formControlName="startDate" placeholder="Kezdés">
              <input matEndDate formControlName="endDate" placeholder="Befejezés">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    
        <mat-form-field appearance="outline" title="Nevezzük el az értékelést, hogy megkülönböztethető legyen később a listában, pl: 2023 Q1">
            <mat-label>Megnevezés</mat-label>
            <input matInput type="text" name="name" formControlName="name" required>
        </mat-form-field>
    
        <!-- <mat-form-field appearance="outline">
            <mat-label>Leírás</mat-label>
            <input matInput type="text" name="description" formControlName="description">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="width-max hidden-print">
            <mat-label>Instrukciók</mat-label>
            <textarea matInput name="instructions" formControlName="instructions" autocomplete="off"></textarea>
        </mat-form-field> -->
    
        <div *ngIf="isPanorama(selectedReviewType)" class="settings-panel">
            <div class="panel-title">Típus beállítások 
                <ng-container *ngIf="!allowSettingsEdit">
                    <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
                </ng-container>
            </div>
            <div class="panel-content simple-border">            
                <mat-checkbox class="multiline" formControlName="isPositionBasedAssessment" [disableControl]="!allowSettingsEdit" (change)="updateModules($event)">Panoráma kompetenciaértékelés</mat-checkbox>
                <mat-checkbox class="multiline" formControlName="isGoalsEnabled" [disableControl]="!allowSettingsEdit" (change)="changeIsGoals($event)">Panoráma célkitűzés</mat-checkbox>
                <mat-checkbox class="multiline" formControlName="isSpecialQuestionSetBasedAssessment" [disableControl]="!allowSettingsEdit" (change)="updateModules($event)">Aura</mat-checkbox>
            </div>
        </div>
    
        <div *ngIf="isPanorama(selectedReviewType) && (isPositionBasedAssessment || isAura || isGoalsEnabled)" class="settings-panel">
            <div class="panel-title">
                <ng-container *ngIf="!allowSettingsEdit">
                    <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
                </ng-container>
            </div>
            <div class="panel-content">
                <div *ngIf="isPositionBasedAssessment || isAura">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Panoráma/Aura komp. ért. nyitva</mat-label>
                        <mat-date-range-input [rangePicker]="pickerPanorama">
                          <input matStartDate formControlName="panoramaAuraStartDate" placeholder="Kezdés">
                          <input matEndDate formControlName="panoramaAuraEndDate" placeholder="Befejezés">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="pickerPanorama"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerPanorama></mat-date-range-picker>
                    </mat-form-field>
                </div>  
                <div *ngIf="isGoalsEnabled"> 
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Panoráma célkitűzés nyitva</mat-label>
                        <mat-date-range-input [rangePicker]="pickerGoals">
                          <input matStartDate formControlName="goalsStartDate" placeholder="Kezdés">
                          <input matEndDate formControlName="goalsEndDate" placeholder="Befejezés">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="pickerGoals"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerGoals></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    
    </div>

    <div class="flex-vertical col-box">
        <div *ngIf="assessmentActions && (isPositionBasedAssessment || isAura || isGoalsEnabled)" class="settings-panel">
            <div class="panel-title">Általános beállítások 
                <ng-container *ngIf="!allowSettingsEdit">
                    <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
                </ng-container>
            </div>
            <div class="panel-content simple-border">            
                <mat-checkbox class="multiline" formControlName="isFreeTextAssessmentAllowed" *ngIf="assessmentActions.isFreeTextAssessmentAllowed && (isPositionBasedAssessment || isAura)" [disableControl]="!allowSettingsEdit">szabadszavas visszajelzés lehetősége</mat-checkbox>
                <mat-checkbox class="multiline" formControlName="copyEmployees" *ngIf="assessmentActions.copyEmployees && addMode" [disableControl]="!allowSettingsEdit">
                    dolgozók automatikus hozzáadása <mat-icon class="info" matTooltip="Új értékelés esetén a cég minden dolgozóját automatikusan felveszi az értékelésbe. Panoráma értékelés folytatása esetén, viszont az *Előző Értékelés* mezőnél kiválasztott értékelésből fogja automatikusan felvenni a dolgozókat.">info</mat-icon></mat-checkbox>
                <mat-checkbox class="multiline" formControlName="updateRpe" *ngIf="assessmentActions.updateRpe && !addMode" [disableControl]="!allowSettingsEdit">ki-kit értékel frissítése</mat-checkbox>
            </div>
        </div>
    </div>

    <div class="flex-vertical col-box">
        <div *ngIf="isPanorama(selectedReviewType) && isPositionBasedAssessment" class="settings-panel questionsets">
            <div class="panel-title">Kérdőívek
                <ng-container *ngIf="!allowSettingsEdit">
                    <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
                </ng-container>
            </div>
            <div class="panel-content simple-border scroll-box max-height-300">
                <ul>
                    <li *ngFor="let child of displaySelectedQuestionSets; let i = index">
                        <div>
                            <strong>{{ getQuestionSetName(child) }}</strong> <span class="desc">{{ getQuestionSetDesc(child) }}</span>
                        </div>
                        <div class="sub-text">{{ child.questionSetCreatedOn | date:"yyyy.MM.dd"}}</div>
                    </li>
                </ul>
                <!-- <ng-container >
                    <ng-container [formGroupName]="i">
                        <mat-checkbox class="multiline" formControlName="isSelected" [ngClass]="{'subQuestionSet': isSubQuestionSet(child.value)}" [disableControl]="!allowSettingsEdit"></mat-checkbox>
                    </ng-container>
    
                </ng-container> -->
               
            </div>
        </div>
    </div>

    <div class="flex-vertical col-box">
        <div formArrayName="questionSettings" class="settings-panel" *ngIf="assessmentActions && assessmentActions.questionModuleSettings && questionSettingsArray.controls.length > 0">
            <div class="panel-title">Kérdés modul beállítások 
                <ng-container *ngIf="!allowSettingsEdit">
                    <ng-container *ngTemplateOutlet="reviewInProgressInfo"></ng-container>
                </ng-container>
            </div>
            <div class="panel-content simple-border">
                <div *ngFor="let child of questionSettingsArray.controls; let i = index">
                    <div class="settings-group" [formGroupName]="i">
                        <div class="group-title">{{ removeHtml(child.get("questionName").value) }}</div>
                        <mat-checkbox class="multiline" formControlName="isSelfAssessmentAllowed" [disableControl]="!allowSettingsEdit">önértékelés</mat-checkbox>
                        <mat-checkbox class="multiline" formControlName="isLeaderAssessmentAllowed" [disableControl]="!allowSettingsEdit">beosztottak értékelik a vezetőket</mat-checkbox>
                        <mat-checkbox class="multiline" formControlName="isPeerAssessmentAllowed" [disableControl]="!allowSettingsEdit">peer-ek értékelik egymást</mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    
        <mat-form-field appearance="outline" class="settings-panel flex-fill">
            <mat-label>Állapot</mat-label>
            <mat-select formControlName="status" disableOptionCentering class="drop-fix" required>
                <mat-option *ngFor="let status of statusList" [value]="status.id">{{ status.name }}</mat-option>
            </mat-select>
            <mat-error msg></mat-error>
        </mat-form-field>
        
    </div>
</div>

<div class="action-buttons flex-end">
    <button mat-flat-button color="primary" (click)="save()" [disabled]="editForm.invalid">Ok</button>
    <button mat-flat-button (click)="cancel()">Mégsem</button>
</div>

<ng-template #reviewInProgressInfo>
    <mat-icon matTooltip="Létező értékelés esetén ezen beállítások már nem módosíthatók.">error_outline</mat-icon>
</ng-template>
