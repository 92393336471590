import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AboutEmployeeAddDialogData } from 'src/app/models/dialog/about-employee-add-dialog-data.model';
import { AboutEmployeeAddDialogResult } from 'src/app/models/dialog/about-employee-add-dialog-result.model';

@Component({
  selector: 'app-about-employee-add-dialog',
  templateUrl: './about-employee-add-dialog.component.html',
  styleUrl: './about-employee-add-dialog.component.scss'
})
export class AboutEmployeeAddDialogComponent implements OnInit {

  public isAuraLeader = false;

  constructor(
    public dialogRef: MatDialogRef<AboutEmployeeAddDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: AboutEmployeeAddDialogData
  ) {
  
  }

  ngOnInit() {

  }

  ok () {
    let result = {
      isAuraLeader: this.isAuraLeader
    } as AboutEmployeeAddDialogResult;

    this.dialogRef.close(result);
  }
}
