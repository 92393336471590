<form [formGroup]="mainForm" *ngIf="loaded">
    <h1 mat-dialog-title>Értékelési kapcsolat</h1>
    <div mat-dialog-content>
  
        <app-search title="Értékelő személy" formControlName="byEmployee" [showAddButton]="false" [inlineButtons]="false" [isAllowSearch]="true" [config]="searchEmployeeConfig" 
            (change)="onByEmployeeChange($event)"
        ></app-search>
       
        <app-search *ngIf="reviewType === reviewTypes.TER" formControlName="aboutEmployee"
            title="Értékelt személy" [showAddButton]="false" [inlineButtons]="false" [isAllowSearch]="true" [config]="searchEmployeeConfig"
            (change)="onAboutEmployeeChange($event)"
        ></app-search>

        <div class="about-employee-options">
            <mat-checkbox *ngIf="isPersonalQuestionSetEnabled && isPositionBasedAssessmentEnabled" class="multiline" formControlName="isAuraLeader">Az értékelt személy egy Aura 360 értékelt vezető</mat-checkbox>
        </div>
        
        <mat-form-field appearance="outline" *ngIf="reviewType === reviewTypes.TER" class="width-max">
            <mat-label>Értékelés iránya</mat-label>
            <mat-select formControlName="assessmentType" disableOptionCentering class="drop-fix">
                <mat-option *ngFor="let item of assessmentTypeList" [value]="item.id">{{ item.name }}</mat-option>
            </mat-select>
            <mat-error msg></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="width-max">
            <mat-label>Kitöltési határidő</mat-label>
            <input matInput [matDatepicker]="pickerStartEdit" formControlName="deadline">
            <mat-datepicker-toggle matSuffix [for]="pickerStartEdit"></mat-datepicker-toggle>
            <mat-datepicker #pickerStartEdit></mat-datepicker>
        </mat-form-field>

        <div formArrayName="questionSets" class="settings-panel" *ngIf="isPersonalQuestionSetEnabled">
            <div class="panel-title">Egyedi kérdőívek</div>
            <div class="panel-content simple-border">
                <ng-container *ngFor="let child of questionSets.controls; let i = index">
                    <ng-container [formGroupName]="i">
                        <mat-checkbox class="multiline" formControlName="isSelected">{{ questionSetList[i].name }}</mat-checkbox>
                    </ng-container>

                </ng-container>
               
            </div>
        </div>        
  
    </div>
    <div mat-dialog-actions class="flex-h flex-end">
      <button mat-flat-button color="primary" (click)="ok()" type="submit">Ok</button>
      <button mat-flat-button [mat-dialog-close]>Mégsem</button>
    </div>
  </form>
