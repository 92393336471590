import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { EmployeeEmailsCheck, EmployeeEmailsCheckResult } from "../models/employee-emails-check.model";
import { Employee } from "../models/employee.model";
import { PagedListResult } from "../models/paged-list-result.model";
import { Result, ResultModel } from "../models/general/result.model";
import { ReviewPeriodEmployeeForAdd } from "../models/review-period-employee-for-add.model";
import { Search } from "../models/search.model";
import { UserProfile } from "../models/user-profile.model";
import { ApiHelper } from "../shared/helpers/api.helper";
import { DefaultDataService } from "./default-data.service";
import { StateService } from "./state.service";
import { EmployeeSettings } from "../models/employee-settings.model";
import { EmployeeMigration } from "../models/other/employee-migration.model";
import { MigrationResult } from "../models/other/migration-result.model";
import { IdName } from "../models/general/id-name.model";
import { EmployeeImportResult } from "../models/other/employee-import-result.model";

@Injectable({
    providedIn: 'root'
})
export class EmployeeService extends DefaultDataService<Employee> {
    
    constructor(
        http: HttpClient, 
        stateService: StateService,
    ) 
    {
        super(http, stateService);
        this.controllerName = 'employee';
    }

    getList = (options?: Search): Observable<PagedListResult<Employee>> => {
        if (!options) options = {search: ''} as Search;

        return this.post<PagedListResult<Employee>>(`/api/${this.controllerName}/list`, options, {headers: {'X-Skip-Interceptor':'1'}});
    }

    getListAsIdName = (options?: Search): Observable<PagedListResult<IdName>> => {
        return this.getList(options).pipe(
            map(x => {
                let pList = {
                    pageCount: x.pageCount,
                    page: x.page,
                    pageSize: x.pageSize,
                    total: x.total,
                    list: x.list.map(model => {
                        let data = {
                            id: model.id,
                            name: model.name,
                            description: `${model.employeeIdentifier ? model.employeeIdentifier : model.email}`
                        } as IdName;
                        return data;
                    }), 
                } as PagedListResult<IdName>;                
                return pList;
            })
        );
    }    

    public editName(data: UserProfile): Observable<void> {
        return this.post(`/api/${this.controllerName}/editName`, data.employee, ApiHelper.addTargetUserId(data.id));
    }

    public getListByEmails(data: EmployeeEmailsCheck): Observable<EmployeeEmailsCheckResult> {
        return this.post(`/api/${this.controllerName}/emails`, data);
    }

    public isBossOf(userId: string): Observable<Result<boolean>> {
        return this.get<Result<boolean>>(`/api/${this.controllerName}/is-boss-of/${userId}`);
    }

    public addToReviewPeriod(data: ReviewPeriodEmployeeForAdd): Observable<ResultModel> {
        return this.put<ResultModel>(`/api/${this.controllerName}/add-to-review-period`, data);
    }
     
    public deleteFromReviewPeriod(data: ReviewPeriodEmployeeForAdd): Observable<ResultModel> {
        return this.delete<ResultModel>(`/api/${this.controllerName}/remove-from-review-period/${data.reviewPeriodId}/${data.employeeId}`);
    }

    public updateSettings(data: EmployeeSettings): Observable<void> {
        return this.post<void>(`/api/${this.controllerName}/update-settings`, data);
    }
    
    public migrateEmployees(data: EmployeeMigration): Observable<EmployeeImportResult> {
        return this.post<EmployeeImportResult>(`/api/${this.controllerName}/migrate-employees`, data);
    }

    checkEmails = (data: EmployeeEmailsCheck): Observable<EmployeeEmailsCheckResult> => {
        return this.post<EmployeeEmailsCheckResult>(`/api/${this.controllerName}/check-emails`, data);
      }
}