import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { QuestionsForAssessment } from '../models/questions-for-assessment.model';
import { EmployeeForAssessments } from '../models/employee-for-assessments.model';

import { DataService } from './data.service';
import { StateService } from './state.service';
import { Assessment } from '../models/assessment.model';
import { Result, ResultModel } from '../models/general/result.model';
import { CompleteReview } from '../models/complete-review.model';
import { Search } from '../models/search.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { OpenAssessmentEmployee } from '../models/open-assessment-employee.model';
import { EmployeeAssessment } from '../models/employee-assessment.model';
import { ReviewPeriodEmployeeForCreate } from '../models/review-period-employee-for-create.model';
import { ReviewPeriodEmployeeAssignment } from '../models/review-period-employee-assignment.model';
import { AddPeersToReviewPeriodValidation, EmployeeEmailsCheckResult } from '../models/employee-emails-check.model';
import { EmployeeBulkInsert } from '../models/employee-bulk-insert.model';
import { ReviewPeriodEmployeeForEmployee } from '../models/review-period-employee-for-employee.model';
import { IdName } from '../models/general/id-name.model';
import { formatDate } from '@angular/common';
import { AssessmentOptions } from '../models/assessment-options.model';
import { ReviewPeriodHelper } from '../helpers/review-period.helper';
import { ReviewFilterOptions } from '../models/other/review-filter-options.model';
import { DateHelper } from '../shared/helpers';

@Injectable({
    providedIn: 'root',
})
export class AssessmentService extends DataService {

    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);
    }
            
    public getAssessmentData(data: AssessmentOptions): Observable<Result<QuestionsForAssessment>> {
        const url = `/api/assessments/assessment`;
        return this.post<Result<QuestionsForAssessment>>(url, data);
    }

    public submit(data: QuestionsForAssessment): Observable<void> {
        return this.post('/api/assessments/submit', data);
    }

    public saveSingleAssessment(data: Assessment): Observable<Result<number>> {
        return this.post('/api/assessments/saveSingleAnswer', data);
    }

    public saveFeedback(reviewPeriodEmployeeId: number, feedback: string): Observable<void> {
        return this.post('/api/assessments/saveFeedback', { reviewPeriodEmployeeId, feedback });
    }

    public completeReview(data: CompleteReview): Observable<ResultModel> {
        return this.post<ResultModel>('/api/assessments/completeReview', data);
    }

    public getName(model: EmployeeForAssessments): string {
        let name = "";
        if (model) {
            if (model.isSelf && ReviewPeriodHelper.isPanoramaAuraGoals(model.reviewType)) return "Önértékelés";
            
            name = model.name + " (" + model.email + ')';
            
            if (model.isContractor) name = name + " - szerződéses";
        }
        return name;
    }    

    public getOpen(dto: Search): Observable<PagedListResult<OpenAssessmentEmployee>> {
        return this.post<PagedListResult<OpenAssessmentEmployee>>('/api/assessments/open', dto, {headers: {'X-Skip-Interceptor':'1'}});
    }

    public getCompleted(dto: Search): Observable<PagedListResult<EmployeeAssessment>> {
        return this.post<PagedListResult<EmployeeAssessment>>('/api/assessments/completed', dto);
    }

    public getEmployeesToReview(options: ReviewFilterOptions): Observable<EmployeeForAssessments[]> {
        return this.post<EmployeeForAssessments[]>('/api/assessments/employeesToReview', options, {headers: {'X-Skip-Interceptor':'1'}});
    }

    public addReviewPeriodEmployee(model: ReviewPeriodEmployeeForCreate): Observable<ResultModel> {
        let data = {
            ...model,
            deadline: DateHelper.toPlainDate(model.deadline).toString(),
        }

        return this.put<ResultModel>('/api/assessments/add-review-period-employee', data);
    }

    public updateReviewPeriodEmployee(model: ReviewPeriodEmployeeForCreate): Observable<ResultModel> {
        let data = {
            ...model,
            deadline: DateHelper.toPlainDate(model.deadline).toString(),
        }

        return this.post<ResultModel>('/api/assessments/update-review-period-employee', data);
    }

    // ReviewPeriodEmployee Assignment Dialog

    public getEmployeeAssignmentForAdd(reviewPeriodId: number): Observable<ReviewPeriodEmployeeAssignment> {
        return this.get<ReviewPeriodEmployeeAssignment>('/api/assessments/get-employee-assignment-for-add/' + reviewPeriodId);
    }

    public getEmployeeAssignmentForEdit(reviewPeriodEmployeeId: number): Observable<ReviewPeriodEmployeeAssignment> {
        return this.get<ReviewPeriodEmployeeAssignment>('/api/assessments/get-employee-assignment-for-edit/' + reviewPeriodEmployeeId);
    }

    public deleteReviewPeriodEmployee(reviewPeriodEmployeeId: number): Observable<ResultModel> {
        return this.delete<ResultModel>('/api/assessments/delete-review-period-employee/' + reviewPeriodEmployeeId);
    }

    public addPeers(employeeList: number[], reviewPeriodId: number, aboutEmployeeId?: number): Observable<void> {
        const model = {
          employeeIdList: employeeList,
          reviewPeriodId: reviewPeriodId,
          aboutEmployeeId: aboutEmployeeId
        } as EmployeeBulkInsert;
    
        return this.put<void>(`/api/assessments/add-peers`, model);
    }

    getListForEmployee = (options?: Search): Observable<PagedListResult<IdName>> => {
        if (!options) options = {search: ''} as Search;

        return this.post<PagedListResult<ReviewPeriodEmployeeForEmployee>>(`/api/assessments/list-for-employee`, options, {headers: {'X-Skip-Interceptor':'1'}})
        .pipe(
            map(x => {
                let pList = {
                    pageCount: x.pageCount,
                    page: x.page,
                    pageSize: x.pageSize,
                    total: x.total,
                    list: x.list.map(rpe => {
                        let data = {
                            id: rpe.reviewPeriodEmployeeId,
                            name: rpe.byEmployeeName,
                            description: `${rpe.employeeIdentifier ? rpe.employeeIdentifier : rpe.email}, kitöltés dátuma: ${formatDate(rpe.closedOn, 'yyyy.MM.dd', 'en-US')}`
                        } as IdName;
                        return data;
                    }), 
                } as PagedListResult<IdName>;                
                return pList;
            })
        );
    }
    
}
