export * from './login/login.component';
export * from './goals/goals.component';
export * from './confirm-email/confirm-email.component';
export * from './assessments/assessments.component';
export * from './assessment/assessment.component';
export * from './reports/reports.component';
export * from './create-role-user/create-role-user.component';
export * from './employee/employee.component';
export * from './employee-add/employee-add.component';
export * from './settings/settings.component';
export * from './position/position.component';
export * from './password-reset/password-reset.component';
export * from './company-selector/company-selector.component';
// export * from './registration/registration.component';
export * from './simple-search-test/simple-search-test.component';
export * from './cancel-user-delete/cancel-user-delete.component';