import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { ReviewPeriodForEmployee } from 'src/app/models/review-period-for-employee.model';
import { ReviewPeriodService } from 'src/app/services';
import { State } from '../../models/state.model';
import { StateService } from '../../services/state.service';
import { Employee } from 'src/app/models/employee.model';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';

@Component({
    selector: 'assessments',
    templateUrl: './assessments.component.html',
    styleUrls: ['./assessments.component.scss'],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ]
})
export class AssessmentsComponent implements OnInit {
    public itemsOpen$: Observable<ReviewPeriodForEmployee[]>;
    public itemsClosed$: Observable<ReviewPeriodForEmployee[]>;
    public activeReviewsCount$: Observable<number>;

    public state: State;
  
    public get currentEmployee(): Employee {
        return this.state?.me.value?.employee;
    }

    constructor(stateService: StateService, private reviewPeriodService: ReviewPeriodService) 
    { 
        this.state = stateService.state;

        const reviewsData$ = this.reviewPeriodService.getEmployeeReviews().pipe(
            map(model => {
                model.list = model.list.map(item => {
                    item.isRpClosed = ReviewPeriodHelper.isRpClosed(item.reviewPeriod);

                    if (ReviewPeriodHelper.isSziluett(item.reviewPeriod.reviewType))
                    {
                        item.isRpeClosed = AssessmentHelper.isRpeFinished(item.reviewPeriodEmployee);
                    }

                    return item;
                });

                return model;
            }),
            shareReplay(1)
        );

        this.activeReviewsCount$ = reviewsData$.pipe(
            map(model => model.activeReviewsCount)
        );

        this.itemsOpen$ = reviewsData$.pipe(
            map(model => model.list.filter(x => 
                { 
                    if (ReviewPeriodHelper.isRpSziluett(x.reviewPeriod))
                        return x.hasNotClosedReviews;
                    else
                        return !ReviewPeriodHelper.isRpClosed(x.reviewPeriod) && !AssessmentHelper.isRpeFinished(x.reviewPeriodEmployee); 
                }
            ))
        );
      
        this.itemsClosed$ = reviewsData$.pipe(
            map(model => model.list.filter(x => 
                { 
                    if (ReviewPeriodHelper.isRpSziluett(x.reviewPeriod))
                        return x.hasClosedReviews;
                    else
                        return ReviewPeriodHelper.isRpClosed(x.reviewPeriod) || AssessmentHelper.isRpeFinished(x.reviewPeriodEmployee); 
                }
            ) )
        );
    }
    
    ngOnInit()
    { 
       
    }

}
