import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-indicator-group',
  templateUrl: './dashboard-indicator-group.component.html',
  styleUrl: './dashboard-indicator-group.component.scss'
})
export class DashboardIndicatorGroupComponent {
  // this is a group of dashboard indicators using mat-cards
  // each card has a little title and a value with large font to display

  showGauge = true;
  gaugeType = "semi";
  gaugeAppendText = "%";
  gaugeSize = 142;
  isGaugeMedium = false;
  isDialogOpened = false;

  gaugeValue = 75;
  gaugeLabel = "időszak - kitöltés";
}
