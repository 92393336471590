import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IChangeMyPassword, IChangePasswordForUser, IResetPassword } from 'src/app/models/change-password.model';
import { StateService } from 'src/app/services';
import { CustomValidators } from 'src/app/shared/helpers/custom-validators';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';
import { AuthService } from '../../services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    public mainForm: UntypedFormGroup;

    @Input()
    passwordResetMode = false;

    @Input()
    token = '';

    @Input()
    email = '';

    @Input()
    isReg = false;
    
    ownProfile = false;
    passwordResetFailed = false;

    minCharCount = 6;
            
    public get isPasswordNotEmpty(): boolean {
        return this.mainForm.controls['newPassword']?.value?.length > 0;
    }

    constructor(
        private authService: AuthService, 
        private employeeStore: EmployeeState,
        private stateService: StateService,        
        private router: Router,
        private fb: UntypedFormBuilder,
        private msgService: ErrorMessageService
        ) {      
    }

    ngOnInit() {
        this.employeeStore.employee.obs$.subscribe(employee => {
            if (employee)
            {
                this.ownProfile = employee.id === this.stateService.state.me.value.id;
                this.mainForm = this.createForm();
            }
        });

        if (this.passwordResetMode) {
            this.mainForm = this.createForm();

            this.mainForm.valueChanges
                .pipe(untilDestroyed(this))
                .subscribe(data => {
                    this.passwordResetFailed = false;
            });
        }
    }
    
    createForm(): UntypedFormGroup {
        let form = this.fb.group({
            username: [this.email],
            newPassword: [null, Validators.compose([
                // 1. Password Field is Required
                Validators.required,
                // 2. check whether the entered password has a number
                CustomValidators.patternValidator(/\d/, { hasNumber: true }),
                // 3. check whether the entered password has upper case letter
                CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
                // 4. check whether the entered password has a lower-case letter
                CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
                // 5. Has a minimum length of 6 characters
                Validators.minLength(this.minCharCount)])
            ],
            confirmPassword: [null, [Validators.required, Validators.minLength(this.minCharCount)] ]
        }, { validator: CustomValidators.matchValues('newPassword', 'confirmPassword') } );

        if ( !this.passwordResetMode && this.ownProfile) {
            form.addControl('oldPassword', new UntypedFormControl(null, [Validators.required]));
        }

        return form;
    }
    
    changePassword() {
        if (this.passwordResetMode)
        {
            let model = {
                isReg: this.isReg,
                password: this.mainForm.value.newPassword, 
                token: this.token, 
                username: this.email, 
                email: this.email } as IResetPassword;

            this.authService.resetPassword(model).subscribe(() => {
                this.router.navigate(['/']);
                this.msgService.showSnackBarMessage("passwordChangeSuccess");
            }, error => {
                this.passwordResetFailed = true;
            });
        }
        else
        {
            if (this.ownProfile) {
                let model = {...this.mainForm.value } as IChangeMyPassword;

                this.authService.changeMyPassword(model).subscribe(() => {      
                    this.msgService.showSnackBarMessage('saveSuccessful');              
                    this.authService.logOut();
                    this.router.navigate(['/']);
                    
                }, () => {
                    this.msgService.showSnackBarMessage('unsuccessfulAction');
                });
            }
            else
            {
                let model = {...this.mainForm.value, email: this.email } as IChangePasswordForUser;

                this.authService.changePasswordForUser(model).subscribe(() => {
                    this.mainForm.reset();
                    this.msgService.showSnackBarMessage('saveSuccessful');
                }, () => {
                    this.msgService.showSnackBarMessage('unsuccessfulAction');
                });
            }
                        
        }
    };

    cancel() {
        this.router.navigate(['/']);
    }
}
