import { ReviewPeriod } from "src/app/models/review-period.model";
import { ReviewTypeStrategyBase } from "../review-type-strategy-base";
import { DateHelper } from "src/app/shared/helpers";
import { ReviewPeriodHelper } from "src/app/helpers/review-period.helper";

export class PanoramaAuraGoalsStrategy extends ReviewTypeStrategyBase {

    constructor() {
        super();
            
    }

    isReviewAllowed(rp: ReviewPeriod, isSelf: boolean): boolean {
        return ReviewPeriodHelper.isRpOpen(rp) && DateHelper.isPlainTodayBetween(rp.panoramaAuraStartDate, rp.panoramaAuraEndDate);
    }
}