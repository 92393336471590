<div class="left-side" [ngClass]="{'header-ratio-2-1': headerRatio21 }">

    <div class="report-logo" *ngIf="reportOptions.logoLink">
        <img [src]="reportOptions.logoLink" [alt]="reportOptions.title" />
    </div>

    <div class="title-block">
        <div class="subtitle">
            {{ reportOptions.subtitle.toUpperCase() }}
        </div>
        <div class="title">        
            {{ reportOptions.title }}<sup *ngIf="reportOptions.titleIndexText">{{ reportOptions.titleIndexText }}</sup>
        </div>
    </div>
</div>

<div class="right-side" [ngClass]="{'line3': legend.length >= 3}">
    <div *ngIf="reportOptions.company" class="report-company">
        {{ reportOptions.company }}
    </div>
    <div *ngIf="showLegend && reportOptions?.subject" class="report-subject">
        {{ reportOptions.subject }}
    </div>
    <app-legend [data]="legend" [showIcon]="showLegendIcon" *ngIf="showLegend"></app-legend>
    
    <div class="review-interval" *ngIf="reportOptions.dateFrom && reportOptions.dateTo">{{ reportOptions.dateFrom | datePlain:"yyyy.MM.dd" }} - {{ reportOptions.dateTo | datePlain:"yyyy.MM.dd" }}</div>
</div>