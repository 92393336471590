import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { ErrorDetailsDialogComponent } from 'src/app/dialogs';
import { EmployeeGroupActions } from 'src/app/enums/employee-group-actions.enum';
import { Company } from 'src/app/models/company.model';
import { ErrorDetailsDialogResult } from 'src/app/models/dialog/error-details-dialog-result.model';
import { ErrorDialogData } from 'src/app/models/dialog/error-dialog.model';
import { Employee } from 'src/app/models/employee.model';
import { IResult, IResultModel, Result, ResultModel } from 'src/app/models/general/result.model';
import { EmployeeImportResult } from 'src/app/models/other/employee-import-result.model';
import { EmployeeMigration } from 'src/app/models/other/employee-migration.model';
import { GroupOperationOption } from 'src/app/models/other/group-operation-option.model';
import { GroupOperationType } from 'src/app/models/other/group-operation-type.model';
import { MigrationResult } from 'src/app/models/other/migration-result.model';
import { PagingRequest } from 'src/app/models/paging-request.model';
import { Search } from 'src/app/models/search.model';
import { State } from 'src/app/models/state.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { AuthService, CompanyService, EmployeeService, StateService } from 'src/app/services';
import { CardComponent } from 'src/app/shared/components';
import { TEXTS } from 'src/app/shared/helpers';
import { ActionStateModel } from 'src/app/shared/models/action-state.model';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { StateValue } from 'src/app/shared/models/state-value.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';

@UntilDestroy()
@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {

  private readonly itemsSubject = new BehaviorSubject<UserProfile[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();

  public readonly basket = new StateValue<ListItem<UserProfile>[]>([]);

  public tableColumns = ['name', 'company', 'sziluettState', 'lastSziluettFinishDate', 'isSziluettDiagramAllowed', 'action']; // 'isOwnPanoramaAuraReportAllowed'
  public viewMode: string[] = [];

  public searchControl: UntypedFormControl;
  public minCharCount = 3;

  public selectedAction: GroupOperationType;
  public selectedCompany: number;

  public filterExpired = false;

  public get isCompanyIndependentMode(): boolean {
    return this.viewMode.includes('companyIndependent');
  }

  public orderField: string = 'name';
  public isAscendingOrder = true;

  public get firstHeaderGroupColSpan(): number {
    return this.isCompanyIndependentMode ? 2 : 1;
  }

  public get sziluettHeaderGroupColSpan(): number {
    return this.isCompanyIndependentMode ? 2 : 3;
  }

  public actionList: GroupOperationType[] = [    
    { id: EmployeeGroupActions.None, name: '--válasszon--' },
    { id: EmployeeGroupActions.EmployeeMigration, name: 'Felhasználó migráció', description: 'Migráció során a kiválasztott dolgozók személyes adatai (kivéve a hierarchia adatok) és Sziluett felmérései átkerülnek a dolgozók aktuális cégéből a célként megjelölt céghez.' },
    { id: EmployeeGroupActions.EmployeeDelete, name: 'Felhasználók törlése', description: '' },
  ];

  public get actions(): GroupOperationType[] {
    let items: GroupOperationType[] = [];

    items.push(this.actionList.find(x => x.id === EmployeeGroupActions.None));
    items.push(this.actionList.find(x => x.id === EmployeeGroupActions.EmployeeMigration));
    items.push(this.actionList.find(x => x.id === EmployeeGroupActions.EmployeeDelete));
    
    return items;
  }

  public companyList: Company[] = [];

  // paging
  public total = 0;
  public pageSize = 20;
  public pageSizeOptions = [20, 30, 50, 100];
  public page = 0;

  public state: State;
  public listState: ActionStateModel = { editMode: true, currentItemIndex: null };

  public searchMessage = '';

  public get isAllowSziluettDiagramEdit(): boolean {    
    return this.state.isSuperAdmin || this.state.isAdmin || this.state.isAssistant;
  }

  public get isAllowPanoramaSettingsEdit(): boolean {    
    return this.state.isSuperAdmin || this.state.isAdmin || this.state.isAssistant;
  }

  public get isAllowUserMigration(): boolean {    
    return this.state.isSuperAdmin || this.state.isAdmin;
  }

  public get hasElevatedAccess(): boolean {
    return this.state?.isSuperAdmin || this.state?.isAdmin || this.state?.isAssistant;
  }

  public get isValidCharacterCount(): boolean {
    return this.searchControl.value.length === 0 || this.searchControl.value.length >= this.minCharCount;
  }

  public get isStartEnabled(): boolean {
    if (!this.selectedAction || this.basket.value.length === 0) return false;

    if (this.selectedAction.id > EmployeeGroupActions.None)   {
      if (this.selectedAction.id === EmployeeGroupActions.EmployeeMigration && this.selectedCompany > 0)
        return true;

      if (this.selectedAction.id === EmployeeGroupActions.EmployeeDelete)
        return true;
    }

    return false;
  }
    
  @ViewChild('card')
  card: CardComponent;

  @ViewChild('paginator')
  paginator: MatPaginator;

  public get searchValue(): string {
    return this.searchControl.value;
  }

  constructor(
    @Inject(TEXTS) public texts: {[key: string]: string},
    private employeeService: EmployeeService, 
    private appState: AppState,
    private authService: AuthService, 
    private msgService: ErrorMessageService, 
    private companyService: CompanyService,
    stateService: StateService,
    private dialog: MatDialog,
    private router: Router) {
      this.state = stateService.state;
     }

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl('');
    
    this.searchControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.firstPage();
        this.setValidationMessage();
      }),
      switchMap(searchString => searchString.length === 0 || searchString.length >= this.minCharCount ? this.employeeSearch(searchString): of([]))
    ).subscribe(itemArray => {
      this.itemsSubject.next(itemArray);
    });

    this.searchControl.setValue('');
  }

  private loadList() {
    this.employeeSearch(this.searchControl.value).subscribe(itemArray => {
      this.itemsSubject.next(itemArray);      
    });
  }

  public isEmployeeInCurrentCompany(element: UserProfile): boolean {
    return element.company === this.appState.company.value.name;
  }

  private employeeSearch(searchString: string): Observable<UserProfile[]> {
        
    return this.authService.getUsers(this.buildPageRequest(searchString)).pipe(
      tap(data => {
        this.total = data.total;
        this.searchMessage = this.getResultMessage(data.list.length);
      }),
      map(result => result.list)
    );    
  }

  public clearText() {
    this.searchControl.setValue('');
    //this.loadList();
  }

  public actionChanged() {
    if (this.selectedAction.id === 1)
    {
      const data = {} as Search;

      this.companyService.getList(data)
      .pipe( map(result => result.list.filter(x => x.id >= 100)))
      .subscribe(result => {
        this.companyList = result;
      });
    }
  }

  public setValidationMessage() {
    if (!this.isValidCharacterCount)
      this.searchMessage = `Minimum ${ this.minCharCount } karakter szükséges`;
  }

  public getResultMessage(count: number): string {
    if (count === 0)
      return 'Nincs találat';
    else
      return '';
}

  private buildPageRequest(searchString: string): Search {
    let data = {
      search: searchString,
      viewMode: this.isCompanyIndependentMode ? 'companyIndependent' : '',
      pagingRequestInfo: {
        pageSize: this.pageSize,
        page: this.page + 1
      } as PagingRequest,
      orderField: this.orderField,
      isAscending: this.isAscendingOrder,
      isExpired: this.filterExpired
    } as Search;

    return data;
  }

  public pagingSettingsChanged(data: PageEvent)
  {
    this.page = data.pageIndex;
    this.pageSize = data.pageSize;
    this.loadList();
  }

  addItem(option: string) {

  }

  addEmployee() {
    this.router.navigate(['/employee-add']);
  }

  startAction() {
    if (this.selectedAction == null) return;

    if (this.selectedAction.id === EmployeeGroupActions.EmployeeMigration)
    {
      this.employeeMigrationAction();
    }
    else if (this.selectedAction.id === EmployeeGroupActions.EmployeeDelete)
    {
      this.employeeDeleteAction();
    }
  }

  public employeeMigrationAction() {
    const data = {      
      targetCompanyId: this.selectedCompany,
      employees: this.basket.value.map(x => x.itemSource.employee.id)
    } as EmployeeMigration;

    this.employeeService.migrateEmployees(data).subscribe({next: (result: EmployeeImportResult) => {
      this.basket.value = [];
      this.loadList();
      this.handleMigrationResult(result);
    }, error: (err: any) => {
      
      if (err.error instanceof Object && "errorData" in err.error)
      {
        this.showErrorDialog(err.error);
      }
      else
        this.msgService.showSnackBarMessage('unsuccessfulAction');
    }});
  }

  public employeeDeleteAction() {
    const data = {              
      employees: this.basket.value.map(x => x.itemSource.employee.id)
    } as GroupOperationOption;

    this.authService.deleteEmployeeInBatch(data).subscribe({next: () => {
      this.basket.value = [];
      this.loadList();
      this.msgService.showSnackBarMessage('successfulAction');
    }, error: (err: IResult<number[], Result<number[]>>) => {
      this.msgService.handleErrorWithData<number[], Result<number[]>>(err);
    }});
  }

  public handleMigrationResult(result: EmployeeImportResult) {
      const errorKeys = Object.keys(result.employeeErrorList);
      const warningKeys = Object.keys(result.employeeWarningList);

      if (result.errorData == null && errorKeys?.length == 0 && warningKeys?.length == 0)
      {
        this.msgService.showSnackBarMessage('successfulAction');
          return;
      }
      else
      {
        this.showErrorDialog(result);
      }
  }

  showErrorDialog(result: EmployeeImportResult)
  {
    const dialogData = {
        title: 'Felhasználó migráció eredmények',
        errorData: result?.errorData,
        employeeErrorList: result?.employeeErrorList,
        employeeWarningList: result?.employeeWarningList,
        defaultErrorKey: 'migrationError'
    } as ErrorDialogData;

    const dialogRef = this.dialog.open(ErrorDetailsDialogComponent, {
        maxWidth: "800px",
        width: "800px",
        data: dialogData,
      });

    dialogRef.afterClosed().subscribe( (result: ErrorDetailsDialogResult) => {
        if (!result)
            return;
    });
  }

  selectEmployee(user: UserProfile) {
    if (this.basket.value && this.basket.value.some(x => x.itemSource.employee.id === user.employee.id))
      return true;

    const newItem = new ListItem<UserProfile>(user);
    this.basket.value = [...this.basket.value, newItem];
  }

  unselectEmployee(employeeId: number) {
    const items = this.basket.value.filter(item => item.itemSource.employee.id !== employeeId)
    this.basket.value = [...items];
  } 

  onMasterRowClick(element) {
    if (!this.isEmployeeInCurrentCompany(element))
      return;

    this.router.navigate(['/employee', element.id]);
  }

  changeAllowSziluettDiagram(event: MatCheckboxChange, employee: Employee) {
    employee.isSziluettDiagramAllowed = event.checked;
    this.saveEmployeeSettings(employee);
  }

  changeOwnPanoramaAuraReportAllowed(event: MatCheckboxChange, employee: Employee) {
    employee.isOwnPanoramaAuraReportAllowed = event.checked;
    this.saveEmployeeSettings(employee);
  }

  saveEmployeeSettings(employee: Employee) {
    const data = {
      employeeId: employee.id,
      isSziluettDiagramAllowed: employee.isSziluettDiagramAllowed,
      isOwnPanoramaAuraReportAllowed: employee.isOwnPanoramaAuraReportAllowed
    };

    this.employeeService.updateSettings(data).subscribe({
      next: () => {
        this.msgService.showSnackBarMessage('saveSuccessful');
      }, error: () => {
        this.msgService.showSnackBarMessage('saveFailed');
      }
    });
  }

  filterExpiredRp(event: MatCheckboxChange) {
    this.page = 0;
    this.paginator.firstPage();
    this.filterExpired = event.checked;
    this.loadList();
  }

  viewCompanyIndependent() {
    this.page = 0;
    this.paginator.firstPage();
    if (this.viewMode.includes('companyIndependent'))
      this.viewMode = this.viewMode.filter(x => x != 'companyIndependent');
    else 
      this.viewMode.push('companyIndependent');

    this.loadList();
  }

  announceSortChange(data: {active: string, direction: string}) {
    this.page = 0;
    this.orderField = data.active;
    this.isAscendingOrder = !data.direction || data.direction === 'asc';
    this.paginator.firstPage();

    this.loadList();
  }

}
