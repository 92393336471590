﻿    <h1 mat-dialog-title>Feltöltés</h1>
    <div mat-dialog-content>
        <span>Értékelés: {{reviewPeriodName}}</span>

        <div *ngIf="appState.company.value">
            <mat-form-field appearance="outline">
                <mat-label>Típus</mat-label>
                <mat-select [(value)]="docType" (selectionChange)="docTypeChanged($event.value)">
                    <mat-option *ngFor="let endpoint of endpointList" [value]="endpoint.id">{{ endpoint.name }}</mat-option>
                </mat-select>
            </mat-form-field>
    
            <div class="options">
                <mat-checkbox *ngIf="docType && docType != 1 && docType != 4" class="multiline" [(ngModel)]="assignToReviewPeriod">A feltöltött adatok hozzárendelése a kiválasztott értékeléshez</mat-checkbox>
    
                <ng-container *ngIf="docType == 1">
                    <div class="margin-top-sm">Minta <a href="/assets/quantille-skalak-import.xlsx" title="minta import file"> skálák import file</a> letöltése.</div>
                </ng-container>
                <ng-container *ngIf="docType == 2">
                    <!-- <mat-checkbox class="multiline" [(ngModel)]="updateInReviewPeriod">Frissítés a kiválasztott értékelésben (!)</mat-checkbox> -->
                    <div class="margin-top-sm">Minta <a href="/assets/quantille-kompetenciak-import.xlsx" title="minta import file"> kérdésadatokat tartalmazó import file</a> letöltése.</div>
                </ng-container>
                <ng-container *ngIf="docType == 3">
                    <div class="margin-top-sm">Minta <a href="/assets/quantille-alkalmazott-import.xlsx" title="minta import file">alkalmazott import file</a> letöltése.</div>
                </ng-container>
                <ng-container *ngIf="docType == 4">
                    <div class="margin-top-sm">Minta <a href="/assets/quantille-kpi-import.xlsx" title="minta import file">KPI import file</a> letöltése.</div>
                </ng-container>            
            </div>
        
            <angular-file-uploader #fileUpload class="uploader"
                [config]="afuConfig"
                (beforeUpload)="beforeUpload($event)"
                (ApiResponse)="handleResult($event)">
            </angular-file-uploader>
            
        </div>
    
  
    </div>
    <div mat-dialog-actions class="flex-h flex-end">
        <button mat-flat-button color="accent" (click)="resetUpload()">Reset</button>
        <button mat-flat-button color="primary" [mat-dialog-close]>Bezárás</button>
    </div>
