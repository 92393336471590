import { DatePipe, formatDate } from "@angular/common";
import { Temporal } from "@js-temporal/polyfill";

export abstract class DateHelper {
    public static setTimeZoneDiff(value: any): Date {
        let dateValue = DateHelper.toDate(value);

        dateValue = new Date(Date.UTC(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds()));
        return dateValue;
    }

    public static isDateExpired(date: Temporal.PlainDate): boolean {
        if (!date) return false;
        
        //get the current date without time
        const today = Temporal.Now.plainDateISO();

        //get the date to compare without time
        const dateToCompare = Temporal.PlainDate.from(date);
        
        //if the date to compare is less than today, it is expired
        return Temporal.PlainDate.compare(dateToCompare, today) < 0;

        // if (!date) return false;
        
        // //get the current date without time
        // const today = new Date();
        // today.setHours(0,0,0,0);

        // //get the date to compare without time
        // const dateToCompare = new Date(date);
        // dateToCompare.setHours(0,0,0,0);
        
        // //if the date to compare is less than today, it is expired
        // return dateToCompare < today;
    }

    public static isDateBetween(dateToCheck: Date, startDate: Date, endDate: Date): boolean
    {
        let date = new Date(dateToCheck);
        let start = new Date(startDate);
        let end = new Date(endDate);

        if (!date || !start || !end) 
            return false;
        return start <= date && date <= end;
    }

    public static isPlainDateBetween(dateToCheck: Temporal.PlainDate, startDate: Temporal.PlainDate, endDate: Temporal.PlainDate): boolean
    {
        if (!dateToCheck || !startDate || !endDate) 
            return false;

        let date = Temporal.PlainDate.from(dateToCheck);
        let start = Temporal.PlainDate.from(startDate);
        let end = Temporal.PlainDate.from(endDate);

        if (!date || !start || !end) 
            return false;
        return Temporal.PlainDate.compare(date, start) >= 0 && Temporal.PlainDate.compare(date, end) <= 0;
    }

    public static isPlainTodayBetween(startDate: Temporal.PlainDate, endDate: Temporal.PlainDate): boolean {
        let start = Temporal.PlainDate.from(startDate);
        let end = Temporal.PlainDate.from(endDate);

        return DateHelper.isPlainDateBetween(Temporal.Now.plainDateISO(), start, end);
    }
 
    public static getDateWithoutTimezone (value: string | Date): Date {
        let dateValue = DateHelper.toDate(value);
        const offset = dateValue.getTimezoneOffset() * 60000; //offset in milliseconds
        const withoutTimezone = new Date(dateValue.valueOf() - offset)
          .toISOString()
          .slice(0, -1);
        return new Date(withoutTimezone);
    };

    public static toDate(value: string | Date): Date {
        if (!value) return null;

        if (typeof value ==='string')
        {
            return new Date(value);
        }

        return value;
    }

    public static toPlainDate(value?: string | Date | Temporal.PlainDate): Temporal.PlainDate {
        if (!value) 
            return null;
        else if (typeof value === 'string')
            return Temporal.PlainDate.from(value);
        else if (value instanceof Date)
            return Temporal.PlainDate.from(formatDate(value, 'yyyy-MM-dd', 'en-US'));
        else
            return Temporal.PlainDate.from(value);
    }

    public static formatPlainDateShort(date: Temporal.PlainDate): string {
        return date.toLocaleString('hu-HU', { year: 'numeric', month: '2-digit', day: '2-digit' });
    }
}