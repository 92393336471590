import { Component, ContentChild, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ReviewPeriodStatusEnum } from 'src/app/enums/review-period-status.enum';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { ReviewPeriodChangeStatus } from 'src/app/models/review-period-change-status.model';
import { ReviewPeriod, ReviewPeriodModel } from 'src/app/models/review-period.model';
import { AuthService, ReviewPeriodService, StatisticsService, SystemService } from 'src/app/services';
import { CardComponent } from 'src/app/shared/components';
import { StateValue } from 'src/app/shared/models/state-value.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';
import { EmployeeState } from 'src/app/store/employee.state';
import { SettingsState } from 'src/app/store/settings.state';
import { ReviewPeriodEditComponent } from './review-period-edit/review-period-edit.component';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { HttpResponse } from '@angular/common/http';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { EmailAdminDialogComponent, ErrorDetailsDialogComponent, UploadDialogComponent } from 'src/app/dialogs';
import { ErrorDetailsDialogResult } from 'src/app/models/dialog/error-details-dialog-result.model';
import { EmployeeImportResult } from 'src/app/models/other/employee-import-result.model';
import { ErrorDialogData } from 'src/app/models/dialog/error-dialog.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-period',
  templateUrl: './review-period.component.html',
  styleUrls: ['./review-period.component.scss']
})
export class ReviewPeriodComponent implements OnInit {

  @Input() public configMode = false; // period konfigurálható módon jelenik meg (settings page), vagy csak kiválasztani enged (employee page)
     
  public addMode = false;
  private readonly itemsSubject = new BehaviorSubject<ReviewPeriod[]>([]);
  public readonly list$ = this.itemsSubject.asObservable();
  
  public selectedReviewPeriod: StateValue<ReviewPeriod>;
  public getDisplayText = ReviewPeriodHelper.getDisplayText;
  public isSziluett = ReviewPeriodHelper.isSziluett;
  
  @Input() public componentTitle = 'Értékelések';

  @ViewChild('card')
  card: CardComponent;

  @ViewChild(ReviewPeriodEditComponent)
  editComponent: ReviewPeriodEditComponent;

  //public status: ReviewPeriodStatusEnum;
  public statusList = ReviewPeriodHelper.getReviewPeriodStatusList();

  public reviewPeriod: ReviewPeriod;
  public approved = false;

  public origStatus: ReviewPeriodStatusEnum;

  public get isTestMode(): boolean {
    return this.appState?.company.value?.config?.testMode;
  } 

  public get showRpButtonGroup(): boolean {
    const rp = this.getCurrent();
    return this.configMode && rp != null;
  }

  public get showStat(): boolean {
    const rp = this.getCurrent();
    return this.configMode && rp && (rp.reviewType === ReviewTypeEnum.TER || rp.reviewType === ReviewTypeEnum.PersonalityTest);
  }

  constructor (
    private employeeState: EmployeeState, 
    private settingsState: SettingsState, 
    public appState: AppState, 
    private fb: UntypedFormBuilder,     
    private reviewPeriodService: ReviewPeriodService,
    private statisticsService: StatisticsService,
    private systemService: SystemService,
    private authService: AuthService,
    private msgService: ErrorMessageService,
    private router: Router,
    private dialog: MatDialog
  ) { }
  
  ngOnInit(): void {    
    this.selectedReviewPeriod = !this.configMode ? this.employeeState.reviewPeriod : this.settingsState.reviewPeriod;
    
    combineLatest([this.employeeState.employee.obs$])
    .subscribe(emp => {
      this.loadList();      
    });

  }

  private getDefaultItem(list: ReviewPeriod[], id?: number): ReviewPeriod {
    let item: ReviewPeriod = null;
    
    item = list.filter(x => x.id == id)[0];
        
    return item ? item : list[0];
  }

  private loadList(reviewPeriodId?: number) {
    const emptyRow = {
      id: null,
      name: '---',
    } as ReviewPeriod;

    if (!this.configMode) {
      // személyi adatlap
      this.employeeState.loadReviewPeriodList().pipe(
        map(result => {
          const list = [emptyRow, ...result];
          return list;
        }),
        tap(list => {
          if (list.length > 0)
            this.setCurrent(this.getDefaultItem(list, reviewPeriodId));
        })
      ).subscribe(data => {
        this.itemsSubject.next(data);
      });

    }
    else
    {
      // settings oldal
      this.settingsState.loadReviewPeriodList().pipe(
        tap(list => {          
        })
      ).subscribe(data => {
        this.itemsSubject.next(data);

        if (data.length > 0)
          {

            let rp: ReviewPeriod = null;

             if (this.settingsState.reviewPeriod.value != null)
             {
              rp = data.find(x => x.id == this.settingsState.reviewPeriod.value.id);
              if (rp != null)
                this.setCurrent(rp);
             }
             
             if (rp == null)
              this.setCurrent(this.getDefaultItem(data, reviewPeriodId));
          }
      });

    }    
  }

  public getCurrent(): ReviewPeriod {
    return this.selectedReviewPeriod?.value;
  }

  public setCurrent(item: ReviewPeriod) {
    this.selectedReviewPeriod.value = item;
    this.reviewPeriod = item;
    this.origStatus = this.selectedReviewPeriod?.value.status;
  }

  public updateStateOfCurrent(status: ReviewPeriodStatusEnum) {
    this.selectedReviewPeriod.value = { ...this.selectedReviewPeriod.value, status: status};
    this.reviewPeriod.status = status;
    //this.status = status;
  }
  
  public add() {
    this.card.subtitle = 'Új értékelés';
    this.addMode = true;
    this.card.onToggleEdit();
    this.editComponent.loadData(new ReviewPeriodModel());
  }
          
  public closeEdit()
  {
    this.card.subtitle = '';
    this.addMode = false;
    this.card.onToggleEdit();
  }

  public dataSaved(id: number) {
    this.loadList(id);
  }

  public edit() {
    this.addMode = false;
    this.card.subtitle = this.getDisplayText(this.getCurrent());
    this.card.onToggleEdit();
    this.editComponent.loadData(this.getCurrent());
  }

  public changePeriod($event: any) {
    this.setCurrent(this.reviewPeriod);
  }

  public changeStatus($event: any) {
    const model = {
      reviewPeriodId: this.getCurrent().id,
      status: this.reviewPeriod.status,
      approved: this.approved
    } as ReviewPeriodChangeStatus;

    this.reviewPeriodService.changeStatus(model).subscribe({ next: (data: EmployeeImportResult) => {
      
      this.handleResult(model, data);
      
    }, error: (err: any) => {
      this.reviewPeriod.status = this.origStatus;
      //this.msgService.handleError<ResultModel>(err);

      this.showErrorDialog(err.error);

    }});
      
  }  

  handleResult(model: ReviewPeriodChangeStatus, result: EmployeeImportResult) {
    const errorKeys = Object.keys(result.employeeErrorList);
    const warningKeys = Object.keys(result.employeeWarningList);

    if (result.errorData == null && errorKeys?.length == 0 && warningKeys?.length == 0)
    {
        this.updateStateOfCurrent(model.status);
        const statusText = ReviewPeriodHelper.getStatusName(model.status);
        this.approved = false;
        this.msgService.showSnackBarInfo(`Az értékelési időszak új állapota: ${statusText}`);
        return;
    }
    else
    {
      this.showErrorDialog(result);
    }
  }

  showErrorDialog(result: EmployeeImportResult)
  {
    
    const dialogData = {
      title: 'Időszak állapot változtatás',
      errorData: result?.errorData,
      employeeErrorList: result?.employeeErrorList,
      employeeWarningList: result?.employeeWarningList,
      defaultErrorKey: 'validationChangeStatusError'
    } as ErrorDialogData;

    const dialogRef = this.dialog.open(ErrorDetailsDialogComponent, {
      maxWidth: "800px",
      width: "800px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe( (result: ErrorDetailsDialogResult) => {
        if (!result)
            return;
        
        if (result.dialogCloseResult == 'continue')
        {
            this.approved = true;
            this.changeStatus(null);
        }
        else if (result.dialogCloseResult == 'close')
        {
          this.approved = false;
          this.reviewPeriod.status = this.origStatus;
        }
    });
  }

  downloadExport(reviewPeriodId: number) {
    this.statisticsService.getExport(reviewPeriodId).subscribe({next: (response: HttpResponse<Blob>) => {
        this.statisticsService.saveStatistics(response);
    }, error: (err: any) => {
        this.msgService.showSnackBarMessage('exportError');
    }})
  };
    
  showEmailAdmin(reviewPeriodId: number) {
    this.dialog.open(EmailAdminDialogComponent, { minWidth: "700px" });
  }

  showUploadDialog(reviewPeriodId: number) {
    this.dialog.open(UploadDialogComponent, { minWidth: "700px" });
  }

  testDatabaseRestore() {
    this.systemService.restoreTestDatabase().subscribe({ next: () => {
      
      this.msgService.showSnackBarMessage("restoreDatabaseSuccess");
      this.authService.logOut();
      this.router.navigate(['/login']);

    }, error: (err: IResultModel<ResultModel>) => {
      this.msgService.handleError(err);
    } });
  }
}
