import { ColorHelper } from "src/app/helpers/color.helper";
import { ChartDataColor } from "src/app/models/general/chart-data-color.model";
import { EmployeeColor } from "src/app/models/report/employee-color.model";

export class EmployeeLegendManager {
    private list: EmployeeColor[] = [];

    public colors: string[] = ['#433696', '#17a5e3', '#F09443', '#2B9443', '#D94646', '#ADADAD'];

    constructor() {
    }

    public get getList(): EmployeeColor[] {
        return this.list;
    }

    public get getFirstItem(): EmployeeColor {
        return this.list[0];
    }

    public add(rpeId: number): void {
        let item = { rpeId: rpeId, color: this.getNextColor() };
        this.list.push(item);
    }

    public remove(rpeId: number): void {
        this.list = this.list.filter(x => x.rpeId !== rpeId);
    }

    public getItem(rpeId: number): EmployeeColor {
        return this.list.find(x => x.rpeId === rpeId);
    }

      //gets the fist not used color from
    public getNextColor(): ChartDataColor {
        for (let i = 0; i < this.colors.length; i++) {
            let item = this.list.find(x => x.color.baseColor === this.colors[i]);
            if (item == null)
                return ColorHelper.getChartLegendColor(this.colors, i);
        }

        return null;
    }

    public getRpeIdList(): number[] {
        return this.list.map(x => x.rpeId);
    }
}