<mat-card class="card-group">
    <mat-card-header>
        <mat-card-title>KPI modul <app-toggle-button [block]="kpiModuleTooltip"></app-toggle-button></mat-card-title>
        <mat-card-subtitle>
            <dynamic-text #kpiModuleTooltip class="no-bottom-margin" [opened]="true"><em>Az alábbi KPI-ok  közül a nem szerkeszthetők vagy automatikusan értékelődnek ki vagy az értékelt személy másik vezetője adja meg a teljesített adatokat.</em></dynamic-text>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
                
            <mat-card *ngFor="let indicator of list" class="question card-light" [ngClass]="{ 'alert': indicator.error, 'alert-danger': indicator.error}">
                <mat-card-header>
                    <mat-card-title>{{ indicator.kpiType.name }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-md-1">Tervezett</div>
                        <div class="col-md-11">{{ indicator.planned }} {{ indicator.kpiType.unit }}</div>
                    </div>
                    <div class="row" *ngIf="!disableKpiEdit(indicator)">
                        <div class="col-md-1">Teljesített</div>
                        <div class="col-md-11">
                            <mat-form-field appearance="outline">
                                <input [disabled]="indicator.noAnswer || disabled" matInput type="text" placeholder="teljesített" [name]="'unit' + indicator.id" [(ngModel)]="indicator.achieved" (change)="changeKpiText(indicator)">
                            </mat-form-field>
                            <span>
                                {{ ' ' + indicator.kpiType.unit }}
                            </span>
                            <mat-checkbox class="notChosing" 
                                [disabled]="disabled"
                                [name]="'notChosing' + indicator.id"
                                [(ngModel)]="indicator.noAnswer"
                                *ngIf="config.c.assessment.isNotChosingEnabled"
                                (change)="onSetKpi(indicator)"><em>nem válaszolok</em></mat-checkbox>
                        </div>                                            
                    </div>
                    
                </mat-card-content>
            </mat-card>

    </mat-card-content>
</mat-card>
