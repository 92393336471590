<app-card #card 
    [formGroup]="kpiForm" 
    [actionState]="actionState"
    [customTitle]="true"
    [showEdit]="false"
    [showStatus]="true"
    [lightCard]="false"
    [useExternalButtons]="true"
    [noContentBottomMargin]="true"
    (delete)="deleteKPI()"
    >
    <div slot="title">
        <span class="line-index">{{index}}. </span><strong>KPI</strong>
    </div>
    <div slot="edit">
        <div class="fields edit-area flex-h field-w-100">
            <div class="col-gauge">
                <div class="gauge-chart" (window:resize)="resizeGauge()">
                    <ngx-gauge [type]="gaugeType" *ngIf="showGauge"
                                [value]="gaugeValue" 
                                [label]="gaugeLabel"
                                [size]="gaugeSize"   
                                foregroundColor="#17A5E3"
                                [ngClass]="{'gauge-m': gaugeSize >= 160}"
                                [append]="gaugeAppendText">
                    </ngx-gauge>                
                </div>
            </div>
            <div class="edit-fields flex-h">
                <div class="field-name">
                    <app-search title="Kitűzött projektcél vagy KPI*" 
                        class="label-color-secondary"
                        #search
                        formControlName="kpiType"
                        controlType="textarea"
                        appearance="outline"
                        [showSearchIcon]="false"
                        [matTooltip]="kpi?.kpiType?.name"
                        (change)="onSearchChange($event)"
                        (blur)="onBlur($event)"
                        [rows]="2"
        
                        [disabled]="isSelf"
                        [inlineButtons]="false"
                        [isAllowSearch]="true"
                        [showAddButton]="false"
                        [isCustomAdd]="false"
                        [multilineDropdown]="true"
                        [config]="kpiSearchConfig"></app-search>
                </div>      
                <div class="field-data">
                    <mat-form-field appearance="outline" class="full-width field-lg label-color-secondary">
                        <mat-label>Kitűzött eredmény</mat-label>
                        <input matInput formControlName="planned" decimalMarker="," mask="separator.2" [allowNegativeNumbers]="true" [disableControl]="isSelf" required [ngStyle]="{'font-size': getInputSize('planned') }" (blur)="onBlur($event)">
                    </mat-form-field>
                </div>
                <div class="field-unit">
                    <mat-form-field appearance="outline" class="full-width field-lg label-color-secondary">
                        <mat-label>Egység</mat-label>
                        <input matInput formControlName="unit" required [disableControl]="isSelf" [ngStyle]="{'font-size': getInputSize('unit')}" (input)="changeUnit($event)" (blur)="onBlur($event)">
                    </mat-form-field>
                </div>
                <div class="field-data">
                    <mat-form-field appearance="outline" class="full-width field-lg field-inverted">
                        <mat-label>Elért eredmény</mat-label>
                        <input matInput formControlName="achieved" decimalMarker="," mask="separator.2" [allowNegativeNumbers]="true" [disableControl]="isSelf" [ngStyle]="{'font-size': getInputSize('achieved')}" (blur)="onBlur($event)">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- floating button -->
        <button mat-icon-button color="primary" class="kpi-delete hidden-print icon-button" [disabled]="isSelf" (click)="deleteKPI()" title="KPI törlése">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</app-card>
