import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { DomSanitizer } from '@angular/platform-browser';
import { Config } from 'src/app/models/config.model';
import { AssessmentStatusEnum } from 'src/app/enums/assessment-status.enum';
import { QuestionModeEnum } from 'src/app/enums/question-mode.enum';
import { ReviewPeriodStatusEnum } from 'src/app/enums/review-period-status.enum';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { QuestionOptions } from 'src/app/models/question-options.model';
import { Question } from 'src/app/models/question.model';
import { Rating } from 'src/app/models/rating.model';
import { AssessmentService } from 'src/app/services';
import { StateService } from 'src/app/services/state.service';
import { ViewHelper } from 'src/app/shared/helpers/view.helper';
import { AppState } from 'src/app/store/app.state';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { ErrorMessageService } from 'src/app/shared/services';
import { IResult, Result } from 'src/app/models/general/result.model';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input()
  public textblock: Question;

  @Input()
  public parentTextblock: Question;

  @Input()
  public questionOptions: QuestionOptions;
    
  @Input()
  public level = 1;

  @Input()
  public parentIndex: string;

  @Input()
  public index = 5;
  
  @Input()
  public editable = false;
      
  public config: Config;
  public noAnswer = 0;
  public ratings: Rating[];  
  public isComplete = false;
  public currentQuestionIndex: number;

  public titleEditMode = false;

  public reviewTypes = ReviewTypeEnum;
  public saveInProgress = false;

  public currentIndexText: string;
 
    
  @ViewChild('questionElement', { read: ElementRef })
  questionElement: ElementRef;

  @ViewChildren('complexChildren')
  complexChildren: QueryList<QuestionComponent>;

  @ViewChildren('groupChildren')
  groupChildren: QueryList<QuestionComponent>;

  // @ViewChild('groupDyn')
  // groupDyn: DynamicTextComponent;
      
  // QuestionMode
  public get isGroup(): boolean {
    return this.textblock.questionMode === QuestionModeEnum.Group;
  }

  public get isSingleAnswer(): boolean {
    return this.textblock.questionMode === QuestionModeEnum.SingleAnswer;
  }

  public get isComplexQuestion(): boolean {
    return this.textblock.questionMode === QuestionModeEnum.ComplexQuestion;
  }

  public get isInnerQuestion(): boolean {
    if (!this.parentTextblock) return false;
    return this.textblock.questionMode === QuestionModeEnum.SingleAnswer && this.parentTextblock.questionMode === QuestionModeEnum.ComplexQuestion;
  }

  // public get isShowGroup(): boolean {
  //   if (this.isGroup)
  //   {
  //     if ((this.textblock.reviewType === ReviewTypeEnum.MilioStressMap || this.textblock.reviewType === ReviewTypeEnum.MilioTeamDysfunction) && this.parentTextblock == null)
  //     {
  //       return false;
  //     }

  //     return true;
  //   }

  //   return false;
  // }

  // ReviewType

  public get isCompetencyAndActivityReview(): boolean {
    return this.textblock.reviewType === ReviewTypeEnum.TER;
  }

  public get isPersonalityTest(): boolean {
    return this.textblock.reviewType === ReviewTypeEnum.PersonalityTest;
  }

  //Todo: kivezetni configba a radio gomb megjelenítési stílust
  public get isPersBlock3And4(): boolean {
    return this.isPersonalityTest && this.textblock.questionModuleId && (this.textblock.questionModuleId === 83 || this.textblock.questionModuleId === 84);
  }

  public get isPeriodStatusInactive(): boolean {
    return this.questionOptions.reviewPeriodEmployee.reviewPeriod.status != ReviewPeriodStatusEnum.Open;
  }

  public get isAssessmentHeadStatusClosed(): boolean {
    return this.questionOptions.reviewPeriodEmployee.status === AssessmentStatusEnum.Finished;
  }

  public get isExpired(): boolean {
    return this.questionOptions.reviewPeriodEmployee.endDate && DateHelper.isDateExpired(this.questionOptions.reviewPeriodEmployee.endDate);
  }

  //---
  public get isCurrent(): boolean {
    if (this.isPeriodStatusInactive || this.isAssessmentHeadStatusClosed) return false;

    if (this.textblock.questionMode === QuestionModeEnum.ComplexQuestion)
      return this.textblock.childQuestions.some(x => x.questionIndex === this.currentQuestionIndex)
    else
      return this.parentTextblock != null && this.parentTextblock.questionMode != QuestionModeEnum.ComplexQuestion && this.currentQuestionIndex === this.textblock.questionIndex;
  }

  // a kérdés aktív e vagy sem, figyelembe kell venni: reviewPeriodStatus, reviewPeriodType, questionMode questionIndex
  public get isInactive(): boolean {    
    //ha az időszak zárt => minden kérdés disabled
    if (this.isPeriodStatusInactive) return true;
    
    //ha személyiségteszt és zárt az adott értékelés példány => minden kérdés disabled
    if (this.isPersonalityTest && this.isAssessmentHeadStatusClosed) return true;
    
    if (this.currentQuestionIndex === -1) return false;

    if (this.textblock.questionMode === QuestionModeEnum.ComplexQuestion) {
      return this.textblock.childQuestions[0].questionIndex > this.currentQuestionIndex;
    }
    else {
      return this.textblock.questionIndex > this.currentQuestionIndex;
    }
     //|| (this.parentTextblock.questionMode === QuestionModeEnum.ComplexQuestion && this.parentTextblock.questionIndex > this.currentQuestionIndex);
  }
   
  constructor(
    private stateService: StateService, 
    private appState: AppState, 
    private assessmentService: AssessmentService, 
    private sanitizer: DomSanitizer,
    private msgService: ErrorMessageService
    ) {
      this.config = stateService.state.config;
  }

  ngOnInit(): void {
    this.ratings = this.getRatings();

    this.currentIndexText = this.getQuestionIndex();
        
    this.appState.assessmentCurrentQuestionIndex.obs$.subscribe(index => {
      this.currentQuestionIndex = index;

      // scroll on rating change
      // if (this.currentQuestionIndex === this.textblock.questionIndex && this.questionElement) {    
      //     this.scrollHere();
      // }     
    });

    this.setupQuestion();

    this.setupQuestionIndex();
  }

  public scrollHere() {
    if (this.questionElement) {
      ViewHelper.scroll(this.questionElement.nativeElement);
    }
  }

  // public get currentLevel(): number {
  //   const ret = this.isGroup && !this.isShowGroup ? 0 : this.level;
  //   return ret;
  // }

  setupQuestion() {

    // TODO: put in question
    if (this.textblock.reviewType === ReviewTypeEnum.PersonalityTest) {

      if (this.textblock.questionModuleId && (this.textblock.questionModuleId === 81)) 
      {
        this.textblock.isMinMaxValueTitle = true;        
      }
    
      if (this.textblock.id === 81)
        this.textblock.is2col = true;
    }
    else if (this.textblock.reviewType === ReviewTypeEnum.MilioStressMap) {

      if (this.level == 2) // a sorok a radiogombokkal
      {
        this.textblock.isMinMaxValueTitle = true;        
      }
    }

    // else if (this.textblock.reviewType === ReviewTypeEnum.TER)
    // {      
    //     this.textblock.isMinMaxValueTitle = true;
    // }
  }

  setupQuestionIndex() {
    this.isComplete = AssessmentHelper.isQuestionComplete(this.textblock);
  }

  getQuestionStateCss(item: Question) {
    if (item.error) return "alert-danger";
    else return "alert-default";
  }

  getQuestionCategoryName(categoryId: number): string {
    // if (this.textblock.reviewType === ReviewTypeEnum.MilioStressMap && (!this.parentTextblock || this.parentTextblock.questionMode != QuestionModeEnum.ComplexQuestion))
    // {
    //   const name = this.questionOptions?.questionCategories?.find(x => x.id === categoryId)?.name ?? '';
    //   return `[${name}] `;    
    // }

    return '';
  }

  onRatingChange(event: MatRadioChange, textblock: Question) {
    textblock.error = false;   
    this.saveInProgress = true;

    this.assessmentService.saveSingleAssessment(textblock.assessmentData).subscribe({ next: (result) => {      
      if (!textblock.assessmentData.assessmentId) {
        textblock.assessmentData.assessmentId = result.data;
      }

      this.saveStatusToQuestionState();
      this.saveInProgress = false;
    }, error:(err: IResult<number, Result<number>>) => {
      this.saveInProgress = false;
      this.textblock.assessmentData.answerId = null;
      this.msgService.handleErrorWithData<number, Result<number>>(err);
    }});
    
    //this.messages = [];
  };
  
  saveStatusToQuestionState() {
    this.isComplete = AssessmentHelper.isQuestionComplete(this.textblock);
    this.appState.questionAssessmentStatusList.value[this.textblock.questionIndex].isComplete = this.isComplete;

    this.appState.setNextQuestion(this.textblock.questionIndex);
  }

  displayRatingName(rating: Rating): string {
    // if (this.textblock.reviewType === ReviewTypeEnum.TER)
    //   return rating.name + (this.config.c.assessment.showRatingDescription ? ` (${rating.description})` : '');
    // else 
      if (this.textblock.isMinMaxValueTitle)
      return '';
    else
      return rating.name;
  }

  getRatingsBySet(reviewPeriod: ReviewPeriod, ratings: Rating[], ratingSet: number): Rating[] {
    let items = ratings.filter(x => x.ratingSetId === ratingSet);

    if (reviewPeriod.reviewType != ReviewTypeEnum.MilioTeamDysfunction)
      items = items.sort((a, b) => a.value - b.value);
    else
      items = items.sort((a, b) => b.value - a.value);

    return items;
  }

  getSelectedRatingText(): string {    
    const answerId = this.textblock?.assessmentData?.answerId;
    if (!answerId || !this.textblock.isMinMaxValueTitle) return '';
    
    return this.ratings.find(x => x.id === answerId)?.name ?? '';
  }

  getRatings(): Rating[] {        
    if (this.textblock.ratingSetId) {
      let items = this.getRatingsBySet(this.questionOptions.reviewPeriodEmployee.reviewPeriod, this.questionOptions.ratings, this.textblock.ratingSetId);
      
      if (this.textblock.assessmentData)
      {
        this.textblock.assessmentData.maxRating = Math.max(...items.map((rating: Rating) => rating.value));

        // fix for HVG
        if (this.textblock.reviewType == ReviewTypeEnum.TER && this.textblock.assessmentData.answerId && !items.find(x => x.id == this.textblock.assessmentData.answerId)) {
          let fixedRatingSetId = this.questionOptions.ratings.find(x => x.id == this.textblock.assessmentData.answerId).ratingSetId;
          items = this.getRatingsBySet(this.questionOptions.reviewPeriodEmployee.reviewPeriod, this.questionOptions.ratings, fixedRatingSetId);
        }
        
      }
      return items;
    }

    return [];
  }

  getFirstRatingTitle(): string {
    const item = this.ratings[0];
    return item.name;
  }

  getLastRatingTitle(): string {
    return this.ratings[this.ratings.length-1].name;
  }

  getQuestionIndex(): string {
    if (this.currentIndexText) 
      return this.currentIndexText;

    let result = '';
    if (!this.parentTextblock || this.parentTextblock.questionMode != QuestionModeEnum.ComplexQuestion) {
       
      result = this.parentIndex ?  `${this.parentIndex}${this.index}.` : `${this.index}.`;
    }
    
    return result;
  }

  showEditor() {
    this.titleEditMode = true;
    
  } 

  editTitleTextOk(editedText: string, textblock: Question) {
    textblock.name = editedText;
    this.titleEditMode = false;
  }

  editTextCancel() {
    this.titleEditMode = false;
  }

  showHtmlContent(text: string) {
      return this.sanitizer.bypassSecurityTrustHtml(text) ;
  }

  showGroupHtmlContent(text: string) {
      let indexText = `<span class="line-index group-level-${this.level}">${this.currentIndexText} </span>`;
      return this.sanitizer.bypassSecurityTrustHtml(indexText + text) ;
  }

}
