import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { MassMailOptions, SingleMailOptions } from 'src/app/models/mail-options.model';
import { EmployeeService } from 'src/app/services';
import { ConfirmDialogComponent, IConfirmDialogModel, SearchComponent } from 'src/app/shared/components';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SettingsState } from 'src/app/store/settings.state';
import { AuthService } from '../../services/auth.service';
import { EmailTemplate } from 'src/app/enums/email-template.enum';
import { MainHubService } from 'src/app/services/mainHubService.service';
import { MailProgress } from 'src/app/models/general/mail-progress.model';
import { tap } from 'rxjs';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-email-admin-dialog',
    templateUrl: './email-admin-dialog.component.html',
    styleUrls: ['./email-admin-dialog.component.scss']
})
export class EmailAdminDialogComponent implements OnInit {
    public mainForm: UntypedFormGroup;

    isValidSendType = false;
    isValidMailType = false;    
    isValidEmail = false;

    public addressWithError = 0;
    public addressWithSuccess = 0;

    public inProgress = false;
    
   // onlyForNewUsers = false;
   public mailResultFeature = true;

   public displayedColumns = ['name', 'isSuccess'];

   public mailResults$ = this.mainHubService.mailProgressSubject.asObservable();
   public mailLastProgress$ = this.mainHubService.mailLastProgressSubject.asObservable()
                                .pipe(
                                    tap(data => {
                                        if (data) {
                                            if (data.isSuccess)
                                                this.addressWithSuccess++;
                                            else
                                                this.addressWithError++;
                                        }
                                    })
                                );
    
    public searchEmployeeConfig: SearchConfig<Employee, number> = {
        mapItem: (model: Employee) => {
          return {
            id: model.id, 
            text: `${model.name} (${model.email})`,
            dropText: `${model.name} ${model.employeeIdentifier ?? ''}`,
            itemSource: model } as SearchOption<Employee, number>;
        },
        getList: this.employeeService.getList
    };

    @ViewChild('search')
    search: SearchComponent<Employee, number>;

    constructor(
        private authService: AuthService, 
        private employeeService: EmployeeService,        
        public settingState: SettingsState,
        private mainHubService: MainHubService,
        private fb: UntypedFormBuilder, 
        private router: Router, 
        private msgService: ErrorMessageService,
        private dialog: MatDialog,
        ) {
    }
    
    ngOnInit() {        
        this.mainForm = this.createEmailForm();

        this.mainForm.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(data => {

            this.isValidSendType = data.sendType != null;
            this.isValidMailType = data.mailType != null;
            this.isValidEmail = this.mainForm.controls.sendType.value != 1 || this.getSingleEmail() != '';
        });

        this.reset();

        this.mainForm.get('sendType').valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(value => {
            if (value == '1') {
              this.mainForm.get('sernumFrom').disable();
              this.mainForm.get('sernumTo').disable();
            } else {
                this.mainForm.get('sernumFrom').enable();
                this.mainForm.get('sernumTo').enable();
            }
        });
    }

    reset(){
        console.info('email admin reset');
        this.mainHubService.resetMailProgress();
        this.addressWithError = 0;
        this.addressWithSuccess = 0;
    }

    checkValidity() {

    }

    getSingleEmail(): string {        
        const val = this.search.searchValue as any;

        if (!val) return '';
        
        return val.id ? val.itemSource.email : val;
    }

    createEmailForm() {
        return this.fb.group({
            sendType: [null, [Validators.required]],
            mailType: [null],
            sernumFrom: [{value: null, disabled: true}],
            sernumTo: [{value: null, disabled: true}],
            onlyForNewEmployees: [false]
        });
    }

    sendSingleEmail() {       
        const val = this.mainForm.value;

        const options = {
            email: this.getSingleEmail(),
            mailType: parseInt(val.mailType),
            clientId: this.mainHubService.clientId        
        } as SingleMailOptions;

        let emailTemplateText = '';

        if (options.mailType == EmailTemplate.Registration) {
            emailTemplateText = 'regisztrációs';
        } else if (options.mailType == EmailTemplate.Password) {
            emailTemplateText = 'jelszó emlékeztető';
        } else if (options.mailType == EmailTemplate.Reminder) {
            emailTemplateText = 'emlékeztető';
        }
                
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: {
                title: 'Email küldés',
                message: `A ${options.email} email címre ${emailTemplateText} levelet küldünk. Folytatja?`
            } as IConfirmDialogModel
          });

          dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
            if (dialogResult)
            {
                this.inProgress = true;
                this.authService.sendSingleEmail(options)
                    .pipe(untilDestroyed(this))
                    .subscribe({ next: () => {
                        this.msgService.showSnackBarMessage('emailSendComplete');
                        this.inProgress = false;
                    }, error: (err: IResultModel<ResultModel>) => {                   
                        this.msgService.handleError<ResultModel>(err);
                        this.inProgress = false;
                    }}
                );
            }
         });
        
    };

    sendMassMessages() {
        const val = this.mainForm.value;

        const options = {
            mailType: parseInt(val.mailType),
            serNumFrom: parseInt(val.sernumFrom),
            serNumTo: parseInt(val.sernumTo),
            reviewPeriodId: this.settingState.reviewPeriod.value.id,
            onlyForNewEmployees: val.onlyForNewEmployees,
            clientId: this.mainHubService.clientId
        } as MassMailOptions;

        const quantityText = options.serNumFrom && options.serNumTo ? options.serNumTo - (options.serNumFrom - 1) : "a kiválasztott értékelési időszakban szereplő összes";

        let emailTemplateText = '';

        if (options.mailType == EmailTemplate.Registration) {
            emailTemplateText = `A regisztrációs levél kiküldésre kerül ${quantityText} dolgozó számára.`;
        } else if (options.mailType == EmailTemplate.Reminder) {
            emailTemplateText = `Az emlékeztető levél kiküldésre kerül ${quantityText} dolgozó számára.`;
        }       

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: {
                title: 'Tömeges email küldés',
                message:  emailTemplateText + ' Folytatja?'
            } as IConfirmDialogModel
          });

          dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
             if (dialogResult)
             {
                this.inProgress = true;
                const mailSendAction = options.mailType == 1 ? this.authService.sendRegistrationMessages : this.authService.sendReminderMessages;

                mailSendAction.bind(this.authService)(options)
                .pipe(untilDestroyed(this))
                .subscribe({ next: () => {
                    this.msgService.showSnackBarMessage('emailSendComplete');
                    this.inProgress = false;
                }, error: (err: IResultModel<ResultModel>) => {                   
                    this.msgService.handleError<ResultModel>(err);
                    this.inProgress = false;
                }});
             }
          });
    }

    sendMail() {
        if (this.mainForm.invalid)
            return;

        this.reset();

        const val = this.mainForm.value;

        if (val.sendType == 1)            
            this.sendSingleEmail();
        else if (val.sendType == 2)            
            this.sendMassMessages();
    }
    
    onSearchChange(event) {       
        this.isValidEmail = this.mainForm.controls.sendType.value != 1 || this.getSingleEmail() != '';
    }

    getProgress(data: MailProgress): number {
        const progress = Math.round( data.itemIndex / data.total * 100 );
        return progress;
    }

    changeSettings() {
        this.reset();
    }
}
