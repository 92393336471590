import { ReviewPeriod } from "src/app/models/review-period.model";
import { ReviewTypeStrategyBase } from "../review-type-strategy-base";
import { ReviewPeriodHelper } from "src/app/helpers/review-period.helper";

export class MilioTeamDysfunctionStrategy extends ReviewTypeStrategyBase {
  
    constructor() {
        super();
            
    }

    isReviewAllowed(rp: ReviewPeriod, isSelf: boolean): boolean {
        return ReviewPeriodHelper.isRpOpen(rp) && isSelf;
    }
}