import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadResultType } from 'src/app/enums/upload-result-type.enum';
import { ErrorDetailsDialogResult } from 'src/app/models/dialog/error-details-dialog-result.model';
import { ErrorDialogData } from 'src/app/models/dialog/error-dialog.model';
import { UploadResultItem } from 'src/app/models/other/upload-result-item.model';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-error-details-dialog',
  templateUrl: './error-details-dialog.component.html',
  styleUrls: ['./error-details-dialog.component.scss']
})
export class ErrorDetailsDialogComponent implements OnInit {

  public title: string;  
  public resultItems: UploadResultItem[] = [];

  public errorKeys: string[];
  public warningKeys: string[];

  public dialogResult: ErrorDetailsDialogResult;

  constructor(    
    public dialogRef: MatDialogRef<ErrorDetailsDialogComponent>,
    public msgService: ErrorMessageService,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData
  ) { }

  public get hasError(): boolean {
    return this.data.errorData != null || this.errorKeys?.length > 0;
  }

  public get hasWarning(): boolean {
    return this.warningKeys?.length > 0;
  }

  ngOnInit(): void {
    this.title = this.data.title;
        
    if (this.data.employeeErrorList == null && this.data.employeeWarningList == null)
    {
      this.data.errorData = {key: 'unknownError', msg: ''};
      return;
    }

    this.errorKeys = Object.keys(this.data.employeeErrorList);

    if (this.errorKeys.length > 0)
    {
      this.resultItems.push({ 
        title: 'Hibák',
        subtitle: 'a következő hibák meggátolják a folytatást, kérem javítsa ki őket és próbálja újra.',
        keys: this.errorKeys,
        items: this.data.employeeErrorList,
        isError: true
      });
    }

    this.warningKeys = Object.keys(this.data.employeeWarningList);
    if (this.warningKeys.length > 0)
    {
      this.resultItems.push({ 
        title: 'Észrevételek',
        subtitle: 'a következő tételek csak a figyelem felhívására szolgálnak, ha mindent helyesnek talál, hagyja őket figyelmen kívül. A folyamat folytatható amennyiben más hibát a rendszer nem érzékelt.',
        keys: this.warningKeys, 
        items: this.data.employeeWarningList,
        isError: false
      });
    }
  }

  public showMainMessage() {
    const error = this.data.errorData;
    let message = '';

    if (error)
    {
      if (error?.key)
      {
        const msg = this.msgService.getErrorMessage(error.key);
        message = msg.text;
      }
      else if (error?.msg)
      {
          message = error.msg;
      }
    }
    else
    {
      if (this.errorKeys?.length > 0 || this.warningKeys?.length > 0)
      {      
        const msg = this.msgService.getErrorMessage(this.data.defaultErrorKey);
        message = msg.text;
      }
    }

    return message;
  }

  public getDetailMessage(key: string): string {
    const msg = this.msgService.getErrorMessage(key);
    return msg.text;
  }

  public showDetail(data: any)
  {
    return '';
  }
  
  public continue() {
    this.dialogResult = { dialogCloseResult: 'continue' };
    this.dialogRef.close(this.dialogResult);
  }

  public close() {
    this.dialogResult = { dialogCloseResult: 'close' };

    if (this.hasError)
      this.dialogResult.uploadResultType = UploadResultType.Error;
    else if (this.hasWarning)
      this.dialogResult.uploadResultType = UploadResultType.Interrupted;

    this.dialogRef.close(this.dialogResult);
  }

}
