import { AbstractControl, FormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ISelectable } from 'src/app/models/general/selectable.model';
import { DataTypeHelper } from './data-type.helper';
import { Temporal } from '@js-temporal/polyfill';
import { DateHelper } from './date.helper';

export abstract class CustomValidators {

    public static numeric(control: AbstractControl): ValidationErrors | null {        
        if (DataTypeHelper.isEmpty(control.value)) { return null; }
    
        const text: string = control.value;
        return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(text) ? null : { numeric: true };
      }
      
    public static integer(control: AbstractControl): ValidationErrors | null {        
        if (DataTypeHelper.isEmpty(control.value)) { return null; }
    
        const text: string = control.value;
        return /^-?\d+$/.test(text) ? null : { notInteger: true };
    }

    public static equalAs(fieldKey: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.parent) return null;
            const otherField = (control.parent as UntypedFormGroup).controls[fieldKey];

            if (control.value != otherField.value)
            {
                let element = (otherField as any).nativeElement; 
                let title = element.attributes['placeholder']?.value;

                return {
                    notEqual: { key: fieldKey, title: title }
                };
            }
            else
                return null;
        };
    }

    public static minSelectedCountArray = (min: number) => {
        return (c: FormArray): {[key: string]: any} => {
          const selectedItems = (c.value as ISelectable[]).filter(x => x.isSelected);
            
          if (selectedItems.length >= min)
            return null;
      
          return { MinSelectedCountArray: true};
        }
    }

    public static minLengthArray = (min: number) => {
        return (c: AbstractControl): {[key: string]: any} => {
          if (c.value.length >= min)
            return null;
      
          return { MinLengthArray: true};
        }
      }

    public static matchValues(field1: string, field2: string): ValidatorFn {
        return (group: UntypedFormGroup): ValidationErrors | null => {
            const control1 = group.controls[field1];
            const control2 = group.controls[field2];
            if (!control1.dirty || !control2.dirty) return null;

            if (control1.value === control2.value)
                return null;
            else
            {
                control2.setErrors({ noPassswordMatch: true });
                return { notMatched: true };
            }
        }
    }

    public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            // if control is empty return no error
            return null;
          }
      
          // test the value of the control against the regexp supplied
          const valid = regex.test(control.value);
      
          // if true, return no error (no error), else return error passed in the second parameter
          return valid ? null : error;
        };
    }

    public static atLeastOneFieldTrueValidatorWithCondition(
      fieldNames: string[],
      condition: (form: AbstractControl) => boolean // A feltételt egy függvényként várjuk, amely megkapja a formot
    ): ValidatorFn {
      return (form: AbstractControl): ValidationErrors | null => {
        // Először ellenőrizzük a feltételt
        if (!condition(form)) {
          return null; // Ha a feltétel nem teljesül, akkor nincs hiba (form érvényes)
        }
    
        // Ha a feltétel teljesül, ellenőrizzük a mezőket
        const isAnyFieldTrue = fieldNames.some(fieldName => form.get(fieldName)?.value === true);
        
        // Ha egyik sem true, hibát adunk vissza, különben nullt (nincs hiba)
        return isAnyFieldTrue ? null : { atLeastOneFieldTrue: true };
      };
    }

    public static conditionalRequiredValidator(condition: (form: AbstractControl) => boolean): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
       // Ellenőrizzük, hogy van-e szülője (parent) a control-nak
        const formGroup = control.parent;
        if (!formGroup) {
          return null; // Ha nincs szülő (form group), akkor nem tudjuk ellenőrizni, tehát nincs hiba
        }
       
        // Ha a feltétel nem teljesül, akkor a mező érvényes
        if (!condition(formGroup)) {
          return null; // A mező érvényes, ha a feltétel nem teljesül
        }
    
        // Ha a feltétel teljesül, akkor ellenőrizzük, hogy a mező nincs-e kitöltve (azaz null vagy üres)
        return control.value ? null : { conditionalRequired: true }; // Hibát adunk vissza, ha nincs kitöltve
      };
    }

    public static dateShouldBeAfterThanValidator(compareDateField: string): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
          return null; // Ha a form még nem épült fel, akkor nincs ellenőrzés
        }
    
        const otherControl = control.parent.get(compareDateField);
        if (!otherControl) {
          return null; // Ha a megadott mező nem létezik, nincs ellenőrzés
        }
    
        // Ha mindkét mezőn van érték és azok dátumok
        const currentDate = DateHelper.toPlainDate(control.value);
        const otherDate = DateHelper.toPlainDate(otherControl.value);
    
        if (currentDate && otherDate)
        { 
          if ( Temporal.PlainDate.compare(currentDate, otherDate) < 0) {
            return { dateAfter: true }; // Hibaüzenet, ha a currentDate korábbi, mint az otherDate
          }
        }
    
        return null; // Érvényes, ha a currentDate későbbi vagy azonos az otherDate-tel, vagy nincs érték
      };
    }

    public static dateShouldBeEarlierThanValidator(compareDateField: string): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
          return null; // Ha a form még nem épült fel, akkor nincs ellenőrzés
        }
    
        const otherControl = control.parent.get(compareDateField);
        if (!otherControl) {
          return null; // Ha a megadott mező nem létezik, nincs ellenőrzés
        }
    
        // Ha mindkét mezőn van érték és azok dátumok
        const currentDate = DateHelper.toPlainDate(control.value);
        const otherDate = DateHelper.toPlainDate(otherControl.value);
    
        if (currentDate && otherDate) {
          if (Temporal.PlainDate.compare(currentDate, otherDate) > 0) {
            return { dateBefore: true }; // Hibaüzenet, ha a currentDate korábbi, mint az otherDate
          }
        }
    
        return null; // Érvényes, ha a currentDate későbbi vagy azonos az otherDate-tel, vagy nincs érték
      };
    }

    public static dateIntervalShouldBeInIntervalValidator(boxStartDateField: string, boxEndDateField: string, startDateField: string): ValidatorFn {
      return (endDateControl: AbstractControl): ValidationErrors | null => {
        
        //should: boxStartDate <= startDate <= endDate <= boxEndDate

        if (!endDateControl.parent) {
          return null; // Ha a form még nem épült fel, akkor nincs ellenőrzés
        }
    
        const boxStartControl = endDateControl.parent.get(boxStartDateField);
        if (!boxStartControl) {
          return null; // Ha a megadott mező nem létezik, nincs ellenőrzés
        }

        const boxEndControl = endDateControl.parent.get(boxEndDateField);
        if (!boxEndControl) {
          return null; // Ha a megadott mező nem létezik, nincs ellenőrzés
        }

        const startDateControl = endDateControl.parent.get(startDateField);
        if (!startDateControl) {
          return null; // Ha a megadott mező nem létezik, nincs ellenőrzés
        }

        // Ha mindkét mezőn van érték és azok dátumok
        const startDate = DateHelper.toPlainDate(startDateControl.value);
        const endDate = DateHelper.toPlainDate(endDateControl.value);
        const boxStartDate = DateHelper.toPlainDate(boxStartControl.value);
        const boxEndDate = DateHelper.toPlainDate(boxEndControl.value);
    
        if (boxStartDate && startDate && endDate && boxEndDate) {
          if (
                Temporal.PlainDate.compare(boxStartDate, startDate) <= 0 &&
                Temporal.PlainDate.compare(startDate, endDate) <= 0 &&
                Temporal.PlainDate.compare(endDate, boxEndDate) <= 0
             ) 
          {
            return null;
          }
          else 
          {
            return { dateInInterval: true }; // Hibaüzenet, ha a currentDate korábbi, mint az otherDate
          }
        }
    
        return null; // Érvényes, ha a currentDate későbbi vagy azonos az otherDate-tel, vagy nincs érték
      };
    }
}
