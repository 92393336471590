﻿<div class="container main-container">
            
    <div *ngIf="reportList">
        <mat-card class="card-light">
            <mat-card-header>
                <mat-card-title>Riportok</mat-card-title>
            </mat-card-header>
            <mat-card-content class="responsive-size">
                <table mat-table [dataSource]="reportList" class="table-default table-hover hover-pointer" multiTemplateDataRows>
                    <ng-container matColumnDef="reviewType">
                        <th mat-header-cell *matHeaderCellDef>Típus</th>
                        <td mat-cell *matCellDef="let element">{{ element.reviewPeriod.reviewType | reviewPeriodType }}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Megnevezés</th>
                        <td mat-cell *matCellDef="let element">{{ element.reviewPeriod.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="period">
                        <th mat-header-cell *matHeaderCellDef>Időszak</th>
                        <td mat-cell *matCellDef="let element">{{ element.reviewPeriod | periodDate }}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="report-links">
                            <ng-container *ngIf="element.reviewPeriod.reviewType === reviewTypes.PersonalityTest">

                                <a mat-icon-button title="Egyéni riport" [routerLink]="['/reports/personality-test/details', element.reviewPeriodEmployee?.reviewPeriodEmployeeId]" *ngIf="element.reviewPeriodEmployee?.status == assessmentStates.Finished">
                                    <mat-icon fontSet="material-icons-outlined">article_outline</mat-icon>
                                </a>
                                <a mat-icon-button title="Diagram riport" [routerLink]="['/reports/personality-test/diagram', element.reviewPeriodEmployee?.reviewPeriodEmployeeId]" *ngIf="element.reviewPeriodEmployee?.status == assessmentStates.Finished">
                                    <mat-icon fontSet="material-icons-outlined">insert_chart_outline</mat-icon>
                                </a>
                                <!-- <a mat-icon-button title="Segédlet" href="/assets/szemelyisegteszt-segedlet.pdf" target="_blank">
                                    <mat-icon fontSet="material-icons-outlined">assignment_outline</mat-icon>
                                </a> -->

                            </ng-container>
                            <ng-contaner *ngIf="element.reviewPeriod.reviewType === reviewTypes.MilioTeamDysfunction">
                                <a mat-icon-button title="Csapat diszfunkciók" [routerLink]="['/reports/team-dysfunctions', element.reviewPeriod.id]">
                                    <mat-icon fontSet="material-icons-outlined">article_outline</mat-icon>
                                </a>
                            </ng-contaner>
                            <ng-contaner *ngIf="element.reviewPeriod.reviewType === reviewTypes.MilioStressMap">
                                <a mat-icon-button title="Csapat stressztérkép" [routerLink]="['/reports/team-stressmap', element.reviewPeriod.id]">
                                    <mat-icon fontSet="material-icons-outlined">article_outline</mat-icon>
                                </a>
                            </ng-contaner>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let element">
                            <button *ngIf="hasDetails(element.reviewPeriod.reviewType)" mat-icon-button aria-label="expand row" title="Részletek">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                            </button>
                        </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="masterTableColumns.length">
                            <div class="element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                
                                <div *ngIf="expandedElement && expandedElement === element">
                                    <div *ngIf="employeeReports$ | async as employeeReports">                                        
                                        <div *ngIf="employeeReports?.length > 0" class="details-panel">

                                                <div>
                                                    <div class="table-title">Személyek</div>
                                                </div>
                                                <table mat-table [dataSource]="employeeReports" class="table-default table-hover hover-pointer details-table">
                                                                                                        
                                                    <ng-container matColumnDef="name">
                                                        <th mat-header-cell *matHeaderCellDef>Név</th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeName }}</td>
                                                    </ng-container>
                                    
                                                    <ng-container matColumnDef="email">
                                                        <th mat-header-cell *matHeaderCellDef>Email</th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeEmail }}</td>
                                                    </ng-container>
                                
                                                    <ng-container matColumnDef="employeeIdentifier">
                                                        <th mat-header-cell *matHeaderCellDef>Törzsszám</th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeIdentifier }}</td>
                                                    </ng-container>
                                    
                                                    <ng-container matColumnDef="isContractor">
                                                        <th mat-header-cell *matHeaderCellDef></th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeType }}</td>
                                                    </ng-container>
                                
                                                    <ng-container matColumnDef="action">
                                                        <th mat-header-cell *matHeaderCellDef></th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <a mat-icon-button title="Megjelenítés">
                                                                <mat-icon fontSet="material-icons-outlined">description_outline</mat-icon>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                    
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
                                                        [routerLink]="['/reports', row.id, expandedElement.reviewPeriod.id]"></tr>
                                                </table>
                                                                                
                                        </div>
                                    </div>
                                </div>   

                            </div>
                            </td>
                        </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="masterTableColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: masterTableColumns;" 
                        class="element-row"                         
                        [ngClass]="{'selected': element.isSelected, 'no-click': !isClickable(element), 'element-expanded-row' : expandedElement === element }"
                        (click)="onMasterRowClick(element)"
                        ></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
                </table>
    
            </mat-card-content>
        </mat-card>        

        <!-- <mat-accordion>

            <mat-expansion-panel *ngFor="let reviewPeriod of data.reviewPeriods" class="default">
                <mat-expansion-panel-header>{{ reviewTypeDisplayName(reviewPeriod) }}</mat-expansion-panel-header>                
                <table mat-table [dataSource]="reviewPeriod.employees" class="table-default table-hover hover-pointer">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Név</th>
                        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                    </ng-container>

                    <ng-container matColumnDef="isContractor">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">{{ element | employeeType }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/' + state.config.c.reports.root, row.id, reviewPeriod.id]"></tr>
                </table>
            </mat-expansion-panel>

        </mat-accordion> -->
        
    </div>
</div>
