﻿<div class="container">
    <h4 class="page-title">Felhasználó létrehozása</h4>

    <mat-card class="card-light" *ngIf="companyGroups$ | async as companyGroups">
        <mat-card-content>

            <form [formGroup]="newUserForm" class="form-container" *ngIf="companies$ | async as companies">

                <mat-form-field appearance="outline">
                    <mat-label>Név</mat-label>
                    <input matInput formControlName="name" required>
                    <mat-error msg></mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required>
                    <mat-error msg></mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Cég</mat-label>
                    <mat-select formControlName="company">
                      <mat-option *ngFor="let company of companies">
                        {{company}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Munkakör</mat-label>
                    <mat-select formControlName="companyGroup">
                      <mat-option *ngFor="let companyGroup of companyGroups" (click)="createUser(companyGroup)">
                        {{companyGroup}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Szerepkör</mat-label>
                    <mat-select formControlName="role">
                      <mat-option value="Admin">
                        Admin
                      </mat-option>
                      <mat-option value="Assistant">
                        Assistant
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </form>
            
            <!-- <div >
                <div>
                    <span class="label label-success" *ngIf="showCreateUserSuccess()">Hozzáadás sikeres</span>
                    <span class="label label-danger" *ngIf="showCreateUserError()">Hozzáadás sikertelen</span>
                </div>
                <div>
                    <span class="label label-success" *ngIf="showCreateRoleUserSuccess()">Hozzáadás sikeres</span>
                    <span class="label label-danger" *ngIf="showCreateRoleUserError()">Hozzáadás sikertelen</span>
                </div>
            </div> -->
            
        </mat-card-content>
    </mat-card>

</div>