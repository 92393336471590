<form [formGroup]="mainForm">
    <h1 mat-dialog-title>Cég szerkesztése</h1>
    <div mat-dialog-content>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Cégnév</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
            <mat-error msg></mat-error>
        </mat-form-field>
  
    </div>
    <div mat-dialog-actions class="flex-h flex-end">
      <button mat-flat-button color="primary" (click)="save()" type="submit">Ok</button>
      <button mat-flat-button [mat-dialog-close]>Mégsem</button>
    </div>
</form>