import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { UserBulkInsertDialogResult } from 'src/app/models/dialog/user-bulk-insert-dialog-result.model';
import { AddToEmployeeGroupValidation, EmployeeEmailsCheck, EmployeeEmailsCheckResult } from 'src/app/models/employee-emails-check.model';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeBulkInsertDialogConfig } from 'src/app/models/emplyee-bulk-insert-dialog-config.model';
import { Search } from 'src/app/models/search.model';
import { EmployeeService } from 'src/app/services';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';

@Component({
  selector: 'app-user-bulk-insert',
  templateUrl: './user-bulk-insert.component.html',
  styleUrls: ['./user-bulk-insert.component.scss']
})
export class UserBulkInsertComponent implements OnInit {

  public mainForm: UntypedFormGroup;

  public checkResultSubject = new BehaviorSubject<EmployeeEmailsCheckResult>({} as EmployeeEmailsCheckResult);
  public checkResult$ = this.checkResultSubject.asObservable();
  public inputText: string;

  public aboutEmployee: Employee;

  public searchEmployeeConfig: SearchConfig<Employee, number> = {
    mapItem: (model: Employee) => {
      return {
        id: model.id, 
        text: `${model.name} (${model.email})`,
        dropText: `${model.name} ${model.employeeIdentifier ?? ''}`,
        itemSource: model } as SearchOption<Employee, number>;
    },
    getList: this.employeeService.getList
  };

  public get showAboutEmployee(): boolean {
    return this.data.reviewPeriod?.isSpecialQuestionSetBasedAssessment || this.data.reviewPeriod?.isPositionBasedAssessment;
  }

  public get isAllowSubmit(): boolean {
    if (this.showAboutEmployee && !this.aboutEmployee) return false;
    if (!this.checkResultSubject.value.validEmployees) return false;

    return this.checkResultSubject.value.validEmployees.length > 0 && this.checkResultSubject.value.invalidEmails.length == 0;
  }
      
  constructor(
    public dialogRef: MatDialogRef<UserBulkInsertComponent>,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: EmployeeBulkInsertDialogConfig) { }

  ngOnInit(): void {

    if (this.data.reviewPeriod) // csak akkor töltjük ha RPE-t módosítunk, employee group esetén nem kell
      this.searchEmployeeConfig.options = {reviewPeriodId: this.data.reviewPeriod?.id, isTargetLeader: true} as Search;
  }
  
  public parseEmails(text: string): string[] {
    return text.split('\n').map(x => x.trim()).filter(x => x != '');
  }

  public inputFieldChanged() {
    if (this.inputText.trim() === '') return;

    const dto = {      
      emails: this.parseEmails(this.inputText)
    } as EmployeeEmailsCheck;

    this.data.validateAction(dto).subscribe(result => {
      this.checkResultSubject.next(result);
      this.inputText = '';     
    });
  }

  public ok() {
    let result = {
      items: this.checkResultSubject.value.validEmployees.map( x => x.id),
      aboutEmployeeId: this.aboutEmployee?.id
    } as UserBulkInsertDialogResult;
    
    this.dialogRef.close(result);
  }

}
