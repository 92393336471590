import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { BehaviorSubject, Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { PagedListResult, PagedListResultModel } from "src/app/models/paged-list-result.model";
import { Search } from "src/app/models/search.model";
import { ActionStateModel } from "../../models/action-state.model";
import { SearchConfig } from "../../models/search-config.model";
import { SearchOption } from "../../models/search-option.model";
import { MatAutocomplete } from "@angular/material/autocomplete";

//This is a general search component that can be used in any form
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    }
  ]
})
export class SearchComponent<T, TId> implements ControlValueAccessor, OnInit {

  @Output() public add: EventEmitter<string | SearchOption<T, TId>> = new EventEmitter<string | SearchOption<T, TId>>();
  @Output() public change: EventEmitter<string | SearchOption<T, TId>> = new EventEmitter<string | SearchOption<T, TId>>();
  @Output() public blur = new EventEmitter<string>();

  public getList: (options?: Search) => Observable<PagedListResult<T>>;
  public options$: Observable<SearchOption<T, TId>[]>;
  public filteredOptions$: Observable<SearchOption<T, TId>[]>;
  public loading$ = new BehaviorSubject(false);

  public list: SearchOption<T, TId>[];
  public searchControl: UntypedFormControl;

  public minCharCount = 3;
  public total = 0;
  
  //Search config
  @Input()
  public title: string;

  @Input()
  public placeholder: string;
  public addIcon = 'add_circle_outline';

  @Input()
  public controlType: 'input' | 'textarea' = 'input';	  //input or textarea

  @Input()
  public appearance: 'fill' | 'outline' = 'outline';

  @Input()
  public showSearchIcon = true;

  @Input()
  public showAddButton = true;

  @Input()
  public addButtonTooltip = '';

  @Input()
  public rows = 3;

  @Input()
  public isCustomAdd = false;

  @Input() 
  public disabledAddButton = false;

  @Input() 
  public multilineDropdown = false;

  @Input()
  public inputText: SearchOption<T, TId>;

  @Input()
  public hiddenRows: TId[] = [];

  @ViewChild('autocomplete') autocomplete: MatAutocomplete;

  @Input()
  public disabled = false;
  public showEditButton = false;
  public actionState = new ActionStateModel();

  @Input() public inlineButtons = false;     //when editMode is true, this will allow to show the Ok and cancel buttons
  @Input() public isAllowSearch = false;

  @Input() public config: SearchConfig<T, TId>;
  // --
  @Input() public isLargeInput = false; 
  
  protected mapItem: (model: T) => SearchOption<T, TId>;

  constructor() {

  }

  ngOnInit(): void {
    if (this.config) {
      this.mapItem = this.config.mapItem;
      this.getList = this.config.getList;
    }
    this.initComponent();
  }

  // ControlValueAccessor interface methods

  writeValue(value: any) {
    if (value !== undefined) {
      this.searchControl.setValue(value);
    }    
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  // --

  public get searchValue(): string | SearchOption<T, TId> {
    return this.searchControl.value;   
  }

  public displayFn(opt: SearchOption<T, TId>): string {
      return opt?.text || '';
  }
 
  public loadValue(opt: SearchOption<T, TId>) {
    this.searchControl.setValue(opt);
  } 

  protected initComponent() {
    
    // Init Search
    this.searchControl = new UntypedFormControl('');
    // --

    if (this.inputText)
    {
      this.loadValue(this.inputText);
    }

    this.filteredOptions$ = this.searchControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      //startWith(''),
      tap(data => {
        this.loading$.next(true);
      }),
      map(result => result === null ? '' : result),
      switchMap(searchString => searchString.length === 0 || searchString.length >= this.minCharCount ? this.getList(this.getOptions(searchString)): of(new PagedListResultModel<T>())),
      map(result => {
        this.total = result.total;
        return result.list.map((item) => this.mapItem(item));
      }),
      tap(data => {
        this.loading$.next(false);
      })

    );
  }

  protected getOptions(searchString: string): Search {
    if (this.config.options)
      return {...this.config.options, search: searchString} as Search;
    else
      return {search: searchString} as Search;
  }
  
  protected findItem(list: SearchOption<T, TId>[], id: TId): SearchOption<T, TId> {
    return Array.isArray(list) ? list.find(x => x.id === id) : null;
  }

  protected textContains(text: string, search: string): boolean {
    return text.toLowerCase().indexOf(search.toLowerCase()) > -1;
  }

  public getValue(): string | SearchOption<T, TId> {
    this.propagateChange(this.searchControl.value);
    return this.searchControl.value;
  }

  protected onAdd() {      
    if (!this.searchControl.value) return;
    if (this.disabled) return;

    //this.disabled = true;
    this.propagateChange(this.searchControl.value);
    this.add.emit(this.searchControl.value);
    this.searchControl.setValue('');
  } 

  protected onOptionSelected(option: SearchOption<T, TId>) {
    this.propagateChange(option);
    this.change.emit(option);
  }   

  protected onOk() {
    this.actionState.editMode = false;
    this.disabled = false;
  }

  protected onCancel() {
    this.actionState.editMode = false;
    this.disabled = false;
  }

  protected onTouched() {
    this.blur.emit(this.searchControl.value);
  }

  protected onTextChanged($event) {
    if (!this.searchControl.value) return;
    if (this.disabled) return;

    //this.disabled = true;
    this.propagateChange(this.searchControl.value);
    this.add.emit(this.searchControl.value);
  }

  protected isHidden(option: SearchOption<T, TId>): boolean {
    return this.hiddenRows.includes(option.id);
  }
}