<ng-container *ngIf="details$ | async as details">
    <div *ngIf="details?.length > 0" class="details-panel">
            <div *ngIf="isPanorama(reviewPeriod.reviewType)">
                <div class="table-title">Értékelendő személyek</div>                                                    
            </div>
            <div *ngFor="let element of details; index as i" [ngClass]="{'inactive': !isReviewPeriodOpen }" class="details-row">
                <div class="field-name">
                    <div class="employee-name">{{ element | employeeName }}</div>
                    <div class="employee-email">{{ element.email }}<span *ngIf="element.employeeIdentifier"> | {{ element | employeeIdentifier }}</span></div>
                    <div>{{ element | employeeType }}</div>
                </div>
                <div class="field-details">

                    <div class="field-block" *ngIf="element?.deadline && isSziluett(element.reviewType) && !isFinished(element.status)">
                        <div class="field-block-title">Határidő</div>
                        <div class="field-block-value" [ngClass]="{'date-expired': isDateExpired(element?.deadline)}">
                            {{ element?.deadline | date:'yyyy.MM.dd' }}
                        </div>
                    </div>

                    <div class="field-block" *ngIf="isFinished(element.status)">
                        <div class="field-block-title">Kitöltve</div>
                        <div class="field-block-value">
                            {{ element?.closedOn | date:'yyyy.MM.dd' }}
                        </div>
                    </div>

                    <div class="field-block" *ngIf="getStatusText(element)">
                        <div class="field-block-title">Állapot</div>
                        <div class="field-block-value">{{ getStatusText(element) }}</div>
                    </div>
                </div>
                <div class="col-action">
                    <button mat-flat-button class="review-button bg-alt2 detail-button" *ngIf="isReviewAllowed(element)" (click)="onReviewClick(element)">Kitöltés</button>
                    
                    <ng-container *ngIf="isSziluett(element.reviewType) && isFinished(element?.status)">
                        <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" class="review-button detail-button">
                            <mat-icon>keyboard_arrow_down</mat-icon> 
                            Riport
                        </button>
                            <mat-menu #menu="matMenu">
                                <a mat-menu-item title="Egyéni riport" [routerLink]="['/reports/personality-test/details', element.reviewPeriodEmployeeId]">
                                    <mat-icon fontSet="material-icons-outlined">article_outline</mat-icon> Egyéni riport
                                </a>
                                <a mat-menu-item title="Diagram riport" [routerLink]="['/reports/personality-test/diagram', element.reviewPeriodEmployeeId]" *ngIf="employee.isSziluettDiagramAllowed">
                                    <mat-icon fontSet="material-icons-outlined">insert_chart_outline</mat-icon> Diagram riport
                                </a>
                            </mat-menu>
                    </ng-container>
                    <button mat-flat-button class="review-button bg-alt1 detail-button" *ngIf="isPanoramaReportAllowed(element)" (click)="onReportClick(element)">Riport</button>
                </div>
            </div>

            <!-- <table mat-table [dataSource]="details" class="table-default table-hover hover-pointer details-table">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Név</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="employee-name">{{ element | employeeName }}</div>
                        <div class="employee-email">{{ element.email }}<span *ngIf="element.employeeIdentifier"> | {{ element | employeeIdentifier }}</span></div>
                        <div>{{ element | employeeType }}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="col-email">Email és Törzsszám</th>
                    <td mat-cell *matCellDef="let element" class="col-email">{{ element | employeeEmail }}<span *ngIf="element.employeeIdentifier"> | {{ element | employeeIdentifier }}</span></td>
                </ng-container>
                
                <ng-container matColumnDef="deadline">
                    <th mat-header-cell *matHeaderCellDef class="col-deadline" [hidden]="isPanorama(reviewPeriod.reviewType)">Határidő</th>
                    <td mat-cell *matCellDef="let element" [hidden]="isPanorama(reviewPeriod.reviewType)">
                        {{ element?.deadline | date:'yyyy.MM.dd' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="col-status">Állapot</th>
                    <td mat-cell *matCellDef="let element">
                        <div>{{ element | employeeReviewState }}</div>
                        <div *ngIf="isFinished(element?.status)">{{ element.closedOn | date:'yyyy.MM.dd' }}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="col-action"></th>
                    <td mat-cell *matCellDef="let element" class="col-action">

                        
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="{'inactive': !isReviewPeriodOpen }"></tr>
            </table>                               -->
    </div>
</ng-container>
