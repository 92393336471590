import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { GoalTypeEnum } from 'src/app/enums/goal-type.enum';
import { ReviewPeriodStatusEnum } from 'src/app/enums/review-period-status.enum';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { GoalsHelper } from 'src/app/helpers/goals.helper';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { Employee } from 'src/app/models/employee.model';
import { Goal, GoalModel } from 'src/app/models/goal.model';
import { GoalPeriod } from 'src/app/models/goalperiod.model';
import { GoalsModel } from 'src/app/models/goals.model';
import { GoalTextModel } from 'src/app/models/goaltext.model';
import { GroupGoalsData } from 'src/app/models/group-goals-data.model';
import { KpiModel } from 'src/app/models/kpi.model';
import { ReviewPeriodGoalsForEmployee } from 'src/app/models/review-period-goals-for-employee.model';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { State } from 'src/app/models/state.model';
import { GoalService, KpiService, StateService } from 'src/app/services';
import { GroupGoalService } from 'src/app/services/group-goal.service';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';

@UntilDestroy()
@Component({
    selector: 'goals',
    templateUrl: './goals.component.html',
    styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {    

    public state: State;
    
    public selectedPeriod: ReviewPeriodGoalsForEmployee;

    public openPeriods: ReviewPeriodGoalsForEmployee[] = [];
    public closedPeriods: ReviewPeriodGoalsForEmployee[] = [];

    public loading = true;
    public isSelf = false;
    
    public employees: Employee[] = [];
    
    public isAddGoal = false;

      
    //selected in the dropdown
    public currentEmployeeId: number | null = null;    
    public openAccordion = null;

    public get isPeriodOpen(): boolean {
        return !this.selectedPeriod.goalPeriod.isClosed;
    }

    public set isPeriodOpen(value: boolean) {
        this.selectedPeriod.goalPeriod.isClosed = !value;
    }

    public get hasOpenRp(): boolean {
        let items = this.openPeriods && this.openPeriods.filter(x => x.reviewPeriod.status === ReviewPeriodStatusEnum.Open);
        return items.length > 0;
    }

    public get hasRpInDateInterval(): boolean {
        let items = this.openPeriods && this.openPeriods.filter(x => !this.isOutOfDateInterval(x.reviewPeriod));
        return items.length > 0;
    }
   
    public reviewTypes = ReviewTypeEnum;
    public isRpOpen = ReviewPeriodHelper.isRpOpen;
    public isRpNew = ReviewPeriodHelper.isRpNew;

    constructor(
        private goalService: GoalService, 
        private groupGoalService: GroupGoalService,
        stateService: StateService, 
        private kpiService: KpiService,
        private route: ActivatedRoute,
        private router: Router, 
        private msgService: ErrorMessageService,
        private appState: AppState
        ) 
    {
        this.state = stateService.state;
        this.appState.assessmentCurrentQuestionIndex
    }


    ngOnInit() {        
        this.route.params
        .pipe(untilDestroyed(this))
        .subscribe(params => {
            
            const employeeId = params['employeeId'] ? parseInt(params['employeeId']) : null;

            this.goalService.getGoals(employeeId)
            .pipe(
                map(data => {
                    data.reviewPeriodGoalsForEmployee.map(item => {                                                                                                
                        item.goalPeriod.goals.map(goal => {
                            goal.goalText = goal.goalText || { name: '', goalType: GoalTypeEnum.Goal, isGlobal: false } as GoalTextModel;
                            return goal;
                        });

                        return item;
                    });

                    return data;
                }),
                untilDestroyed(this)
            )
            .subscribe({ next: (data: GoalsModel) => { 
                this.openPeriods = data.reviewPeriodGoalsForEmployee.filter(x => x.reviewPeriod.status != ReviewPeriodStatusEnum.Closed);
                this.closedPeriods = data.reviewPeriodGoalsForEmployee.filter(x => x.reviewPeriod.status == ReviewPeriodStatusEnum.Closed);

                this.selectedPeriod = data.reviewPeriodGoalsForEmployee.length > 0 ? data.reviewPeriodGoalsForEmployee[0] : null;
                //this.currentPeriod.questionSets = this.createQuestionGroups();

                console.log(this.selectedPeriod);
                
                this.employees = data.employees;

                if (employeeId)
                    this.currentEmployeeId = Number(employeeId);
                else if (data.employees.length > 0)
                    this.currentEmployeeId = data.employees[0].id;
                else
                    this.currentEmployeeId = null;

                this.isSelf = data.isSelf;
                                
                this.loading = false;

                
            }, error: (e) => {
                if (e.status === 403) {                        
                    this.router.navigate(['/goals']);
                }
                console.error(e);
                this.loading = false;
            }});
            
        });        
    }

    isOutOfDateInterval(rp: ReviewPeriod): boolean {
        let dateFrom = new Date(rp.goalsStartDate.toString());
        let dateTo = new Date(rp.goalsEndDate.toString());
        let today = new Date();

        return today < dateFrom || today > dateTo;
    }

    getGroupGoalsData(goalPeriod: GoalPeriod): GroupGoalsData {
       
        let dt = new GroupGoalsData();
        dt.reviewPeriodId = goalPeriod.reviewPeriod.id;
        dt.employeeId = this.currentEmployeeId;
        dt.isSelf = this.isSelf;
        dt.loading = this.loading;
                 
        return dt;
    }
   
    changeEmployee(employeeId) {
        this.router.navigate(['/goals', employeeId]);
    };
   
    addGoal(goalPeriod: GoalPeriod) {
        let found = goalPeriod.goals.find(x => x.id == 0);

        if (!found)
            goalPeriod.goals.push(new GoalModel());
    }

    addKpi(rpForEmployee: ReviewPeriodGoalsForEmployee) {
        let found = rpForEmployee.kpiList.find(x => x.id == 0);

        if (!found) {
            let model = new KpiModel();            
            model.aboutEmployeeId = this.currentEmployeeId;
            model.byEmployeeId = this.state.me.value.employee.id;
            model.reviewPeriodId = rpForEmployee.reviewPeriod.id;
            
            rpForEmployee.kpiList.push(model);
        }
    }

    goalAdded(goal: Goal) {

    }
   

    getQuarter(quarter: number): string {
        return GoalsHelper.getQuarter(quarter);
      }
        
    changePeriodState(goalPeriod: GoalPeriod, isClosed: boolean) {
        const oldState = !isClosed;
        this.goalService.changeGoalPeriodState(goalPeriod.id, goalPeriod.reviewPeriod.id, goalPeriod.employeeId, isClosed).subscribe(result => {
            goalPeriod.id = result.id;

            if (isClosed)
                this.msgService.showSnackBarMessage('goalPeriodClosed');
            else
                this.msgService.showSnackBarMessage('goalPeriodOpen');

        }, error => {
            goalPeriod.isClosed = oldState;
            this.msgService.showSnackBarMessage('saveFailed');
        });
    } 

    updateComment(goalPeriod: GoalPeriod, comment) {
        this.loading = true;
        this.goalService.updateComment(goalPeriod.id, goalPeriod.reviewPeriod.id, goalPeriod.employeeId, comment).subscribe((result) => {
            this.loading = false;
        }, (error) => {
            if (confirm('Hiba történt a mentés során, megpróbálja újra?')) {
                this.updateComment(goalPeriod, comment);
            }
            console.log(error);
            this.loading = false;
        });
    };

    getReviewPeriodDisplayName(rp: ReviewPeriod): string {
        return ReviewPeriodHelper.getDisplayText(rp);
    }

    openHistory(reviewPeriodGoals: ReviewPeriodGoalsForEmployee) {
        if ( reviewPeriodGoals.goalPeriod?.goals?.length > 0 || reviewPeriodGoals?.groupGoals?.length > 0) {
            //already loaded
            return;
        }

        this.groupGoalService.getHistory(reviewPeriodGoals.reviewPeriod.id, this.currentEmployeeId).subscribe((data) => {
            reviewPeriodGoals.groupGoals = data.groupGoals ?? [];
            reviewPeriodGoals.goalPeriod.goals = data.goals ?? [];
        });

    }  
       
}
