import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action } from '../models/action.model';
import { GoalPeriodChangeState } from '../models/goal-period-change-state.model';
import { IGoalPeriodComment } from '../models/goal-period-comment.model';
import { Goal, InsertGoal } from '../models/goal.model';
import { GoalPeriod } from '../models/goalperiod.model';
import { GoalsModel } from '../models/goals.model';
import { GoalText } from '../models/goaltext.model';
import { GroupGoal } from '../models/group-goal.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { Search } from '../models/search.model';
import { DataService } from './data.service';
import { StateService } from './state.service';
import { ApiHelper } from '../shared/helpers';

@Injectable({
    providedIn: 'root',
})
export class GoalService extends DataService {
    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);
    }
    
    getGoals(id?: number): Observable<GoalsModel>  {
        return this.get('/api/goals/' + (id ? id : ''));
    }

    getGoalTexts = (): Observable<GoalText[]> => {
        return this.get<GoalText[]>('/api/goals/goal-text-list');
    }

    getGoalTextsPaged = (options?: Search): Observable<PagedListResult<GoalText>> => {
        return this.post<PagedListResult<GoalText>>('/api/goals/goal-text-list', options, {headers: {'X-Skip-Interceptor':'1'}});
    }

    changeGoalPeriodState(id: number, reviewPeriodId: number, employeeId: number, isClosed: boolean): Observable<GoalPeriodChangeState> {
        const data = {id, reviewPeriodId, employeeId, isClosed} as GoalPeriodChangeState;
        return this.post<GoalPeriodChangeState>('/api/goals/change-goal-period-state/', data);
    }
    
    insertGoal(data: InsertGoal): Observable<Goal> {
        return this.put('/api/goals/goal/', data);
    }
    
    updateGoal(goal: Goal): Observable<any> {
        return this.post('/api/goals/goal', goal);
    }
    
    insertAction(goalId: number, action: Action): Observable<Action> {
        return this.put('/api/goals/action/' + goalId.toString(), action);
    }
    
    updateAction(action: Action): Observable<any> {
        return this.post('/api/goals/action', action);
    }
    
    deleteGoal(id): Observable<any> {
        return this.delete('/api/goals/goal/' + id);
    }
    
    deleteAction(id): Observable<any> {
        return this.delete('/api/goals/action/' + id);
    }
    
    updateComment(goalPeriodId: number, reviewPeriodId: number, employeeId: number, comment: string): Observable<any> {
        const data = { goalPeriodId, reviewPeriodId, employeeId, comment } as IGoalPeriodComment;
        return this.post('/api/goals/comment', data);
    }    
}
