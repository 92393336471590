<div class="dashboard-indicator-group">

    <div class="row system-indicators" *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Rendszer: Felhasználók</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>1670</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Rendszer: Online felhasználó</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>8</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Rendszer - Cégek száma</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>52</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Rendszer - Kitöltések ma</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>17</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="row company-indicators">
        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Cég - Felhasználók</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>129</p>
                </mat-card-content>
            </mat-card>
        </div>
        
        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Cég - Online felhasználók</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>1</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>&nbsp;</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>&nbsp;</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Cég - Kitöltések ma</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>3</p>
                </mat-card-content>
            </mat-card>
        </div>

    </div>

    <div class="row rp-indicators">
        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Időszak - Felhasználók</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>28</p>
                </mat-card-content>
            </mat-card>
        </div>
        
        <div class="col-xl-3">
            <mat-card class="card-light chart-panel">
                <!-- <mat-card-header>
                    <mat-card-subtitle>Időszak - Kitöltés állapota</mat-card-subtitle>
                </mat-card-header> -->
                <mat-card-content>
                    <ngx-gauge [type]="gaugeType"
                                [value]="gaugeValue" 
                                [label]="gaugeLabel"
                                [size]="gaugeSize"   
                                foregroundColor="#17A5E3"
                                [ngClass]="{'gauge-m': gaugeSize >= 160}"
                                [append]="gaugeAppendText">
                    </ngx-gauge>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Időszak - Kitöltött / hiányzó</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>15 / 7</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-xl-3">
            <mat-card class="card-light">
                <mat-card-header>
                    <mat-card-subtitle>Időszak - Kitöltések ma</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>3</p>
                </mat-card-content>
            </mat-card>
        </div>

    </div>

    


</div>
