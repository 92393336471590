import { formatDate } from "@angular/common";
import { ReviewPeriod } from "src/app/models/review-period.model";
import { AssessmentTypeEnum } from "../enums/assessment-type.enum";
import { ReviewPeriodStatusEnum } from "../enums/review-period-status.enum";
import { ReviewTypeEnum } from "../enums/review-type.enum";
import { IdName } from "../models/general/id-name.model";
import { ReviewType } from "../models/review-type.model";
import { SettingsState } from "../store/settings.state";
import { State } from "../models/state.model";
import { DateHelper } from "../shared/helpers";
import { Temporal } from "@js-temporal/polyfill";

export abstract class ReviewPeriodHelper {

    public static reviewTypes: ReviewType[] = [
      {id: ReviewTypeEnum.TER, name: 'Panoráma és Aura'},
      {id: ReviewTypeEnum.PersonalityTest, name: 'Sziluett'},
      {id: ReviewTypeEnum.MilioStressMap, name: 'Miliő - Stressztérkép'},   
      {id: ReviewTypeEnum.MilioTeamDysfunction, name: 'Miliő - Működési térkép'},   //diszfunkciók, működési zavarok
    ];

    public static reviewPeriodStatusList: ReviewPeriodStatus[] = [
      {id: ReviewPeriodStatusEnum.New, name: 'Előkészítés alatt'},
      {id: ReviewPeriodStatusEnum.Open, name: 'Nyitott'},
      {id: ReviewPeriodStatusEnum.Closed, name: 'Lezárt'},
    ];

    public static assessmentTypeList: AssessmentType[] = [
      {id: 0, name: '-- autodetect --'},
      {id: AssessmentTypeEnum.Self, name: 'Önértékelés'},
      {id: AssessmentTypeEnum.Leader, name: 'Főnök értékeli a beosztottat'},
      {id: AssessmentTypeEnum.Peer, name: 'Peer értékelés'},
      {id: AssessmentTypeEnum.Subordinate, name: 'Beosztott értékeli a vezetőt'},
    ];

    public static assessmentEmployeeTypeList: AssessmentType[] = [      
      {id: AssessmentTypeEnum.Self, name: 'Önértékelés'},
      {id: AssessmentTypeEnum.Leader, name: 'Beosztott'},
      {id: AssessmentTypeEnum.Peer, name: 'Peer'},
      {id: AssessmentTypeEnum.Subordinate, name: 'Vezető'},
    ];
    
    public static getCurrentReviewPeriod(list: ReviewPeriod[]): ReviewPeriod {
        let today = Temporal.Now.plainDateISO();
        
        let result = list.filter(x => {
            return DateHelper.isPlainDateBetween(today, x.startDate, x.endDate);
        });

        return result.length > 0 ? result[0] : list[0];
    }

    public static isBlockEditable(settingState: SettingsState, state: State): boolean {
      const item = settingState.reviewPeriod.value;
      const items = settingState.reviewPeriodList.value;
  
      if (!state.isSuperAdmin && !state.isAdmin) return false;
  
      if (!item || items.length === 0) return false;
      
      if (item.status == ReviewPeriodStatusEnum.Closed)
        return false;
      
      return true;
    }

    public static getName(rpType: ReviewTypeEnum): string {
        const item = ReviewPeriodHelper.reviewTypes.find(x => x.id === rpType as number);
        return item.name;
    }

    public static getAssessmentEmployeeTypeName(assessmentType: AssessmentTypeEnum): string {
      const item = ReviewPeriodHelper.assessmentEmployeeTypeList.find(x => x.id === assessmentType as number);
      return item.name;
    }

    public static getStatusName(status: ReviewPeriodStatusEnum): string {
      const item = ReviewPeriodHelper.reviewPeriodStatusList.find(x => x.id === status as number);
      return item.name;
    }

    public static getDisplayText(rp: ReviewPeriod): string {
        if (!rp) return '';
        if (!rp.id) return rp.name;

        const typeName = ReviewPeriodHelper.getName(rp.reviewType);
        const rpName = rp.name ? rp.name : '';

        return `${typeName} - ${rpName}`;
  }

  public static getDateRange(rp: ReviewPeriod): string {
    if (!rp || !rp.startDate || !rp.endDate) return '';
    return `${DateHelper.formatPlainDateShort(rp.startDate)} - ${DateHelper.formatPlainDateShort(rp.endDate)}`;
  }

  public static getReviewTypes(): ReviewType[] {
      return ReviewPeriodHelper.reviewTypes;
  }

  public static getReviewPeriodStatusList(): ReviewPeriodStatus[] {
    return ReviewPeriodHelper.reviewPeriodStatusList;
  }

  public static getAssessmentTypeList(): AssessmentType[] {
    return ReviewPeriodHelper.assessmentTypeList;
  }

  public static getListTER(allReviews: ReviewPeriod[]): ReviewPeriod[] {
      return allReviews.filter(x => x.reviewType == ReviewTypeEnum.TER);
  }

  public static getLastTER(allReviews: ReviewPeriod[]): ReviewPeriod {
    const items = ReviewPeriodHelper.getListTER(allReviews);
    return items[items.length - 1];
  }

  public static isSziluett(reviewType: ReviewTypeEnum): boolean {
    return reviewType == ReviewTypeEnum.PersonalityTest;
  }

  public static isRpSziluett(rp: ReviewPeriod): boolean {
    return this.isSziluett(rp.reviewType);
  }

  public static isMilio(reviewType: ReviewTypeEnum): boolean {
      return reviewType == ReviewTypeEnum.MilioStressMap || reviewType == ReviewTypeEnum.MilioTeamDysfunction;
  }

  public static isPanoramaAuraGoals(reviewType: ReviewTypeEnum): boolean {
      return reviewType == ReviewTypeEnum.TER;
  }

  public static isRpOpen(rp: ReviewPeriod): boolean {
    return rp?.status == ReviewPeriodStatusEnum.Open;
  }

  public static isRpNew(rp: ReviewPeriod): boolean {
    return rp?.status == ReviewPeriodStatusEnum.New;
  }

  public static isRpClosed(rp: ReviewPeriod): boolean {
    return rp?.status == ReviewPeriodStatusEnum.Closed;
  }

}

export interface ReviewPeriodStatus extends IdName {
}

export interface AssessmentType extends IdName {

}