<ul class="chart-legend" *ngIf="!isChartLegend" [ngClass]="{'line3': data.length >= 3}">
    <ng-container *ngFor="let legendItem of data; index as i">
        <li class="legend-item">
            <div class="legend-text">
                <div class="legend-title">
                    {{ legendItem.person.name }}
                </div>
                <div class="legend-subtitle">
                    {{ legendItem.person.email }}
                </div>
                <div class="legend-subtitle" *ngIf="legendItem.reviewDate">
                    Kitöltés dátuma: {{ legendItem.reviewDate | date:'yyyy.MM.dd' }}
                </div>
    
            </div>
            <div *ngIf="showIcon" class="legend-icon" [ngStyle]="{'color': legendItem.colorOptions.backgroundColor, 'backgroundColor': legendItem.colorOptions.backgroundColor}"></div>
        </li>
        <!-- <li class="separator" *ngIf="(i+1) < legend.length"> - </li> -->
    </ng-container>
</ul>

<ul class="chart-legend" *ngIf="isChartLegend">
    <ng-container *ngFor="let legendItem of data; index as i">
        <li class="legend-item">
            <div class="legend-text">
                <div class="legend-title">
                    {{ legendItem.mainLine }}
                </div>
                <div class="legend-subtitle" *ngIf="legendItem.subLine">
                    {{ legendItem.subLine }}
                </div>
                <div class="legend-subtitle" *ngIf="legendItem.useRpDateRange">
                    {{ legendItem.startDate | datePlain:'yyyy.MM.dd' }} - {{ legendItem.endDate | datePlain:'yyyy.MM.dd' }}
                </div>
    
            </div>
            <div *ngIf="showIcon" class="legend-icon" [ngStyle]="{'color': legendItem.colorOptions.backgroundColor, 'backgroundColor': legendItem.colorOptions.backgroundColor}"></div>
        </li>
        <!-- <li class="separator" *ngIf="(i+1) < legend.length"> - </li> -->
    </ng-container>
</ul>