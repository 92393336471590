import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReviewPeriodStatusEnum } from 'src/app/enums/review-period-status.enum';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { IResult, Result } from 'src/app/models/general/result.model';
import { AssessmentActions } from 'src/app/models/other/assessment-actions.model';
import { QuestionModule } from 'src/app/models/question-module.model';
import { ReviewPeriodForEdit } from 'src/app/models/review-period-for-edit.model';
import { ReviewPeriodPositionQuestionSet } from 'src/app/models/review-period-position-questionset.model';
import { ReviewPeriodQuestionSettings, ReviewPeriodQuestionSettingsModel } from 'src/app/models/review-period-question-settings.model';
import { ReviewPeriod, ReviewPeriodModel } from 'src/app/models/review-period.model';
import { ReviewType } from 'src/app/models/review-type.model';
import { ReviewPeriodService } from 'src/app/services';
import { CustomValidators } from 'src/app/shared/helpers/custom-validators';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { ErrorMessageService } from 'src/app/shared/services';
import { SettingsState } from 'src/app/store/settings.state';

@UntilDestroy()
@Component({
  selector: 'app-review-period-edit',
  templateUrl: './review-period-edit.component.html',
  styleUrls: ['./review-period-edit.component.scss']
})
export class ReviewPeriodEditComponent implements OnInit {  

  @Output()
  public closeEdit = new EventEmitter();

  @Output()
  public saved = new EventEmitter<number>();

  public readonly reviewTypes = ReviewPeriodHelper.getReviewTypes();
  public readonly reviewTypesEnum = ReviewTypeEnum;

  @Input()
  public selectedReviewPeriod: ReviewPeriod;

  public prevReviewPeriodList: ReviewPeriod[] = [];

  public questionSetList: ReviewPeriodPositionQuestionSet[] = [];

  public assessmentActions: AssessmentActions;

  // Kérdőívek
  public get questionSets(): FormArray {
    return this.editForm.get('questionSets') as FormArray;
  }

  // Kérdőív beállítások
  public get questionSettingsArray(): FormArray {
    return this.editForm.get('questionSettings') as FormArray;
  } 
  
  public editForm: UntypedFormGroup = null;
  //public addForm: FormGroup = null;

  public addMode = false;
  private isReviewExists = true;
  
  public reviewType: ReviewType;

  public questionModules: QuestionModule[];

  public statusList = ReviewPeriodHelper.getReviewPeriodStatusList();

  public get allowSettingsEdit(): boolean {
    return this.addMode || (this.isLatestReviewPeriodSelected && !this.isReviewExists);
  }

  public get isLatestReviewPeriodSelected(): boolean {
    if (!this.selectedReviewPeriod || !this.settingsState.reviewPeriodList.value) return false;
    if (this.selectedReviewPeriod.reviewType != ReviewTypeEnum.TER) return true;

    const lastInState = ReviewPeriodHelper.getLastTER(this.settingsState.reviewPeriodList.value);
    return lastInState.id === this.settingsState.reviewPeriod.value.id;
  }

  public get isAllowCheckboxGroup(): boolean {
    let rt: number = this.editForm.get('reviewType').value;
    return rt != null && rt == ReviewTypeEnum.TER;
  }
  
  public getDisplayText = ReviewPeriodHelper.getDisplayText;

  constructor(
    private fb: UntypedFormBuilder,     
    private reviewPeriodService: ReviewPeriodService,
    private msgService: ErrorMessageService,
    private settingsState: SettingsState,
    private cd: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.editForm = this.createEditForm();
    
  }

  public loadData(reviewPeriod: ReviewPeriod) {    
    this.addMode = !reviewPeriod.id;
            
    this.reviewPeriodService.getForEdit(reviewPeriod.id)
    .pipe(untilDestroyed(this))
    .subscribe(data => {
      const emptyRow = new ReviewPeriodModel();
      emptyRow.id = null;
      emptyRow.name = '--válasszon--';

      this.prevReviewPeriodList = [emptyRow, ...data.reviewPeriodsToContinue.filter(x => x.id != reviewPeriod.id)];

      //this.editForm.patchValue(data.reviewPeriod);

      if (this.addMode)
      {
        this.reviewType = this.reviewTypes[0];
        this.assessmentActions = AssessmentHelper.getAssessmentActions(reviewPeriod.reviewType);
        this.editForm.patchValue(reviewPeriod);
      }           
      else
      {
        this.assessmentActions = AssessmentHelper.getAssessmentActions(data.reviewPeriod.reviewType);
        this.editForm.patchValue(data.reviewPeriod);
      }

      this.questionModules = data.questionModules;
      
      // kérdőívek lista
      this.questionSetList = this.setupQuestionSetsList(data);
      this.patchQuestionSets();
      
      // kérdőív modulok
      this.patchModuleSettings(data.reviewPeriod?.questionSettings ?? []);

      this.setControlState();
  
      if (this.isLatestReviewPeriodSelected && data.reviewPeriod && data.reviewPeriod.id) {
        this.isReviewExists = data.isReviewExist;
      }

      this.cd.detectChanges();      
    });

  }

  public toggleModuleSettings(item: ReviewPeriodPositionQuestionSet) {

    if (item.itemOrder == 51) //Panoráma
    {
      this.questionSets.controls.forEach(element => {
        const control = element as FormControl;
        
        const itemData = element.value as ReviewPeriodPositionQuestionSet;        

        if (itemData.itemOrder >= 100)
        {
          itemData.isSelected = item.isSelected;
          element.setValue(itemData);

          if (itemData.isSelected)
          {
            this.addModuleSettings(itemData);
          } 
          else
          {
            this.removeModuleSettings(itemData);
          }
        }
      });

    }
    else
    {
      if (item.isSelected)
      {
        this.addModuleSettings(item);
      } 
      else
      {
        this.removeModuleSettings(item);
      }
    }    
  }

  public isSubQuestionSet(item: ReviewPeriodPositionQuestionSet): boolean {    
    return item?.itemOrder >= 100;
  }

  public getQuestionSetName(item: ReviewPeriodPositionQuestionSet): string {
    return item?.questionSetName;
  }

  public getQuestionSetDesc(item: ReviewPeriodPositionQuestionSet): string {
    return item?.questionSetDescription;
  }
  
  private addModuleSettings(item: ReviewPeriodPositionQuestionSet) {
    // item: kérdőív

    // kérdőív modulok meghatározása kérdőív azonosító alapján
    const modules = this.questionModules.filter(x => x.questionSetId == item.questionSetId);
    
    modules.forEach(element => {
      
      const item = this.questionSettingsArray.controls.find(x => x.get('questionId').value == element.id);

      if (!item)
      {
        const settings = new ReviewPeriodQuestionSettingsModel();
        settings.questionName = element.name;
        settings.questionId = element.id;   //module id azaz egy question id
    
        this.questionSettingsArray.push(this.patchSettings(settings));
      }

    });
    
  }

  public reviewTypeChanged() {
    this.assessmentActions = AssessmentHelper.getAssessmentActions(this.editForm.get('reviewType').value);
    this.patchQuestionSets();
    this.cd.detectChanges();
  }

  private removeModuleSettings(item: ReviewPeriodPositionQuestionSet) {    

    const allQuestionSets = this.questionSets.controls.map(x => x.value as ReviewPeriodPositionQuestionSet);
    const selectedQuestionSets = allQuestionSets.filter(x => x.isSelected);
    const selectedQuestionSetIdList = selectedQuestionSets.map(x => x.questionSetId);
    const selectedQuestionSetModules = this.questionModules.filter(x => selectedQuestionSetIdList.includes(x.questionSetId));

    const currentQuestionSetModules = this.questionModules.filter(x => x.questionSetId == item.questionSetId);
    
    currentQuestionSetModules.forEach(element => {
      const moduleInUse = selectedQuestionSetModules.find(x => x.id == element.id);

      if (!moduleInUse)  //ha a többi kijelölt kérdőívhez tartozó modulok között nincs ott akkor törölhető
      {
        const index = this.questionSettingsArray.controls.findIndex(x => x.get('questionId').value == element.id);
        this.questionSettingsArray.removeAt(index);
      }
    });

    //const modulesOfSelectedQuestionSets = this.questionModules.filter(x => x.i questionSetId == item.questionSetId);

    // const itemsWithSameQuestionId = values.filter(x => x. questiontId == item.questionSetId && x.isSelected);

    // if (itemsWithSameQuestionSetId.length > 0) return;

    // const index = this.questionSettingsArray.controls.findIndex(x => x.get('questionId').value == item.id);
    // this.questionSettingsArray.removeAt(index);
  }
  
  private patchModuleSettings(settings: ReviewPeriodQuestionSettings[]) {
    const settingsArray = <FormArray>this.editForm.get('questionSettings');
    settingsArray.clear();

    settings.forEach(item => {
      settingsArray.push(this.patchSettings(item));
    });
  }

  private patchQuestionSet(qSet: ReviewPeriodPositionQuestionSet)
  {
    return this.fb.group({
      'id': qSet.id,
      'positionId': qSet.positionId,
      'questionSetId': qSet.questionSetId,
      'questionSetName': qSet.questionSetName,
      'questionSetDescription': qSet.questionSetDescription,
      'isSelected': qSet.isSelected,
      'itemOrder': qSet.itemOrder,
      'reviewType': qSet.reviewType,
      'positionName': qSet.positionName
    });
  }

  private patchQuestionSets() {
    const list = <FormArray>this.editForm.get('questionSets');
    list.clear();

    const reviewType = this.editForm.get('reviewType').value;

    this.questionSetList.forEach(item => {
      if (item.reviewType == reviewType)
      {
        list.push(this.patchQuestionSet(item));
      }
    });
  }

  private setupQuestionSetsList(data: ReviewPeriodForEdit): ReviewPeriodPositionQuestionSet[] {    
    const selectedQuestionSetsIdList = data.reviewPeriod?.selectedQuestionSets.map(x => x.questionSetId) ?? [];

    let questionSetsToDisplay = data.questionSets.filter(x => x.reviewType != ReviewTypeEnum.TER);
    
    let panoramaQuestionSets = data.questionSets.filter(x => x.reviewType == ReviewTypeEnum.TER).sort((a,b) => a.questionSetName.localeCompare(b.questionSetName));

    if (panoramaQuestionSets.length > 1)
    {
      let isPanoramaSelected = panoramaQuestionSets.some(x => x.reviewType === ReviewTypeEnum.TER);
      questionSetsToDisplay.push({id: -20, positionName: '', questionSetId: -51, questionSetName: 'Panoráma kérdőívek', reviewType: ReviewTypeEnum.TER, itemOrder: 51, isSelected: false });
    }

    let index = 100;

    panoramaQuestionSets = panoramaQuestionSets.map(item => {
      if (!item.itemOrder)
      {
        if (item.questionSetName == 'Aura')
          item.itemOrder = 50;
        else
          item.itemOrder = index;

          index += 1;
      }
      return item;
    });
    
    questionSetsToDisplay = questionSetsToDisplay.concat(panoramaQuestionSets);
    
    questionSetsToDisplay = questionSetsToDisplay.map(item => {
      
        if (!data.reviewPeriod?.selectedQuestionSets)
        {
          item.isSelected = false;
        }
        else
        {
          if (item.id != -20)
          {
            item.isSelected = selectedQuestionSetsIdList.includes(item.questionSetId); 
          }
          else
          {
            item.isSelected = data.reviewPeriod?.selectedQuestionSets.some(x => x.reviewType === ReviewTypeEnum.TER && x.questionSetName != 'Aura');
          }
        }

        return item;
    });

    questionSetsToDisplay = questionSetsToDisplay.sort((a, b) => a.itemOrder - b.itemOrder);

    return questionSetsToDisplay;
  }

  private setControlState() {
    const reviewControl = this.editForm.get('reviewType');
    if (this.addMode)
      reviewControl.enable();
    else
      reviewControl.disable();

    const prevReviewPeriodControl = this.editForm.get('previousId');
    if (this.addMode)
      prevReviewPeriodControl.enable();
    else
      prevReviewPeriodControl.disable();
  }

  public saveAdd() {
    if (this.editForm.invalid) return;

    const model = this.editForm.value;
    // data.startDate = DateHelper.setTimeZoneDiff(data.startDate);
    // data.endDate = DateHelper.setTimeZoneDiff(data.endDate);
    model.selectedQuestionSets = (model.questionSets as ReviewPeriodPositionQuestionSet[]).filter(x => x.isSelected && x.id != -20);

    this.reviewPeriodService.add(model as ReviewPeriod).subscribe(
      { next:(result) => {
        this.msgService.showSnackBarMessage('reviewPeriodCreated');
        this.settingsState.reviewPeriod.value = result.data;
        this.saved.emit(result.data.id);
        this.closeEditMode();
      }, error:(err: IResult<ReviewPeriod, Result<ReviewPeriod>>) => {
        this.msgService.handleErrorWithData<ReviewPeriod, Result<ReviewPeriod>>(err);
     }}
    );
  }

  public saveEdit() {
    let model = {...this.selectedReviewPeriod};
    model = {...model, ...this.editForm.getRawValue()};

    // if (model.startDate)
    //   model.startDate = DateHelper.getDateWithoutTimezone(model.startDate);

    // if (model.endDate)
    //   model.endDate = DateHelper.getDateWithoutTimezone(model.endDate);

    model.selectedQuestionSets = ((model as any).questionSets as ReviewPeriodPositionQuestionSet[]).filter(x => x.isSelected && x.id != -20);

    this.reviewPeriodService.update(model).subscribe({ next: (result) => {
      this.settingsState.reviewPeriod.value = model;
      this.msgService.showSnackBarMessage('saveSuccessful');
      this.saved.emit(model.id);
      this.closeEditMode();
    }, error: (err) => {
      this.msgService.showErrorResponse(err);
    }});
  }

  public save() {
    if (this.addMode)
      this.saveAdd();
    else
      this.saveEdit();
  }

  public delete(rp: ReviewPeriod) {

  }

  public closeEditMode() {
    this.closeEdit.emit();
  }

  public cancel() {
    const f = this.editForm.value;

    this.closeEditMode();
  }
  
  private createAddForm(): UntypedFormGroup {
    return this.fb.group({
      'startDate': ['', [Validators.required]],
      'endDate': ['', [Validators.required]],
    })
  }
    
  private createEditForm(): UntypedFormGroup {
    return this.fb.group({
      'id': 0,
      'reviewType': [{value: 1}, [Validators.required]],
      'startDate': null,
      'endDate' : null,
      'name': ['', [Validators.required]],
      'description': '',
      'instructions': '',
      'questionSettings': this.fb.array([]), 
      'isFreeTextAssessmentAllowed': false,
      'isPositionBasedAssessment': false,
      'isSpecialQuestionSetBasedAssessment': false,
      'updateRpe': false,
      
      'copyEmployees': false,
      'status': ReviewPeriodStatusEnum.New,
      'previousId': null,
      'questionSets': this.fb.array([])
    });
  }

  patchSettings(settings: ReviewPeriodQuestionSettings)
  {
    return this.fb.group({
      'id': settings.id,
      'reviewPeriodId': settings.reviewPeriodId,
      'questionId': settings.questionId,
      'questionName': settings.questionName,
      'isSelfAssessmentAllowed': settings.isSelfAssessmentAllowed,
      'isLeaderAssessmentAllowed': settings.isLeaderAssessmentAllowed,
      'isPeerAssessmentAllowed': settings.isPeerAssessmentAllowed,
      'isSpecialQuestion': settings.isSpecialQuestion
    });
  }

  public get isQuestionSetSelected(): boolean {
    const list = this.editForm.get('questionSets').value as ReviewPeriodPositionQuestionSet[];
    return list.some(x => x.isSelected);
  }

  public removeHtml(html: string) {
    if (!html) return '';
      return html.replace(/<[^>]*>/g, '');
  }
  

  public addItem() {        
    this.addMode = false;
  }

  public cancelAddItem() {
    this.addMode = false;
  }

}
