<div class="container main-container">
    
    <div>
        <mat-card class="card-light">
            <mat-card-header>
                <mat-card-title>Cégek listája</mat-card-title>
            </mat-card-header>
            <mat-card-content class="responsive-size">
                
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Kereső</mat-label>
                    <input matInput type="text" [formControl]="searchControl" autocomplete="off">
                    <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Törlés" (click)="clearText()">
                    <mat-icon>close</mat-icon>
                    </button>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field> 

                <div class="message-panel">
                {{ searchMessage }}
                </div>

                <ng-container *ngIf="items$ | async as items">
                    <table mat-table [dataSource]="items" class="table-default table-hover hover-pointer" multiTemplateDataRows>
                                                            
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Megnevezés</th>
                            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                        </ng-container>                      
                        
                        <ng-container matColumnDef="employeeCount">
                            <th mat-header-cell *matHeaderCellDef>Dolgozók</th>
                            <td mat-cell *matCellDef="let element">{{ showEmployeeCount(element.employeeCount) }}</td>
                        </ng-container>
    
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="actions-col padding-left-4" [hidden]="!showAdminFunctions"></th>
                            <td mat-cell *matCellDef="let element" class="padding-left-4" [hidden]="!showAdminFunctions">
                                <button mat-icon-button class="icon-bt-md" (click)="$event.stopPropagation(); editCompany(element)">
                                    <mat-icon class="normal no-margin" title="Cég szerkesztése">edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="masterTableColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: masterTableColumns;" 
                            class="element-row"                         
                            [ngClass]="{'selected': element.isSelected }"
                            (click)="onMasterRowClick(element)"
                            ></tr>
                    </table>
                </ng-container>
    
            </mat-card-content>
        </mat-card>

        <div class="action-buttons mobile-page-bottom" *ngIf="showAdminFunctions">
            <button mat-flat-button color="primary" (click)="createCompany()">
                <mat-icon>add</mat-icon>
                Cég
            </button>
        </div>

        
    </div>

</div>
