import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ComponentState } from '../../enum/component-state.enum';
import { ActionStateModel } from '../../models/action-state.model';
import { ListItem } from '../../models/list-item.model';

@Component({
  selector: 'app-dynamic-list-item',
  templateUrl: './dynamic-list-item.component.html',
  styleUrls: ['./dynamic-list-item.component.scss']
})
export class DynamicListItemComponent<T> implements OnInit {

  public componentStates = ComponentState;

  @Input() public actionState: ActionStateModel;
  @Input() public index: number;
  @Input() public source: ListItem<T>;
  @Input() public noEdit = false;
  @Input() public noDelete = false;
  @Input() public noActions = false;
  
  @Input() public disableActions = false;
  @Input() public disableActionsMessage = '';

  @Input() public inlineEdit = true;
  @Input() public hasContextMenu = false;
  
  @HostBinding('class.border-bottom') 
  @Input() public showBottomBorder = false;
  
  @Output() public ok = new EventEmitter<DynamicListItemComponent<T>>();
  @Output() public cancel = new EventEmitter();
  @Output() public edit = new EventEmitter();
  @Output() public delete = new EventEmitter();
  
  public get allowAction(): boolean {
    return (this.actionState.currentItemIndex === null || this.actionState.currentItemIndex === this.index) && this.source.state != ComponentState.Disabled;
  }
  
  constructor() { }

  ngOnInit(): void {
    
  }

  public onOk() {    
    this.ok.emit(this);
  }

  public onCancel() {        
    this.cancel.emit();
    this.close();
  }

  public onEdit() {    
    if (this.inlineEdit)
    {
      this.source.state = ComponentState.Edit;
      this.actionState.currentItemIndex = this.index;
    }
    else
      this.source.state = ComponentState.View;
    
    this.edit.emit();
  }
  
  public onDelete() {
    this.delete.emit();
  }

  public close() {
    this.source.state = ComponentState.View;
    this.actionState.currentItemIndex = null;
  }
}
