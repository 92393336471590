import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartConfiguration, TooltipItem } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BarChartDataSource } from 'src/app/models/general/bar-chart-datasource.model';
import { RadarChartDataSource } from 'src/app/models/general/radar-chart-datasource.model';
import { PersonLegend } from 'src/app/models/report/person-legend.model';
import { PersonalityTestReport } from 'src/app/models/report/personality-test-report.model';
import { QuestionCategoryResultsForPerson } from 'src/app/models/report/question-category-results-for-person.model';
import { ReportOptions } from 'src/app/models/report/report-options.model';
import { ReportService } from 'src/app/services';
import { TextGuideService } from 'src/app/services/text-guide.service';
import { TextHelper } from 'src/app/shared/helpers/text.helper';
import { ViewHelper } from 'src/app/shared/helpers/view.helper';
import { EmployeeLegendManager } from './legend-handler.code';
import { IdName } from 'src/app/models/general/id-name.model';

@UntilDestroy()
@Component({
  selector: 'app-personality-test-diagram',
  templateUrl: './personality-test-diagram.component.html',
  styleUrls: ['./personality-test-diagram.component.scss']
})
export class PersonalityTestDiagramComponent implements OnInit {

  public radar1ds: RadarChartDataSource = new RadarChartDataSource(); // Motiváció és problémamegoldás
  public radar2ds: RadarChartDataSource = new RadarChartDataSource(); // Viselkedés
  public bar1ds: BarChartDataSource = new BarChartDataSource(); // Kommunikáció
  public bar2ds: BarChartDataSource = new BarChartDataSource(); // Konfliktuskezelés

  public legend: PersonLegend[] = [];


  public personCount = 0;
  public reportOptions: ReportOptions;

  public reportData: PersonalityTestReport;
  public employeeManager: EmployeeLegendManager;

  public radar1Labels: string[] = [];

  public get hiddenRows(): number[] {
    let rows = this.employeeManager?.getList.map(x => x.rpeId) ?? [];
    return rows;
  }

  @ViewChild('chart1', {read: BaseChartDirective}) radarChart1: BaseChartDirective;
  @ViewChild('chart2', {read: BaseChartDirective}) chart2Canvas: BaseChartDirective;
  @ViewChild('barChart1', {read: BaseChartDirective}) barChart1Canvas: BaseChartDirective;
  @ViewChild('barChart2', {read: BaseChartDirective}) barChart2Canvas: BaseChartDirective;
  
  public radarChartOptions: ChartConfiguration<'radar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    layout: {
      padding: 10
    },
    scales: {      
      r: {        
        pointLabels: {
          borderRadius: 16,
          backdropPadding: 10,
          padding: 14,
          backdropColor: '#edebf5',
          color: '#161041',          
          font: (ctx) => {
            
            return {
              size: ctx.chart.width < 500 ? 9 : 11,
              weight: 'bold',
              family: 'Work Sans',            
            };
          },          
        },
        ticks: {
          display: true
        },
        suggestedMin: 0,
        suggestedMax: 8,
      },            
    },
    elements: {
      point: {
        radius: 2,        
      }
    },
    plugins: {
      
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          footer: (tooltipItems) => {
            return this.footerContent(tooltipItems, 55);
          }
        }
      }
    },
  };

  public radarChartOptionsSmall: ChartConfiguration<'radar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    scales: {
      r: {
        pointLabels: {
          borderRadius: 14,
          backdropPadding: 8,
          padding: 14,
          backdropColor: '#edebf5',
          color: '#161041',
          font: (ctx) => {            
            return {
              size: ctx.chart.width < 500 ? 9 : 10,
              weight: 'bold',
              family: 'Work Sans',            
            };
          },  
        },
        ticks: {
          display: true
        },
        suggestedMin: 0,
        suggestedMax: 8,
      },
    },
    elements: {
      point: {
        radius: 2
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          footer: (tooltipItems) => {
            return this.footerContent(tooltipItems, 55);
          }
        }
      }
    },
  };

  public barChartOptions1: ChartConfiguration<'bar'>['options'] = {
    layout: {
      padding: 15,
    },
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    scales: {
      y: {
        ticks: {
          color: '#383838',
          font: {
            size: 10,
            family: 'Work Sans'
          },
        },
        grid: {
          display: false,
        }
      },
      x : {
        ticks: {
          color: '#383838',
          stepSize: 1,
          autoSkip: false,
        },
        suggestedMin: 0,
        max: 8,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Kommunikációs stílus',
        align: 'start',
        font: {
          size: 12,
          weight: 'normal',
          family: 'Work Sans'
        },
        color: '#000000',
      },
      tooltip: {
        footerFont: {
          size: 9.5
        },
        callbacks: {
          footer: (tooltipItems) => {
            return this.footerContent(tooltipItems, 62);
          }
        }
      }
    }
  };

  public barChartOptions2: ChartConfiguration<'bar'>['options'] = {
    layout: {
      padding: 15,
    },
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    scales: {
      y: {
        ticks: {
          color: '#383838',
          font: {
            size: 10,
            family: 'Work Sans'
          },
        },
        grid: {
          display: false,
        }
      },
      x : {
        ticks: {
          color: '#383838',
          stepSize: 1,
          autoSkip: false,
        },
        suggestedMin: 0,
        max: 8,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Konfliktuskezelési stílus',
        align: 'start',
        font: {
          size: 12,
          weight: 'normal',
          family: 'Work Sans'
        },
        color: '#000000',
      },
      tooltip: {
        footerFont: {
          size: 9.5
        },        
        callbacks: {
          footer: (tooltipItems) => {
            return this.footerContent(tooltipItems, 62);
          }
        }
      }
    }
  };



  constructor(private reportService: ReportService, private route: ActivatedRoute, private guideService: TextGuideService) {

    this.radar1ds.columnCodes = ['MOT001', 'MOT002', 'MOT003', 'MOT004', 'MOT005', 'MOT006', 'MOT007', 'MOT008', 'MOT009'];
    this.radar2ds.columnCodes = ['VIS001', 'VIS002', 'VIS003', 'VIS004', 'VIS005', 'VIS006', 'VIS007'];
    this.bar1ds.columnCodes = ['COM001', 'COM002', 'COM003', 'COM004'];
    this.bar2ds.columnCodes = ['CONF005', 'CONF004', 'CONF001', 'CONF003', 'CONF002'];

    this.employeeManager = new EmployeeLegendManager();
  }


  ngOnInit(): void {

    this.route.params
    .pipe(untilDestroyed(this))
    .subscribe(params => {
        const reviewPeriodEmployeeId: number | null = parseInt(params['reviewPeriodEmployeeId']);

        this.reportOptions = this.guideService.getGuideAsReportOptions('assessment:SZILUETT');

        if (reviewPeriodEmployeeId)
        {
          this.employeeManager.add(reviewPeriodEmployeeId);
          this.loadData();
        }

        ViewHelper.scrollToTop();
    });

    ViewHelper.media('print').subscribe((matches) => {
      // if (matches)
      // {
      //   this.chart2Canvas..canvas .nativeElement.src = this.chart2Canvas.nativeElement.toDataURL('image/png');
      //   this.barChart1Canvas.nativeElement.src = this.barChart1Canvas.nativeElement.toDataURL('image/png');
      //   this.barChart2Canvas.nativeElement.src = this.barChart2Canvas.nativeElement.toDataURL('image/png');
      // }
      // else
      // {

      // }
     });


  }

  loadData() {    
    this.reportService.getPersonalityTestReport(this.employeeManager.getRpeIdList()).subscribe(data => {

      this.reportData = data;
      
      let firstPerson = true;
      this.personCount = 0;

      this.reportOptions.showCreatedByPerson = true;
      // demo copy
      //data.items.push(data.items[0]);

      this.legend = [];

      this.radar1ds.datasets = [];
      this.radar2ds.datasets = [];
      this.bar1ds.datasets = [];
      this.bar2ds.datasets = [];

      data.items.forEach(item => {

        let employeeColor = this.employeeManager.getItem(item.reviewPeriodEmployeeId);
        item.color = employeeColor.color;
        this.legend.push({ person: item.employee, colorOptions: item.color, reviewDate: item.reviewDate });

        this.addRadarDatasource(firstPerson, item, this.radar1ds);
        this.addRadarDatasource(firstPerson, item, this.radar2ds);
        this.addBarDatasource(firstPerson, item, this.bar1ds);
        this.addBarDatasource(firstPerson, item, this.bar2ds);

        firstPerson = false;
        this.personCount++;
      });

    });
  }
  

  addRadarDatasource(firstPerson: boolean, dataForPerson: QuestionCategoryResultsForPerson, datasource: RadarChartDataSource) {
      const valuesForPerson: number[] = [];
      const labels: string[] = [];

      datasource.columnCodes.forEach(code => {
        const catresult = dataForPerson.categoryResults.find(x => x.questionCategory.code === code);

        if (firstPerson)
        {
          labels.push(catresult.questionCategory.name.toLocaleLowerCase());
        }
        valuesForPerson.push(catresult.result);
      });

      datasource.datasets.push({
        data: valuesForPerson,
        label: dataForPerson.employee.name,
        ...dataForPerson.color,
        borderWidth: 2,        
        fill: true
      });

      if (firstPerson)
      {
        datasource.labels = labels;
        this.radar1Labels = labels;
      }
  }

  footerContent(tooltipItems: TooltipItem<"radar">[] | TooltipItem<"bar">[], maxLineLength: number) {
    if (tooltipItems.length > 0)
    {
      let result: string[] = [];
      const record = tooltipItems[0];

      const catResults =  this.reportData.items[record.datasetIndex].categoryResults;
      const cat = catResults.find(x => x.questionCategory.name.toLocaleLowerCase() === record.label);

      result.push('');
      result = result.concat(TextHelper.wrapStringIntoArray(cat.questionCategory.description, maxLineLength));

      if (cat.resultText) {
        result.push('');
        result = result.concat(TextHelper.wrapStringIntoArray(cat.resultText, maxLineLength));
      }

      return result;
    }
    return '';
  }

  addBarDatasource(firstPerson: boolean, dataForPerson: QuestionCategoryResultsForPerson, datasource: BarChartDataSource) {
    const valuesForPerson: number[] = [];
    const labels: string[] = [];

    datasource.columnCodes.forEach(code => {
      const catresult = dataForPerson.categoryResults.find(x => x.questionCategory.code === code);

      if (firstPerson)
      {
        labels.push(catresult.questionCategory.name.toLocaleLowerCase());
      }
      valuesForPerson.push(catresult.result);
    });

    datasource.datasets.push({
      data: valuesForPerson,
      label: dataForPerson.employee.name,
      ...dataForPerson.color,
      barThickness: 'flex',
      barPercentage: 0.5,
      categoryPercentage: 0.6,
     });

    if (firstPerson)
    {
      datasource.labels = labels;
    }
  }

  onAdd(data: IdName) {
    this.employeeManager.add(data.id);
    this.loadData();
  }

  onRemove(data: IdName) {
    this.employeeManager.remove(data.id);
    this.loadData();
  }

  @HostListener("window:beforeprint", [])
  onWindowBeforePrint() {    
    this.radarChart1.chart.resize(600,600);
    this.chart2Canvas.chart.resize(407, 396);
    //this.barChart1Canvas.chart.resize(290, 240);
    //this.barChart2Canvas.chart.resize(290, 240);    
  }

  @HostListener("window:afterprint", [])
  onWindowAfterPrint() {    
    this.radarChart1.chart.resize();
    this.chart2Canvas.chart.resize();
    //this.barChart1Canvas.chart.resize();
    //this.barChart2Canvas.chart.resize();    
  }

}
