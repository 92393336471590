import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Temporal } from '@js-temporal/polyfill';

@Pipe({
  name: 'datePlain'
})
export class PlainDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Temporal.PlainDate | null | undefined, format: string = 'mediumDate'): string | null {
    if (!value) return null;

    // Átalakítjuk a PlainDate értéket ISO stringgé, amit a DatePipe értelmezni tud
    const isoString = value.toString(); // 'YYYY-MM-DD' formátum
    
    // DatePipe használata a formázáshoz
    return this.datePipe.transform(isoString, format);
  }
}