import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReviewPeriodStatusEnum } from 'src/app/enums/review-period-status.enum';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { IResult, Result } from 'src/app/models/general/result.model';
import { AssessmentActions } from 'src/app/models/other/assessment-actions.model';
import { QuestionModule } from 'src/app/models/question-module.model';
import { ReviewPeriodPositionQuestionSet } from 'src/app/models/review-period-position-questionset.model';
import { ReviewPeriodQuestionSettings, ReviewPeriodQuestionSettingsModel } from 'src/app/models/review-period-question-settings.model';
import { ReviewPeriod, ReviewPeriodModel } from 'src/app/models/review-period.model';
import { ReviewType } from 'src/app/models/review-type.model';
import { ReviewPeriodService } from 'src/app/services';
import { CustomValidators } from 'src/app/shared/helpers/custom-validators';
import { ErrorMessageService } from 'src/app/shared/services';
import { SettingsState } from 'src/app/store/settings.state';

@UntilDestroy()
@Component({
  selector: 'app-review-period-edit',
  templateUrl: './review-period-edit.component.html',
  styleUrls: ['./review-period-edit.component.scss']
})
export class ReviewPeriodEditComponent implements OnInit {  

  @Output()
  public closeEdit = new EventEmitter();

  @Output()
  public saved = new EventEmitter<number>();

  public readonly reviewTypes = ReviewPeriodHelper.getReviewTypes();
  public readonly reviewTypesEnum = ReviewTypeEnum;

  @Input()
  public selectedReviewPeriod: ReviewPeriod;

  public prevReviewPeriodList: ReviewPeriod[] = [];

  public questionSetList: ReviewPeriodPositionQuestionSet[] = [];
  public assessmentActions: AssessmentActions;

  // Kérdőív beállítások
  public get questionSettingsArray(): FormArray {
    return this.editForm.get('questionSettings') as FormArray;
  } 
  
  public editForm: UntypedFormGroup = null;

  public addMode = false;
  private isReviewExists = true;
  
  public reviewType: ReviewType;

  public questionModules: QuestionModule[];

  public statusList = ReviewPeriodHelper.getReviewPeriodStatusList();

  public get allowSettingsEdit(): boolean {
    return this.addMode || (this.isLatestReviewPeriodSelected && !this.isReviewExists);
  }

  public get isLatestReviewPeriodSelected(): boolean {
    if (!this.selectedReviewPeriod || !this.settingsState.reviewPeriodList.value) return false;
    if (this.selectedReviewPeriod.reviewType != ReviewTypeEnum.TER) return true;

    const lastInState = ReviewPeriodHelper.getLastTER(this.settingsState.reviewPeriodList.value);
    return lastInState.id === this.settingsState.reviewPeriod.value.id;
  }

  public get selectedReviewType(): ReviewTypeEnum {
    return this.editForm.get('reviewType')?.value;
  }

  public get isPositionBasedAssessment(): boolean {
    return this.editForm.controls['isPositionBasedAssessment']?.value;
  }

  public get isAura(): boolean {
    return this.editForm.controls['isSpecialQuestionSetBasedAssessment']?.value;
  }

  public get isGoalsEnabled(): boolean {
    return this.editForm.controls['isGoalsEnabled']?.value;
  }

  public get selectedQuestionSets(): ReviewPeriodPositionQuestionSet[] {
    let resultList: ReviewPeriodPositionQuestionSet[] = [];

    if (this.isPositionBasedAssessment) {
      const selected = this.questionSetList.filter(x => x.isPositionBasedAssessment);
      resultList.push(...selected);
    }

    if (this.isAura) {
      const selected = this.questionSetList.filter(x => x.isSpecialQuestionSetBasedAssessment);
      resultList.push(...selected);
    }

    return resultList;
  }

  public get displaySelectedQuestionSets(): ReviewPeriodPositionQuestionSet[] {
    const toDisplay = this.selectedQuestionSets.filter(x => !x.isSpecialQuestionSetBasedAssessment);
    return toDisplay;
  }
  
  public getDisplayText = ReviewPeriodHelper.getDisplayText;
  public isPanorama = ReviewPeriodHelper.isPanoramaAuraGoals;

  constructor(
    private fb: UntypedFormBuilder,     
    private reviewPeriodService: ReviewPeriodService,
    private msgService: ErrorMessageService,
    private settingsState: SettingsState,
    private cd: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.editForm = this.createEditForm();
    
    this.addPanoramaAuraDateValidation(this.editForm);
  }

  private addPanoramaAuraDateValidation(form: UntypedFormGroup): void {
    const isPositionBasedAssessmentControl = form.get('isPositionBasedAssessment');
    const isSpecialQuestionSetBasedAssessmentControl = form.get('isSpecialQuestionSetBasedAssessment');
    const isGoalsEnabledControl = form.get('isGoalsEnabled');
    const panoramaAuraStartDateControl = form.get('panoramaAuraStartDate');
  
    // Figyeljük, ha a két checkbox bármelyike megváltozik
    const updateValidators = () => {
      const isPositionBased = isPositionBasedAssessmentControl?.value;
      const isSpecialQuestionSet = isSpecialQuestionSetBasedAssessmentControl?.value;
  
      if (!isPositionBased && !isSpecialQuestionSet)
      {
        form.get('panoramaAuraStartDate').setValue(null);
        form.get('panoramaAuraEndDate').setValue(null);
      }

      if (!isGoalsEnabledControl)
      {
        form.get('goalsStartDate').setValue(null);
        form.get('goalsEndDate').setValue(null);
      }

      if ((isPositionBased || isSpecialQuestionSet) && !panoramaAuraStartDateControl.hasValidator(Validators.required)) {
        panoramaAuraStartDateControl?.setValidators([Validators.required]);
      }
      
      if (!isPositionBased && !isSpecialQuestionSet && panoramaAuraStartDateControl.hasValidator(Validators.required))
      {
        panoramaAuraStartDateControl?.clearValidators();
      }
  
      // Fontos: érvényesítjük a változást
      panoramaAuraStartDateControl?.updateValueAndValidity();
    };
  
    // Mindkét mező változását figyeljük
    isPositionBasedAssessmentControl?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(updateValidators);

    isSpecialQuestionSetBasedAssessmentControl?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(updateValidators);

    isGoalsEnabledControl?.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(val => {
      this.cd.detectChanges();
    });
  }

  public loadData(reviewPeriod: ReviewPeriod) {    
    this.addMode = !reviewPeriod.id;
            
    this.reviewPeriodService.getForEdit(reviewPeriod.id)
    .pipe(untilDestroyed(this))
    .subscribe(data => {
      const emptyRow = new ReviewPeriodModel();
      emptyRow.id = null;
      emptyRow.name = '--válasszon--';

      this.prevReviewPeriodList = [emptyRow, ...data.reviewPeriodsToContinue.filter(x => x.id != reviewPeriod.id)];

      if (this.addMode) // New
      {
        this.reviewType = this.reviewTypes[0];
        this.assessmentActions = AssessmentHelper.getAssessmentActions(reviewPeriod.reviewType);
        this.editForm.patchValue(reviewPeriod);
      }           
      else  // Edit
      {
        this.assessmentActions = AssessmentHelper.getAssessmentActions(data.reviewPeriod.reviewType);
        this.editForm.patchValue(data.reviewPeriod);
      }

      this.questionModules = data.questionModules;
      
      // kérdőívek lista, az összes (Panorama, aura)
      this.questionSetList = data.questionSets.sort((a,b) => a.questionSetName.localeCompare(b.questionSetName));
      
      // kérdőív modulok
      this.patchModuleSettings(data.reviewPeriod?.questionSettings ?? []);

      this.setControlState();
  
      if (this.isLatestReviewPeriodSelected && data.reviewPeriod && data.reviewPeriod.id) {
        this.isReviewExists = data.isReviewExist;
      }

      this.cd.detectChanges();      
    });

  }

  public updateModules($event: any)
  {
    if ($event.checked) {
      this.loadDates('panoramaAuraStartDate', 'panoramaAuraEndDate');
    }

    let modules: QuestionModule[] = [];

    if (this.isPositionBasedAssessment) {
      const selected = this.questionModules.filter(x => x.isPositionBasedAssessment);
      modules.push(...selected);
    }

    if (this.isAura) {
      const selected = this.questionModules.filter(x => x.isSpecialQuestionSetBasedAssessment);
      modules.push(...selected);
    }

    // add missing modules
    modules.forEach(element => {
      
      const settingsItem = this.questionSettingsArray.controls.find(x => x.get('questionId').value == element.id);

      if (!settingsItem)
      {
        const settings = new ReviewPeriodQuestionSettingsModel();
        settings.questionName = element.name;
        settings.questionId = element.id;   //module id azaz egy question id
        settings.isSpecialQuestion = element.isSpecialQuestionSetBasedAssessment;
    
        this.questionSettingsArray.push(this.patchSettings(settings));
      }

    });

    let toDelete: number[] = [];

    // remove not used modules
    this.questionSettingsArray.controls.forEach(element => { 
      const item = element.value as ReviewPeriodQuestionSettings;
      const module = modules.find(x => x.id == item.questionId);

      if (!module) 
      {
        toDelete.push(item.questionId);
      }
    });

    toDelete.forEach(questionId => {
      const index = this.questionSettingsArray.controls.findIndex(x => x.get('questionId').value == questionId);
      this.questionSettingsArray.removeAt(index);
    });

  }

  public getQuestionSetName(item: ReviewPeriodPositionQuestionSet): string {
    return item?.questionSetName;
  }

  public getQuestionSetDesc(item: ReviewPeriodPositionQuestionSet): string {
    return item?.questionSetDescription;
  }

  public reviewTypeChanged() {
    this.resetTypeOptions();
    this.assessmentActions = AssessmentHelper.getAssessmentActions(this.editForm.get('reviewType').value);
    this.cd.detectChanges();
  }

  private resetTypeOptions() {
    this.editForm.controls['isPositionBasedAssessment'].setValue(false);
    this.editForm.controls['isSpecialQuestionSetBasedAssessment'].setValue(false);
    this.editForm.controls['isGoalsEnabled'].setValue(false);

    this.editForm.controls['panoramaAuraStartDate'].setValue(null);
    this.editForm.controls['panoramaAuraEndDate'].setValue(null);
    this.editForm.controls['goalsStartDate'].setValue(null);
    this.editForm.controls['goalsEndDate'].setValue(null);

    this.editForm.controls['isFreeTextAssessmentAllowed'].setValue(false);
    this.editForm.controls['copyEmployees'].setValue(false);
  }
  
  private patchModuleSettings(settings: ReviewPeriodQuestionSettings[]) {
    const settingsArray = <FormArray>this.editForm.get('questionSettings');
    settingsArray.clear();

    settings.forEach(item => {
      settingsArray.push(this.patchSettings(item));
    });
  }

  private setControlState() {
    const reviewControl = this.editForm.get('reviewType');
    if (this.addMode)
      reviewControl.enable();
    else
      reviewControl.disable();

    const prevReviewPeriodControl = this.editForm.get('previousId');
    if (this.addMode)
      prevReviewPeriodControl.enable();
    else
      prevReviewPeriodControl.disable();
  }

  public saveAdd() {
    if (this.editForm.invalid) return;

    const model = this.editForm.value as ReviewPeriod;

    this.reviewPeriodService.add(model)
    .pipe(untilDestroyed(this))
    .subscribe({ 
      next: (result) => {
        this.msgService.showSnackBarMessage('reviewPeriodCreated');
        this.settingsState.reviewPeriod.value = result.data;
        this.saved.emit(result.data.id);
        this.closeEditMode();
      }, 
      error: (err: IResult<ReviewPeriod, Result<ReviewPeriod>>) => {
        this.msgService.handleErrorWithData<ReviewPeriod, Result<ReviewPeriod>>(err);
     }}
    );
  }

  public saveEdit() {
    let model = {...this.selectedReviewPeriod};
    model = {...model, ...this.editForm.getRawValue()};

    this.reviewPeriodService.update(model)
    .pipe(untilDestroyed(this))
    .subscribe({ 
      next: (result) => {
        this.settingsState.reviewPeriod.value = model;
        this.msgService.showSnackBarMessage('saveSuccessful');
        this.saved.emit(model.id);
        this.closeEditMode();
      }, 
      error: (err) => {
        this.msgService.showErrorResponse(err);
    }});
  }

  public save() {
    if (this.addMode)
      this.saveAdd();
    else
      this.saveEdit();
  }

  public closeEditMode() {
    this.closeEdit.emit();
  }

  public cancel() {
    this.closeEditMode();
  }
    
  private createEditForm(): UntypedFormGroup {
    return this.fb.group({
      'id': 0,
      'reviewType': [{value: 1}, [Validators.required]],
      'startDate': null,
      'endDate' : null,
      'name': ['', [Validators.required]],
      'questionSettings': this.fb.array([]), 
      'isFreeTextAssessmentAllowed': false,

      'isPositionBasedAssessment': false,
      'isGoalsEnabled': false,
      'isSpecialQuestionSetBasedAssessment': false,
      
      'panoramaAuraStartDate': null,
      'panoramaAuraEndDate': [null, [
                                      CustomValidators.conditionalRequiredValidator((form) => form.get('isPositionBasedAssessment')?.value === true),
                                      CustomValidators.dateIntervalShouldBeInIntervalValidator('startDate', 'endDate', 'panoramaAuraStartDate')
                                    ]],
      'goalsStartDate': [null],
      'goalsEndDate': [null, [
                              CustomValidators.conditionalRequiredValidator((form) => form.get('isGoalsEnabled')?.value === true),
                              CustomValidators.dateIntervalShouldBeInIntervalValidator('startDate', 'endDate', 'goalsStartDate')
                            ]],
      
      'updateRpe': false,
      
      'copyEmployees': false,
      'status': ReviewPeriodStatusEnum.New,
      'previousId': null
    }, {
      validators:
        CustomValidators.atLeastOneFieldTrueValidatorWithCondition(['isPositionBasedAssessment', 'isGoalsEnabled', 'isSpecialQuestionSetBasedAssessment'], (form) => form.get('reviewType')?.value === ReviewTypeEnum.TER)
    });
  }

  patchSettings(settings: ReviewPeriodQuestionSettings)
  {
    return this.fb.group({
      'id': settings.id,
      'reviewPeriodId': settings.reviewPeriodId,
      'questionId': settings.questionId,
      'questionName': settings.questionName,
      'isSelfAssessmentAllowed': settings.isSelfAssessmentAllowed,
      'isLeaderAssessmentAllowed': settings.isLeaderAssessmentAllowed,
      'isPeerAssessmentAllowed': settings.isPeerAssessmentAllowed,
      'isSpecialQuestion': settings.isSpecialQuestion
    });
  }

  public get isQuestionSetSelected(): boolean {
    return this.selectedQuestionSets.length > 0;
  }

  public removeHtml(html: string) {
    if (!html) return '';
      return html.replace(/<[^>]*>/g, '');
  }
  
  public addItem() {        
    this.addMode = false;
  }

  public cancelAddItem() {
    this.addMode = false;
  }

  public changeIsGoals($event: any) {
    if (!$event.checked) return;
    
    this.loadDates('goalsStartDate', 'goalsEndDate');
  }

  public loadDates(dateFromField: string, dateToField: string){
    this.editForm.get(dateFromField).setValue(this.editForm.get('startDate').value);
    this.editForm.get(dateToField).setValue(this.editForm.get('endDate').value);
  }
}
