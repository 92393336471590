<div class="container padding-top-lg report-container" *ngIf="reportData">

    <app-back-nav linkText="Vissza az értékelésekhez"></app-back-nav>

    <ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
        <app-multi-item-selector mode="RPEmployee" (add)="onAdd($event)" (remove)="onRemove($event)" [hiddenRows]="hiddenRows" [rpId]="reportData?.reviewPeriod?.id" class="no-print" ></app-multi-item-selector>
    </ng-container>

    <app-report-frame 
        class="header-ratio-2-1"
        [reportTypeName]="legend.length > 1 ? 'összeillés riport' : 'diagram riport'"
        [reportOptions]="reportOptions"
        [legend]="legend"
        [showLegend]="true"
        *ngIf="radar1ds.datasets">

        <!-- <div>
                <ul class="chart-legend">
                    <li *ngFor="let legendItem of legend; index as i" class="legend-item">
                        <div class="legend-icon" 
                                [ngStyle]="{'background-color': legendItem.colorOptions.backgroundColor}" 
                                [style.border]="'2px solid ' + legendItem.colorOptions.borderColor"></div>
                        <div class="legend-text">{{ legendItem.person.name }}</div>
                    </li>
                </ul>
            </div> -->
            
        <div class="content">
            <mat-card>
                <mat-card-title>
                    <div class="chart-title">Motiváció & Problémamegoldás</div>
                </mat-card-title>
                <mat-card-content class="large-chart-content">
                    <!-- 1. radar chart -->
                    <div class="chart large-chart">
                        <canvas #chart1 baseChart
                                [datasets]="radar1ds.datasets"
                                [options]="radarChartOptions"
                                [labels]="radar1ds.labels"
                                [type]="'radar'"
                                >
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
                    
            <mat-card class="panel2 break-inside-avoid">
                <mat-card-content class="small-chart-content">
                    <div class="charts2 flex-horizontal">
                        <div class="left-side">
                            <div class="chart-title">Viselkedés</div>
        
                            <!-- 2. radar chart -->
                            <div class="chart small-chart">
                                <canvas baseChart #chart2
                                        [datasets]="radar2ds.datasets"
                                        [options]="radarChartOptionsSmall"
                                        [labels]="radar2ds.labels"
                                        [type]="'radar'">
                                </canvas>
                            </div>
                        </div>
        
                        <div class="right-side">
                            <div class="chart-title2">Határozottság: <span>kiegészítő faktorok</span></div>
                            <div class="bar-chart-panel">
                                <div class="chart bar-chart">
                                    <canvas baseChart #barChart1
                                        [datasets]="bar1ds.datasets"
                                        [labels]="bar1ds.labels"
                                        [options]="barChartOptions1"
                                        [legend]="false"
                                        [type]="'bar'">
                                    </canvas>
                                </div>
                            </div>
                            <div class="bar-chart-panel">
                                <div class="chart bar-chart">
                                    <canvas baseChart #barChart2
                                        [datasets]="bar2ds.datasets"
                                        [labels]="bar2ds.labels"
                                        [options]="barChartOptions2"
                                        [legend]="false"
                                        [type]="'bar'">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>

    </app-report-frame>        

</div>

