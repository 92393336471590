import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReviewPeriodChangeStatus } from '../models/review-period-change-status.model';
import { ReviewPeriodForEdit } from '../models/review-period-for-edit.model';
import { ReviewPeriod } from '../models/review-period.model';
import { DataService } from './data.service';
import { StateService } from './state.service';
import { Result, ResultModel } from '../models/general/result.model';
import { Search } from '../models/search.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { IdName } from '../models/general/id-name.model';
import { formatDate } from '@angular/common';
import { EmployeeReviews } from '../models/employee-reviews.model';
import { Temporal } from '@js-temporal/polyfill';
import { DateHelper } from '../shared/helpers';

@Injectable({
    providedIn: 'root',    
})
export class ReviewPeriodService extends DataService {
    
    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);
    }
    
    getReviewPeriodList(userId?: string): Observable<ReviewPeriod[]> {
        const url = userId ? `/api/review-period/list/${userId}` : `/api/review-period/list`;
        return this.post<ReviewPeriod[]>(url, null).pipe(
            map(x => x.map(y => {
                // if (y.startDate)
                //     y.startDate = Temporal.PlainDate.from(y.startDate.toString());

                // if (y.endDate)
                //     y.endDate = Temporal.PlainDate.from(y.endDate.toString());

                if (y.createdOn)
                    y.createdOn = new Date(y.createdOn.toString());

                // if (y.panoramaAuraStartDate)
                //     y.panoramaAuraStartDate = Temporal.PlainDate.from(y.panoramaAuraStartDate.toString());

                // if (y.panoramaAuraEndDate)
                //     y.panoramaAuraEndDate = Temporal.PlainDate.from(y.panoramaAuraEndDate.toString());

                // if (y.goalsStartDate)
                //     y.goalsStartDate = Temporal.PlainDate.from(y.goalsStartDate.toString());

                // if (y.goalsEndDate)
                //     y.goalsEndDate = Temporal.PlainDate.from(y.goalsEndDate.toString());
                    
                return y;
            }))
        );
    }

    getList = (options?: Search): Observable<PagedListResult<ReviewPeriod>> => {
        if (!options) options = {search: ''} as Search;

        return this.post<PagedListResult<ReviewPeriod>>(`/api/review-period/list-search`, options, {headers: {'X-Skip-Interceptor':'1'}});
    }

    getListAsIdName = (options?: Search): Observable<PagedListResult<IdName>> => {
        return this.getList(options).pipe(
            map(x => {
                let pList = {
                    pageCount: x.pageCount,
                    page: x.page,
                    pageSize: x.pageSize,
                    total: x.total,
                    list: x.list.map(rp => {
                        let data = {
                            id: rp.id,
                            name: rp.name,
                            description: `${DateHelper.formatPlainDateShort(rp.startDate)} - ${DateHelper.formatPlainDateShort(rp.endDate)}`
                        } as IdName;
                        return data;
                    }), 
                } as PagedListResult<IdName>;                
                return pList;
            })
        );
    }    

    getListNotClosed(userId: string): Observable<ReviewPeriod[]> {
        const url = `/api/review-period/list-not-closed/${userId}`;
        return this.get<ReviewPeriod[]>(url);
    }

    getForEdit(reviewPeriodId?: number): Observable<ReviewPeriodForEdit> {
        const url = reviewPeriodId ? `/api/review-period/get-for-edit/${reviewPeriodId}` : `/api/review-period/get-for-edit`;
        return this.get<ReviewPeriodForEdit>(url);
    }

    getReviewPeriodListForPanoramaAndAura(): Observable<ReviewPeriod[]> {
        return this.get<ReviewPeriod[]>('/api/review-period/list-panorama-and-aura');
    }

    getEmployeeReviews(): Observable<EmployeeReviews> {
        return this.get<EmployeeReviews>('/api/review-period/employee-reviews');
    }

    add(model: ReviewPeriod): Observable<Result<ReviewPeriod>> {
        let data = {
            ...model,
            startDate: DateHelper.toPlainDate(model.startDate)?.toString(),
            endDate: DateHelper.toPlainDate(model.endDate)?.toString(),
            panoramaAuraStartDate: DateHelper.toPlainDate(model.panoramaAuraStartDate)?.toString(),
            panoramaAuraEndDate: DateHelper.toPlainDate(model.panoramaAuraEndDate)?.toString(),
            goalsStartDate: DateHelper.toPlainDate(model.goalsStartDate)?.toString(),
            goalsEndDate: DateHelper.toPlainDate(model.goalsEndDate)?.toString()
        }

        return this.put<Result<ReviewPeriod>>('/api/review-period/add', data);
    }

    update(model: ReviewPeriod): Observable<void> {
        let data = {
            ...model,
            startDate: DateHelper.toPlainDate(model.startDate)?.toString(),
            endDate: DateHelper.toPlainDate(model.endDate)?.toString(),
            panoramaAuraStartDate: DateHelper.toPlainDate(model.panoramaAuraStartDate)?.toString(),
            panoramaAuraEndDate: DateHelper.toPlainDate(model.panoramaAuraEndDate)?.toString(),
            goalsStartDate: DateHelper.toPlainDate(model.goalsStartDate)?.toString(),
            goalsEndDate: DateHelper.toPlainDate(model.goalsEndDate)?.toString()
        };
        
        return this.post<void>('/api/review-period/update', data);
    }

    changeStatus(model: ReviewPeriodChangeStatus): Observable<ResultModel> {
        return this.post<ResultModel>(`/api/review-period/change-status`, model);
    }
        
    isReviewExists(reviewPeriodId: number): Observable<boolean> {
        return this.get<boolean>(`/api/review-period/is-review-exists/${reviewPeriodId}`);
    }

    // generateSettings(reviewType: ReviewTypeEnum): Observable<ReviewPeriodQuestionSettings[]> {
    //     const url = `/api/review-period/generate-settings/${reviewType}`;
    //     return this.get<ReviewPeriodQuestionSettings[]>(url);
    // }
}



