import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartConfiguration, TooltipItem } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ReportHelper } from 'src/app/helpers/report.helper';
import { IdName } from 'src/app/models/general/id-name.model';
import { ListWithTitle } from 'src/app/models/general/list-with-title.model';
import { QuestionCategoryResultsForReviewPeriod } from 'src/app/models/report/question-category-results-for-reviewperiod.model';
import { ReportOptions } from 'src/app/models/report/report-options.model';
import { StressMapReportItemModel } from 'src/app/models/report/stressmap-report-item.model';
import { ReportService } from 'src/app/services';
import { TextGuideService } from 'src/app/services/text-guide.service';
import { ViewHelper } from 'src/app/shared/helpers';
import { TextHelper } from 'src/app/shared/helpers/text.helper';

@UntilDestroy()
@Component({
  selector: 'app-team-dysfunctions',
  templateUrl: './team-dysfunctions.component.html',
  styleUrls: ['./team-dysfunctions.component.scss']
})
export class TeamDysfunctionsComponent implements OnInit {

  public reportOptions: ReportOptions;

  public reportData: StressMapReportItemModel = null;
  public colors = [1, 0, 5, 2, 3];
  public itemIndex = 0;
  public reviewPeriodId?: number;

  public dataList: number[] = [];

  public rawResults: QuestionCategoryResultsForReviewPeriod[] = [];

  public freeTextLists: ListWithTitle[] = [];

  public columnCodes: string[] = ['DY005', 'DY002', 'DY003', 'DY001', 'DY004'];
  
  @ViewChildren('barChart', {read: BaseChartDirective}) barChart: BaseChartDirective;

  public barChartOptions1: ChartConfiguration<'bar'>['options'] = {
    layout: {
      padding: 15,
    },
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    scales: {
      y: {
        ticks: { 
          color: '#383838',
          font: (ctx) => {
            
            return {
              size: ctx.chart.width < 500 ? 11 : 14,
              family: 'Work Sans',            
            };
          },
        },
        grid: {
          display: false,       
        }
      },      
      x : {       
        ticks: {
          color: '#383838',
          stepSize: 1,
          autoSkip: false,
        }, 
        min: 3,
        max: 9,        
      }
    },    
    plugins: {      
      tooltip: {  
        titleFont:{
          size: 15
        },      
        footerFont: {
          size: 11
        },
        callbacks: {
          footer: (tooltipItems) => {
            return this.footerContent(tooltipItems, 62);
          } 
        }
      }
    }
  };

  constructor(
      private reportService: ReportService, 
      private route: ActivatedRoute, 
      private guideService: TextGuideService,
      private cd: ChangeDetectorRef
    ) {
  }
 

  ngOnInit(): void {

    this.route.params
    .pipe(untilDestroyed(this))
    .subscribe(params => {
        this.reviewPeriodId = parseInt(params['reviewPeriodId']);
                
        this.reportOptions = this.guideService.getGuideAsReportOptions('assessment:MilioTeamDysfunction');

        if (this.reviewPeriodId)
        {
          this.dataList.push(this.reviewPeriodId);
          this.loadData();
        }

        ViewHelper.scrollToTop();
    });  
        
     
  }

  loadData() {    
    this.freeTextLists = [];

    this.reportService.getMilioReport(this.dataList).subscribe(list => {
                                                                   
      this.rawResults = list;
      let chartDetails = new StressMapReportItemModel();
      chartDetails.barChartDs.columnCodes = this.columnCodes;
      chartDetails.barChartOptions = this.barChartOptions1;

      let resultItem = list[0];
      this.itemIndex = 0;

      chartDetails.chartData = resultItem;
        
      this.reportOptions = {
        ...this.reportOptions, 
        reviewPeriod: resultItem.reviewPeriod,
      } as ReportOptions;
        
      let firstItem = true;
      list.forEach(resultItem => {  //item by reviewperiod
        this.manageResultItems(resultItem, chartDetails, firstItem);
        firstItem = false;
      });

      this.reportData = chartDetails;
    });
  }

  manageResultItems(resultItem: QuestionCategoryResultsForReviewPeriod, chartDetails: StressMapReportItemModel, firstItem: boolean) {
    this.freeTextLists.push({ title: resultItem.reviewPeriod.name, list: resultItem.freeTexts });

    resultItem.color = ReportHelper.getChartLegendColor(this.colors[this.itemIndex], 99);
    chartDetails.legend.push({
        mainLine: resultItem.reviewPeriod.name,
        useRpDateRange: true,
        startDate: resultItem.reviewPeriod.startDate,
        endDate: resultItem.reviewPeriod.endDate,
        colorOptions: resultItem.color
      });
    this.addBarDatasource(firstItem, resultItem, chartDetails);
        
    this.itemIndex++;
  }

  addBarDatasource(firstItem: boolean, itemData: QuestionCategoryResultsForReviewPeriod, detailsItem: StressMapReportItemModel) {
    const values: number[] = [];
    const labels: string[] = [];

    detailsItem.barChartDs.columnCodes.forEach(code => {
      const category = detailsItem.chartData.questionCategories.find(x => x.code === code);
      const catResult = itemData.items.find(x => x.categoryId === category.id);
      
      if (catResult) {
        if (firstItem)
        {
          labels.push(category.name.toLocaleLowerCase());
        }        
        values.push(catResult.resultDecimal);
      }
    });   

    detailsItem.barChartDs.datasets.push({
      data: values, 
      label: itemData.reviewPeriod.name,
      ...itemData.color,      
      barThickness: 'flex',
      barPercentage: 0.6,
      categoryPercentage: 0.6,
    });    
    
    if (firstItem)
        detailsItem.barChartDs.labels = labels;
  } 

  onAdd(data: IdName) {
    this.dataList.push(data.id);
    this.loadData();    
  }

  onRemove(data: IdName) {
    this.dataList = this.dataList.filter(x => x != data.id);
    this.loadData();
  }

  footerContent(tooltipItems: TooltipItem<"radar">[] | TooltipItem<"bar">[], maxLineLength: number) {
    let result: string[] = [];    
        
    if (tooltipItems.length > 0)
    {
      const chartY = tooltipItems[0] as TooltipItem<"bar">;
      const category = this.reportData.chartData.questionCategories.find(x => x.name.toLocaleLowerCase() == chartY.label.toLocaleLowerCase());
      const rpLabel = chartY.dataset.label;

      const rpItem = this.rawResults.find(x => x.reviewPeriod.name === rpLabel);
      const catResult = rpItem.items.find(x => x. categoryId == category.id);
      
      result.push('');
      if (tooltipItems.length > 1)
      {        
        result = result.concat(TextHelper.wrapStringIntoArray(`${chartY.dataset.label.toLocaleLowerCase()}: `, maxLineLength));
      }
            
      result.push('Részletes eredmények kérdésenként:');
      result.push('');
      catResult.questionResults.forEach(qr => {
        result = result.concat(TextHelper.wrapStringIntoArray(qr.name, maxLineLength));
        result.push(`Válaszok átlaga: ${qr.resultDecimal}`);
        result = result.concat(TextHelper.wrapStringIntoArray(`Legkisebb válasz: ${qr.min} | Legnagyobb válasz: ${qr.max} | Szórás: ${qr.standardDeviation}`, maxLineLength));
        result.push('');
      });
      
      return result;
    }
    return '';
  }  

  @HostListener("window:beforeprint", [])
  onWindowBeforePrint() {    
    //this.radarChart1.chart.resize(800,800);
    this.barChart.chart.resize(600,360);
  }

  @HostListener("window:afterprint", [])
  onWindowAfterPrint() {    
    //this.radarChart1.chart.resize();
    
    this.barChart.chart.resize();
  }
}
