import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionForReviewPeriodDialogComponent } from 'src/app/dialogs';
import { EmployeeStatus } from 'src/app/enums/employee-status.enum';
import { ActionForReviewPeriodDialogConfig } from 'src/app/models/action-for-review-period-dialog-config.model';
import { EmployeeHierarchy, EmployeeHierarchyCreateDelete } from 'src/app/models/employee-hierarchy.model';
import { Employee } from 'src/app/models/employee.model';
import { Search } from 'src/app/models/search.model';
import { State } from 'src/app/models/state.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { EmployeeService, StateService } from 'src/app/services';
import { EmployeeHierarchyService } from 'src/app/services/employee-hierarchy.service';
import { CardComponent, SearchComponent } from 'src/app/shared/components';
import { ComponentState } from 'src/app/shared/enum/component-state.enum';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';

@Component({
  selector: 'app-leader',
  templateUrl: './leader.component.html',
  styleUrls: ['./leader.component.scss']
})
export class LeaderComponent implements OnInit {

  @Input()
  public model: UserProfile;

  public itemsSubject = new BehaviorSubject<ListItem<EmployeeHierarchy>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();
      
  public searchConfig: SearchConfig<Employee, number> = {
    mapItem: (model: Employee) => {
      return {
        id: model.id, 
        text: model.name,
        dropText: `${model.name} | ${!model.employeeIdentifier ? model.email : model.employeeIdentifier}`,
        itemSource: model } as SearchOption<Employee, number>;
    },
    getList: this.employeeService.getList,
    options: { exceptItemId: this.employeeStore.employee?.value?.employee.id} as Search
  };

  @ViewChild('card')
  card: CardComponent;

  @ViewChild('search')
  search: SearchComponent<Employee, number>;

  public get allowAdd(): boolean {
    return this.itemsSubject.getValue().length === 0 || this.itemsSubject.getValue().every(x => x.state === ComponentState.View || x.state === ComponentState.Disabled);
  }

  public get allowEdit(): boolean {
    return this.state.isSuperAdmin || this.state.isAdmin;
  }

  public state: State;
  
  constructor(    
    private employeeHierarchyService: EmployeeHierarchyService, 
    private employeeService: EmployeeService,
    private employeeStore: EmployeeState, 
    stateService: StateService,
    private dialog: MatDialog,
    private msgService: ErrorMessageService) 
    {
      this.state = stateService.state;
  }

  ngOnInit(): void {

    combineLatest([this.employeeStore.employee.obs$, this.employeeStore.reviewPeriod.obs$])
    .subscribe(emp => {
        this.loadList();
    });   
    
  }
  
  private loadList() {
    let profile = this.employeeStore.employee.value;
    if (profile && profile.employee) 
    {
      this.employeeHierarchyService.getLeadersByEmployee(profile.employee.id, this.employeeStore.reviewPeriod?.value?.id).pipe(
        map(list => list.map(x => {
          let item = new ListItem(x);

          return item;
        })),
      )
      .subscribe(itemArray => {
        this.itemsSubject.next(itemArray);
      });
    }
    else
    {
      console.log('leaderComponent: employee is null', profile);
    }
  }

  public deleteItem(item: ListItem<EmployeeHierarchy>) {
    const dialogConfig: ActionForReviewPeriodDialogConfig = {
      title: 'Vezető hozzárendelés törlése',
      message: 'Biztosan törölni szeretné a vezető hozzárendelését?',
    }

    const dialogRef = this.dialog.open(ActionForReviewPeriodDialogComponent, {
      minWidth: "500px",
      data: dialogConfig
    });
    
    dialogRef.afterClosed().subscribe( (dialogResult: number[]) => {
      if (dialogResult)
      { 
        const dto = {
          leaderId: item.itemSource.leader.id,
          memberId: item.itemSource.member.id,
          reviewPeriodList: dialogResult
        } as EmployeeHierarchyCreateDelete;
    
        this.employeeHierarchyService.deleteLeader(dto).subscribe(result => {
          this.loadList();
    
          this.msgService.showSnackBarMessage('deleteSuccessful');
        }, error => {
          this.msgService.showErrorResponse(error);
        });

      }
    });
  }
  
  public addItem(option: SearchOption<Employee, number>) {
    const dialogConfig: ActionForReviewPeriodDialogConfig = {
      title: 'Vezető hozzárendelése',
      message: 'Biztosan hozzá szeretné rendelni a vezetőt?',
    }

    const dialogRef = this.dialog.open(ActionForReviewPeriodDialogComponent, {
      minWidth: "500px",
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe( (dialogResult: number[]) => {
      if (dialogResult)
      { 
        let dto = {
          leaderId: option.itemSource.id,
          memberId: this.employeeStore.employee.value.employee.id,
          reviewPeriodList: dialogResult
        } as EmployeeHierarchyCreateDelete;
            
        this.employeeHierarchyService.addLeader(dto).subscribe(data => {
          this.loadList();
        }, error => {
          this.msgService.showErrorResponse(error);
        });

      }
    });
    
  }

  displayLeaderName(leader: Employee): string
  {
    if (leader.employeeStatus === EmployeeStatus.Inactive)
      return 'Törölt vezető';

      return leader.name + ' | ' + (!leader.employeeIdentifier ? leader.email : leader.employeeIdentifier);
  }
  
  onOptionSelected() {

  }

}
