import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Observable, of, switchMap, tap } from 'rxjs';
import { CompanyEditDialogComponent } from 'src/app/dialogs';
import { Roles } from 'src/app/enums/roles.enum';
import { CompanyStat } from 'src/app/models/company-stat.model';
import { Company } from 'src/app/models/company.model';
import { PagingRequest } from 'src/app/models/paging-request.model';
import { Search } from 'src/app/models/search.model';
import { State } from 'src/app/models/state.model';
import { AuthService, CompanyService, StateService } from 'src/app/services';
import { MainHubService } from 'src/app/services/mainHubService.service';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {

  private readonly itemsSubject = new BehaviorSubject<CompanyStat[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();

  public masterTableColumns = ['name', 'employeeCount', 'action'];
  public state: State;

  public minCharCount = 3;

  public total = 0;
  public pageSize = 500;
  public pageSizeOptions = [3, 5, 20, 50, 100];
  public page = 0;

  public searchMessage = '';
  
  public searchControl: UntypedFormControl;

  public forbiddenReturnUrls = [
    '/company/create',
    'assessment/',
    'personality-test/diagram/',
    'personality-test/details/',
    'team-dysfunctions/',
    'team-stressmap/',
    'reports/',
    'goals/',
    'employee-add',
    'employee/'
  ];

  public get isValidCharacterCount(): boolean {
    return this.searchControl.value.length === 0 || this.searchControl.value.length >= this.minCharCount;
  }

  public get showAdminFunctions(): boolean {    
    return this.state?.isAdmin || this.state?.isSuperAdmin;
  }

  constructor(private companyService: CompanyService, 
    private authService: AuthService, 
    private router: Router,
    public appState: AppState,
    stateService: StateService,
    private hubService: MainHubService,
    private dialog: MatDialog,
    ) { 
      this.state = stateService.state;
    }

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl('');

    this.searchControl.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => {
          this.setValidationMessage();
        }),
        switchMap(searchString => searchString.length === 0 || searchString.length >= this.minCharCount ? this.doSearch(searchString): of([]))
    ).subscribe({next: itemArray => {
      this.itemsSubject.next(itemArray);
    }});

    this.loadList();    
  }

  loadList() {
    this.doSearch(this.searchControl.value).subscribe({next: itemArray => {
      this.itemsSubject.next(itemArray);
    }});
  }

  onMasterRowClick(item: CompanyStat) {

    if (item.id !== this.appState.company.value.id)
    {
      this.hubService.stopConnection();
      this.authService.changeCompany(item.id).subscribe({ next: (data) => {       
        this.router.navigate([this.getLocation()]);
      }});
    }
    else
      this.router.navigate([this.getLocation()]);
  }

  private doSearch(searchString: string): Observable<CompanyStat[]> {
    return this.companyService.getStats(this.buildPageRequest(searchString)).pipe(
      tap(data => {
        this.total = data.total;
        this.searchMessage = this.getResultMessage(data.list.length);
      }),
      map(result => result.list)
    );
  }

  private buildPageRequest(searchString: string) {
    let data = {
      search: searchString,
      pagingRequestInfo: {
        pageSize: this.pageSize,
        page: this.page + 1
      } as PagingRequest
    } as Search;

    return data;
  }

  public getLocation(): string {
    let targetPath = this.getLocationByHistory();
    return this.getLocationByPermission(targetPath);
  }

  public getLocationByHistory(): string {
    if (!this.appState.prevLocation?.value) return '/';

    const isForbidden = this.forbiddenReturnUrls.find(x => this.appState.prevLocation?.value.includes(x) );

    if (this.appState.prevLocation?.value === this.appState.currentLocation?.value || isForbidden )
      return '/';
    else
      return this.appState.prevLocation?.value;
  }

  public getLocationByPermission(path: string): string {
    let isElevated = this.state.isAdmin || this.state.isSuperAdmin || this.state.isAssistant; 
    if (path === '/' && isElevated)
        return '/settings';
    else if (path == '/settings' && !isElevated)
        return '/';
    else
      return path;
  }

  public showCompanyDialog(model: Company){
    const dialogRef = this.dialog.open(CompanyEditDialogComponent, {
      minWidth: "500px",
      data: model,
    });

    dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
      if (dialogResult)
      {         
        this.loadList();
      }
    });
  }

  public createCompany() {
    this.showCompanyDialog(null);
  }

  public editCompany(model: Company) {
    this.showCompanyDialog({...model} as Company);
  }


  public showEmployeeCount(count: number) {
    if (this.state?.me?.value?.role != Roles.User)
      return count;
    else
      return '';
  }
  
  public clearText() {
    this.searchControl.setValue('');
  }

  public get searchValue(): string {
    return this.searchControl.value;
  }

  public getResultMessage(count: number): string {
    if (this.total > count)
      return `${ this.total } találatból ${ count } megjelenítve.`;
    else if (count === 0)
      return 'Nincs találat';
    else
      return '';
  }

  public setValidationMessage() {
    if (!this.isValidCharacterCount)
      this.searchMessage = `Minimum ${ this.minCharCount } karakter szükséges`;
  }

}
