import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeGroupMember } from '../models/employee-group-member.model';
import { DefaultDataService } from './default-data.service';
import { StateService } from './state.service';
import { ResultModel } from '../models/general/result.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGroupMemberService extends DefaultDataService<EmployeeGroupMember> {
    
  constructor(
      http: HttpClient, 
      stateService: StateService,
  ) 
  {
      super(http, stateService);
      this.controllerName = 'employee-group-member';
  }
    
  public getList(groupId: number): Observable<EmployeeGroupMember[]> {
    return this.get<EmployeeGroupMember[]>(`/api/${this.controllerName}/list/${groupId}`, {headers: {'X-Skip-Interceptor':'1'}} );
  }
  
  public deleteMember(model: EmployeeGroupMember): Observable<ResultModel> {
    return this.delete<ResultModel>(`/api/${this.controllerName}/delete/${model.groupMember.id}/${model.group.id}`);
  }

}
