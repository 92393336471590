import { InjectionToken } from '@angular/core';
import { Message, MessageType } from '../models';

const messages: {[key: string]: Message} = {
      addSuccessful: { text: "A hozzáadás sikeresen megtörtént.", type: MessageType.success },
      deleteSuccessful: { text: "Sikeres törlés.", type: MessageType.success },
      cacheDeleted: { text: "A cache törölve.", type: MessageType.success },
      deleteEmployeeSuccessful: { text: "A felhasználó törlésre került.", type: MessageType.success },
      
      badEmail: { text: "Hibás formátumú email cím.", type: MessageType.error },
      badDeadline: { text: "Hibás formátumú határidő, a cellában szereplő adat nem értelmezhető dátumként.", type: MessageType.error },

      importUnknownError: { text: "Ismeretlen hiba történt az import során. Kérjük próbálja újra.", type: MessageType.error },
      importHasNoPositions: { text: "Az import fájl nem tartalmaz munkaköröket.", type: MessageType.error },
      importStructureError: { text: "Hibás import fájl formátum. Hiányzó vagy nem megfelelő oszlopok az import fájlban.", type: MessageType.error },
      importCompanyDataError: { text: "Hibás vagy hiányzó cégadatok az import fájlban.", type: MessageType.error },
      importWrongCompany: { text: "Az import fájlban jelzett cégnév nem egyezik a kiválasztott céggel.", type: MessageType.error },
      importDataWarningOrError: { text: "Az import fájl feldolgozása során hibák és/vagy észrevételek merültek fel. Kérjük, ellenőrizd az alábbi észrevételeket, és javítsd ennek megfelelően a feltöltött fájlt.", type: MessageType.error },
      importHasSomeError: { text: "Az importálás részlegesen sikerült, de a következő tételek nem lettek importálva, kérjük javítsd őket, és próbáld újra.", type: MessageType.error },
      importTargetLeaderHasNoSubordinate: { text: "Az értékelt vezető nem rendelkezik beosztottakkal.", type: MessageType.warning },
      importReviewPeriodClosed: { text: "Az időszak már lezárult, ezért az import ebbe az időszakba nem lehetséges.", type: MessageType.error },
      importNameChange: { text: "Az import során a következő személyek neve megváltozik.", type: MessageType.warning },
      importRoleNotExist: { text: "A felsorolt személyeknél megadott szerepkörök nem engedélyezettek vagy nem léteznek a rendszerben. Import során Felhasználó vagy HR Adminisztrátor szerepkör közül lehet választani.", type: MessageType.error },
      importTargetLeadersAddedWhenNotRequired: { text: "Az import fájlban Aura értékelésre megjelölt vezetőket a rendszer nem fogja értékelt vezetőként kezelni, mert nincs kiválasztva semmilyen időszak vagy a kiválasztott időszak nem Aura.", type: MessageType.warning },
      importPositionHasNoQuestions: { text: "Az import fájlban az alábbi munkakörökhöz nem tartoznak kérdések.", type: MessageType.error },
      importQuestionModuleHasNoGroups: { text: "Az import fájlban az alábbi munkakörök esetén egyes kérdésmodulokhoz nem tartoznak kérdéscsoportok.", type: MessageType.error },
      importGroupHasNoQuestions: { text: "Az import fájlban az alábbi munkakörök esetén egyes kérdéscsoportokhoz nem tartoznak kérdések.", type: MessageType.error },

      employeeNotAuraTarget: { text: "A személy nem adható hozzá az időszakhoz mert nincs az értékelt vezetők között.", type: MessageType.error },

      // validation
      validationEmployeesWithNoPosition: { text: "A következő személyekhez nincs megadva munkakör.", type: MessageType.error },
      validationEmployeesWithNoLeader: { text: "A következő személyekhez nincs hozzárendelve vezető.", type: MessageType.warning },
      validationPositionHasNoQuestions: { text: "A következő személyek munkaköréhez nincsenek rögzítve kérdések.", type: MessageType.error },
      validationTargetLeaderHasNoReview: { text: "A következő személyek értékelt vezetőként vannak megjelölve de nem mutat rájuk értékelés.", type: MessageType.error },
      validationChangeStatusError: { text: "Az időszak állapotának változtatása során hibák és/vagy észrevételek merültek fel. Kérjük, ellenőrizd az alábbi észrevételeket, és javítsd az esetleges hibákat a folytatáshoz.", type: MessageType.error },


      // KPI Import
      importReviewPeriodNotFound: { text: "Az importáláshoz szükséges értékelési időszak nem található.", type: MessageType.error },
      importHasNoEmployeeKpiData: { text: "Az import fájl nem tartalmaz dolgozókhoz kapcsolódó KPI adatokat.", type: MessageType.error },
      importAboutEmployeeNotFound: { text: "Az importban szereplő értékelt személyek nem léteznek a kiválasztott cégben.", type: MessageType.error },
      importByEmployeeNotFound: { text: "A listában szereplő dolgózókat értékelő vezetők nem léteznek a kiválasztott cégben.", type: MessageType.error },
      importKPIAboutEmployeeNameDuplicate: { text: "Az importban található értékelt személyek között azonos nevű személyek szerepelnek.", type: MessageType.warning },


      ratingSetNotFoundForQuestionModule: { text: "Nincs skála importálva egy vagy több kérdésmodulhoz.", type: MessageType.error },
      
      importEmailDuplicates: { text: "Több személynek is azonos az email címe.", type: MessageType.error },
      importEmailSameAsBossEmail: { text: "A dolgozó email címe nem lehet azonos saját főnökének email címével.", type: MessageType.error },
      importIdentifierDuplicates: { text: "Több személynek is azonos a törzsszáma.", type: MessageType.error },
      importNameDuplicates: { text: "Név alapján duplán szerepelnek a következő személyek.", type: MessageType.warning },
      importBossEmailNotValid: { text: "A következő személyek főnökeinél hibás email címek szerepelnek.", type: MessageType.error },
      importNoLeader: { text: "Nincs megadva vezető.", type: MessageType.warning },
      importLeaderEmailsAreTheSame: { text: "A következő személyeknél az első és második főnök megegyezik.", type: MessageType.error },
      importNoPosition: { text: "Az import fájlban az alábbi személyeknek hiányzik a munkaköre.", type: MessageType.error },
      importPositionNotExist: { text: "Az alábbi munkakörök nem léteznek a rendszerben: kérjük, az alkalmazott adatokat megelőzően importáld őket a 2. munkaköri kompetencia-értékelés adatok [Panoráma] funkcióval.", type: MessageType.error },
      importPositionNotExistWarning: { text: "Az alábbi munkakörök még nem léteznek a rendszerben, de folytatás esetén létrehozásra kerülnek.", type: MessageType.warning },
      importLeaderNotFound: { text: "A személyeknél megadott vezetők nem találhatók meg a cégben.", type: MessageType.error },

      emailSendError: { text: "Email küldési hiba.", type: MessageType.error },
      emailSendComplete: { text: "Az email küldési folyamat befejeződött.", type: MessageType.success },
      forgottenPasswordError: {text: "Hibás email cím, vagy a felhasználó nem található.", type: MessageType.error},
      passwordChangeSuccess: {text: "A jelszó sikeresen megváltoztatva.", type: MessageType.success},
      emailNotEnoughMailTarget: { text: "A rendszer nem talált olyan címzettet, akinek a levél kiküldhető lenne.", type: MessageType.error },
      uknownMailType: { text: "Ismeretlen email típus.", type: MessageType.error },

      userHasNoCompany: { text: "A felhasználó egyetlen céghez sincs hozzárendelve.", type: MessageType.error },

      employeeExistsInDefaultCompany: {text: "A dolgozó már létezik a Magánszemélyek nevű cégben. Kérjük először migráld át onnan a kiválasztott cégbe, majd ismételd meg az importot.", type: MessageType.error},
      employeeExistsInOtherCompany: {text: "A dolgozó már létezik egy másik cégben is (email címe alapján), így nem importálható.", type: MessageType.error},
      leader1ExistsInDefaultCompany:  {text: "A dolgozó 1. főnöke már létezik a Magánszemélyek nevű cégben. Kérlek először migráld át onnan a kiválasztott cégbe, majd ismételd meg az importot.", type: MessageType.error},
      leader1ExistsInOtherCompany: {text: "A dolgozó 1. főnöke már létezik egy másik cégben is (email címe alapján), így nem importálható.", type: MessageType.error},
      leader2ExistsInDefaultCompany:  {text: "A dolgozó 2. főnöke már létezik a Magánszemélyek nevű cégben. Kérlek először migráld át onnan a kiválasztott cégbe, majd ismételd meg az importot.", type: MessageType.error},
      leader2ExistsInOtherCompany: {text: "A dolgozó 2. főnöke már létezik egy másik cégben is (email címe alapján), így nem importálható.", type: MessageType.error},
      noQuestionsForPosition: {text: 'A következő munkakörökhöz hiányoznak a rendszerből a Panoráma kérdőív kitöltéséhez szükséges kérdések.', type: MessageType.error},

      unsuccessfulAction: { text: 'Sikertelen művelet.', type: MessageType.error },
      companyNotFound: { text: 'A cég nem található.', type: MessageType.error },

      changeReviewPeriodStatusFailed: { text: 'Időszak állapot váltás sikertelen.', type: MessageType.error },

      noUser: { text: 'Nem létező felhasználó.', type: MessageType.error },
      noEmployee: { text: 'Nem létező dolgozó.', type: MessageType.error },
      emailAlreadyExists: { text: 'Hiba: Az email cím már létezik a rendszerben.', type: MessageType.error},
      noToken: { text: 'Hiba: A token nincs megadva.', type: MessageType.error },
      noDeleteEvent: { text: 'A törlési folyamat nem található, vagy már korábban le lett állítva.', type: MessageType.error },
      userNotActivated: { text: 'Nem aktivált felhasználó. Kérjük lépj kapcsolatba az adminisztrátorokkal!', type: MessageType.error },
      loginFailed: { text: 'Hibás email és/vagy jelszó.', type: MessageType.error },
      insufficientPrivileges: { text: 'Nincs megfelelő jogosultságod a művelet végrehajtásához.', type: MessageType.error },
      employeeAlreadyExistsInCompany: { text: 'Ez a dolgozó már létezik ebben a cégben.', type: MessageType.error },
      employeeAlreadyExistsInOtherCompany: { text: 'Ez a dolgozó már létezik egy másik cégben.', type: MessageType.error },
      employeeAlreadyExistsInReviewPeriod: { text: 'Ez a dolgozó már szerepel az értékelésben.', type: MessageType.error },
      canNotUpdateOwnRole: {text:'Saját szerepkör nem módosítható.', type: MessageType.error },
      canNotDeleteOwnRole: {text:'Saját szerepkör nem törölhető.', type: MessageType.error },
      doesNotHaveRoleInCompany: {text: 'Nincs a cégben szerepköröd', type:MessageType.error},
      userAlreadyHasRoleInCompany: {text: 'A felhasználó már rendelkezik szerepkörrel a cégnél.', type:MessageType.error},
      assessmentExpired: {text: 'Az értékelés határideje lejárt.', type: MessageType.error},
      assessmentClosed: {text: 'Az értékelés lezárult.', type: MessageType.error},
      notAllQuestionHasAnswer: {text: 'Nincs minden kérdés megválaszolva, kérem ellenőrizze a kitöltött kérdőívet.', type: MessageType.error},
      kpiTypeAlreadyExist: {text: 'Ez a KPI típus már létezik.', type: MessageType.error},
      kpiUpdateTypeRequired: {text: 'Ez a KPI másoknál is használatban van, módosítsuk mindenkinél, vagy a változtatások csak erre a kollégára vonatkozzanak?', type: MessageType.warning},
      
      migrationUnsupportedReviewType: {text: 'Migrácionál nem támogatott értékelés típussal is rendelkezik.', type: MessageType.error},
      migrationHasGoals: {text: 'Migráció nem lehetséges, mert a dolgozóhoz célkitűzések vannak rögzítve.', type: MessageType.error},
      migrateEmployeeExistInTargetCompany: {text: 'A dolgozó már létezik a célként kiválasztott cégnél.', type: MessageType.error},
      migrationError: {text: 'A felhasználó migráció sikertelen volt.', type: MessageType.error},

      goalPeriodOpen: {text: 'Az egyéni célok szerkesztési lehetősége megnyílt.', type: MessageType.info},
      goalPeriodClosed: {text: 'Az egyéni célok szerkesztési lehetősége lezárult.', type: MessageType.info},      

      canNotDetectAssessmentType: {text: 'A megadott személyek között nem találtunk kapcsolatot, kérjük rögzítsd, vagy manuálisan add meg az értékelés irányát.', type: MessageType.error},
      reviewPeriodCreated: {text: 'Az új időszak létrejött.', type: MessageType.success},
      canNotCreateInCompany: {text: 'A bejegyzés nem hozható létre a kiválasztott cégben.', type: MessageType.error},

      rpNotOpen: {text: 'Az értékelés nem nyitott.', type: MessageType.error},

      rpeNotFound: {text: 'Az értékelés nem található.', type: MessageType.error},
      rpeStatusNotNew: {text: 'A műveletet már folyamatban lévő értékelésen nem lehet elvégezni.', type: MessageType.error},
      rpeCanNotCreate: {text: 'Az értékelés nem hozható létre.', type: MessageType.error},
      rpeAlreadyExists: {text: 'Az értékelés már létezik.', type: MessageType.error},
      rpeNotClosedForAddSziluett: {text: 'A személy előző értékelése nem zárult le, így nem lehet hozzáadni új Sziluett értékelést.', type: MessageType.error},
      
      reviewTypeNotSziluett: {text: 'Az értékelés nem Sziluett típusú.', type: MessageType.error},
      reviewTypeNotValid: {text: 'Az értékelés típusa nem megfelelő.', type: MessageType.error},

      noUserSelected: {text: 'Kérlek válassz ki egy dolgozót a listából.', type: MessageType.error},
      noEmployeeGroupSelected: {text: 'Kérlek válassz ki vagy hozz létre egy csapatot.', type: MessageType.error},
      noPositionForEmployee: {text: 'A kiválasztott személynek nincs beállított munkaköre.', type: MessageType.error},
      noQuestionSetForPosition: {text: 'A kiválasztott személy munkaköréhez nem tartozik kérdőív.', type: MessageType.error},
      importReviewTypeMismatch: {text: 'A kiválasztott értékelési időszak típusa nem egyezik az import típusával.', type: MessageType.error},
      noDeleteDataExistInRp: {text: 'A törlés nem hajtható végre, mert már rögzítve lettek adatok az értékelésben.', type: MessageType.error},

      passwordReminderSent: {text: 'Az email címre elküldtük a visszaállításhoz szükséges instrukciókat.', type: MessageType.success},
      saveSuccessful: { text: 'A változások mentésre kerültek.', type: MessageType.success },
      saveFailed: { text: 'Sikertelen mentés.', type: MessageType.error },
      successfulAction: { text: 'A művelet sikeresen lefutott.', type: MessageType.success },
      answerNotSaved: { text: 'Hiba: A válasz nem került mentésre. Kérem próbálja újra.', type: MessageType.error },
      errorCreatingReviewPeriod: { text: 'Hiba: Az értékelés nem került létrehozásra.', type: MessageType.error },

      migrateTooManyReviewPeriods: { text: 'Hiba: A kiválasztott dolgozók több értékelésben is részt vesznek, így a migráció nem folytatható.', type: MessageType.error },

      deadlineNotInFuture: { text: 'A határidő nem lehet múltbéli dátum.', type: MessageType.error },
      restoreDatabaseSuccess: { text: 'Az éles adatbázis átemelése sikeresen megtörtént.', type: MessageType.success },
      restoreDatabaseFailed: { text: 'Az éles adatbázis átemelése a teszt rendszerbe sikertelen volt.', type: MessageType.error },
      allowedOnlyInTestMode: { text: 'Ez a funkció csak teszt módban érhető el.', type: MessageType.error },

      exportError: {text: 'Hiba történt az exportálás során.', type: MessageType.error },
      unknownError: {text: 'Ismeretlen hiba történt.', type: MessageType.error},

      saved: {text: 'mentve', type: MessageType.success, icon: 'check_circle', duration: 2000},
      chooseToProceed: {text: 'Kérem válasszon a folytatáshoz.', type: MessageType.warning},
};

const texts: {[key: string]: string} = {
  deleteEmployeeBatchLine1: 'Kérjük, hogy a felhasználói fiók törlése funkciót akkor használd, ha megszűnt a jogalapja annak, hogy a kiválasztott személyek személyes adatait (nevét, e-mailcímét) és riportjait a quantille tovább tárolja (pl. ha kollégaként elment a cégtől, vagy jelöltként nem rá esett a választás).',
  deleteEmployeeBatchLine2: "Az 'Indítás' gombra kattintva a kiválasztott személyek felhasználói fiókja véglegesen és visszavonhatatlanul eltávolításra kerül a cégből. Ezzel a művelettel végérvényesen törlöd a quantille céges rendszeréből a kiválasztott személyek személyes adatait és az összes felmérésük/értékelésük riportját.",
  deleteEmployeeBatchLine3: 'A Sziluett riporttal rendelkező felhasználóknak a törlésről e-mailben értesítést küldünk, és aki 15 napon belül kéri, annak kizárólag a Sziluett személyiségfelmérés riportjait megőrizzük egy cégfüggetlen, személyes felhasználói fiókban. A Sziluett eredményének személyes megőrzéséhez minden felhasználónak joga van.',
  deleteEmployeeBatchLine4: 'Ha a felhasználó e-mailcíme céges, de már megszüntettétek a hozzáférését a céges e-mailfiókjához, kérjük, hogy továbbítsátok neki a céges e-mailcímére küldött automatikus üzenetünket a Sziluett riportja megőrzéséről.',
  
  deleteEmployeeDialogLine1: 'Kérjük, hogy a felhasználói fiók törlése funkciót akkor használd, ha megszűnt a jogalapja annak, hogy a kiválasztott személyek személyes adatait (nevét, e-mailcímét) és riportjait a quantille tovább tárolja (pl. ha kollégaként elment a cégtől, vagy jelöltként nem rá esett a választás).',
  deleteEmployeeDialogLine2: "Az 'Törlés' gombra kattintva a kiválasztott személy felhasználói fiókja véglegesen és visszavonhatatlanul eltávolításra kerül a cégből. Ezzel a művelettel végérvényesen törlöd a quantille céges rendszeréből a kiválasztott személy személyes adatait és az összes felmérése/értékelése riportját.",
  deleteEmployeeDialogLine3: 'A Sziluett riporttal rendelkező felhasználóknak a törlésről e-mailben értesítést küldünk, és aki 15 napon belül kéri, annak kizárólag a Sziluett személyiségfelmérés riportjait megőrizzük egy cégfüggetlen, személyes felhasználói fiókban. A Sziluett eredményének személyes megőrzéséhez minden felhasználónak joga van.',
  deleteEmployeeDialogLine4: 'Ha a felhasználó e-mailcíme céges, de már megszüntettétek a hozzáférését a céges e-mailfiókjához, kérjük, hogy továbbítsátok neki a céges e-mailcímére küldött automatikus üzenetünket a Sziluett riportja megőrzéséről.',
  
  deleteEmployeeDialogLineLast: 'Biztos vagy benne, hogy végérvényesen törlöd a felhasználói fiókot?',
};

const errorList = {
    min: ({ min }) =>           `A szám kisebb mint: ${min}`,
    max: ({ max }) =>           `A szám kisebb mint: ${max}`,
    numeric: () =>          `Egész szám beírása szükséges`,
    required: (error) =>    `A mező kitöltése kötelező`,
    minlength: ({ requiredLength, actualLength }) => `A szöveg rövidebb mint ${requiredLength} karakter`,
    maxlength: ({ requiredLength, actualLength }) => `A szöveg hosszabb mint ${requiredLength} karakter`,
    email: () =>            `Az e-mail cím formátuma nem megfelelő`,
    pattern: () =>          `A beírt szöveg formátuma nem megfelelő`,
    date: () =>             `A dátum nem megfelelő`,
    notMatched: () =>      `A két érték nem egyezik`,
    noPassswordMatch: () => 'A két jelszó nem egyezik'
}

export const  FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => errorList
});

export const MESSAGES = new InjectionToken('MESSAGES', {
  providedIn: 'root',
  factory: () => messages
});

export const TEXTS = new InjectionToken('TEXTS', {
  providedIn: 'root',
  factory: () => texts
});

export { MessageType };
