﻿<section class="head-section">
    <div class="container container-extra no-horizontal-padding">                
        <app-product-guide category="goals" [productType]="reviewTypes.TER"></app-product-guide>
    </div>
</section>

<div class="goals container container-extra">

    <div class="goals container container-extra">

        <h1 class="page-title">
            <span>Célkitűzések</span>
        </h1>
        
        <!--  Person selector  -->

        <mat-card class="card-light mb-4" *ngIf="selectedPeriod === null">
            <mat-card-content class="text-subtitle">Jelenleg célkitűzés nem rögzíthető.</mat-card-content>
        </mat-card>
        
        <ng-container *ngIf="selectedPeriod">

            <mat-form-field appearance="fill" class="width-max mat-fill-white bk-blade" *ngIf="hasOpenRp && hasRpInDateInterval">
                <mat-label>Válasszon egy dolgozót</mat-label>
                <mat-select [(value)]="currentEmployeeId" (selectionChange)="changeEmployee(currentEmployeeId)" class="selected-text-lg">
                    <mat-option *ngFor="let opt of employees" [value]="opt.id">{{ opt.name }}</mat-option>
                </mat-select>
            </mat-form-field>
             
            <div *ngFor="let reviewPeriodGoals of openPeriods; first as isFirst">
                <h1>{{ getReviewPeriodDisplayName(reviewPeriodGoals.reviewPeriod) }}</h1>
            
                <mat-card class="card-light mb-4" *ngIf="isRpNew(reviewPeriodGoals.reviewPeriod)">
                    <mat-card-content class="text-subtitle">A célkitűzés előkészítés alatt van.</mat-card-content>
                </mat-card>

                <mat-card class="card-light mb-4" *ngIf="isRpOpen(reviewPeriodGoals.reviewPeriod) && isOutOfDateInterval(reviewPeriodGoals.reviewPeriod)">
                    <mat-card-content class="text-subtitle">A célkitűzés nem elérhető.</mat-card-content>
                </mat-card>

                <ng-container *ngIf="isRpOpen(reviewPeriodGoals.reviewPeriod) && !isOutOfDateInterval(reviewPeriodGoals.reviewPeriod)">
                    <app-group-goals [data]="getGroupGoalsData(reviewPeriodGoals.goalPeriod)" *ngIf="reviewPeriodGoals.hasSubordinates"></app-group-goals>
    
                    <mat-expansion-panel class="lg-panel style-primary" [expanded]="isFirst">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <strong>Egyéni fejlődési célok <span class="text-alt1">({{ reviewPeriodGoals.goalPeriod.goals.length }})</span></strong>
                            </mat-panel-title>
                            <mat-panel-description>
                                <mat-checkbox class="inverted" [(ngModel)]="isPeriodOpen" [disableControl]="isSelf && reviewPeriodGoals.hasBoss" (change)="changePeriodState(reviewPeriodGoals.goalPeriod, !isPeriodOpen)" [title]="isSelf && reviewPeriodGoals.hasBoss && !isPeriodOpen ? 'Az Ön személyes fejlesztési célkitűzései jelenleg nem szerkeszthetők. A szerkesztésükhöz kérje meg közvetlen felettesét, hogy nyissa meg Önnek.' : ''">{{ isPeriodOpen ? 'Nyitott' : 'Lezárt' }}</mat-checkbox>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <!--  Goals  -->
                        <div *ngFor="let goal of reviewPeriodGoals.goalPeriod.goals; let i = index">
                            <mat-card class="goal-card" >
                                <mat-card-header>
                                    <mat-card-title><span class="line-index">{{i+1}}. </span><strong>Célkitűzés</strong></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                    <app-goal-edit 
                                        [goal]="goal"
                                        [leaders]="reviewPeriodGoals.leaders"
                                        [data]="getGroupGoalsData(reviewPeriodGoals.goalPeriod)"
                                        [currentPeriod]="reviewPeriodGoals.goalPeriod"
                                        (added)="goalAdded($event)"
                                        ></app-goal-edit>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    
                        <div class="show-add-button" *ngIf="isSelf && !reviewPeriodGoals.goalPeriod.isClosed">
                            <button mat-flat-button color="accent" (click)="addGoal(reviewPeriodGoals.goalPeriod)">
                                <mat-icon>add</mat-icon>
                                Cél
                            </button>
                        </div>
        
                        <mat-form-field appearance="fill" class="width-max style-2">
                            <mat-label>Vezetői megjegyzések</mat-label>
                            <textarea matInput [(ngModel)]="reviewPeriodGoals.goalPeriod.comment" [disabled]="isSelf" [ngModelOptions]="{debounce: 1000}" (change)="updateComment(reviewPeriodGoals.goalPeriod, reviewPeriodGoals.goalPeriod.comment)" 
                                class="hidden-print" rows="5"></textarea>
                        </mat-form-field>
        
                        <p class="visible-print-block">{{reviewPeriodGoals.goalPeriod.comment}}</p>
                    </mat-expansion-panel>
        
                    <mat-expansion-panel class="lg-panel kpi-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <strong>Egyéni projektcélok, KPI-ok <span class="text-alt1">({{ reviewPeriodGoals.kpiList?.length ?? 0 }})</span></strong>
                            </mat-panel-title>
                            <mat-panel-description>
                                
                            </mat-panel-description>
                        </mat-expansion-panel-header>
        
                        <!--  KPI  -->
                        <div *ngFor="let kpi of reviewPeriodGoals.kpiList; let i = index">
                            <div class="padding-bottom-m">
                                <app-goal-kpi-edit
                                            [kpi]="kpi"
                                            [index]="i+1"
                                            [currentPeriod]="reviewPeriodGoals"
                                            [aboutEmployeeId]="currentEmployeeId"
                                            [isSelf]="isSelf"
                                            (added)="kpiAdded($event)"                            
                                        ></app-goal-kpi-edit>
                            </div>
                        </div>
        
                        <div class="margin-top-m" *ngIf="!readonly">
                            <button mat-flat-button color="primary" [disabled]="isSelf" (click)="addKpi(reviewPeriodGoals)">
                                <mat-icon>add</mat-icon>
                                Projektcél, KPI
                            </button>
                        </div>
                    
                    </mat-expansion-panel>
                </ng-container>
            
            </div>
        </ng-container>

    
        <!-- history -->

        <h1 class="page-title">
            <span>Lezárt célkitűzések</span>
        </h1>

        <mat-card class="card-light mb-4" *ngIf="closedPeriods.length === 0">
            <mat-card-content class="text-subtitle">Jelenleg nincsenek lezárt célkitűzések.</mat-card-content>
        </mat-card>

        <mat-accordion *ngIf="closedPeriods.length > 0">
            <mat-expansion-panel *ngFor="let reviewPeriodGoals of closedPeriods" class="lg-panel" (opened)="openHistory(reviewPeriodGoals)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>{{ getReviewPeriodDisplayName(reviewPeriodGoals.reviewPeriod) }}</strong>
                    </mat-panel-title>                
                </mat-expansion-panel-header>

                <div *ngIf="reviewPeriodGoals.groupGoals?.length > 0" class="panel-body bottom-border">
                    <h2>Csapatcélok</h2>
                    <div>
                        <div *ngFor="let groupGoal of reviewPeriodGoals.groupGoals">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-header">Csapatcél</div>
                                    {{ groupGoal.goalText.name }}
                                </div>
                                <div class="col-6">
                                    <div class="form-header">Felettesem kapcsolódó csapatcélja</div>
                                    {{ groupGoal.parentGroupGoal?.goalText.name }}
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="reviewPeriodGoals.goalPeriod">
                    <h2>Egyéni fejlődési célok</h2>
                    <div>
                        <h5 *ngIf="reviewPeriodGoals.goalPeriod?.goals?.length == 0" class="text-center">Ebben az időszakban nem voltak egyéni célok rögzítve!</h5>
        
                        <div class="panel-body bottom-border-light" *ngFor="let goal of reviewPeriodGoals.goalPeriod.goals">
                        
                            <div class="goal-period-header flex-v">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-header">Kompetencia</div>
                                        {{ goal.questionName }}
                                    </div>
                                    <div class="col-2">
                                        <div class="form-header">Cél</div>
                                        {{ goal.targetValue }}
                                    </div>
                                    <div class="col-2">
                                        <div class="form-header">KPI</div>
                                        {{ goal.isKpi ? 'Igen' : 'Nem'}}
                                    </div>                                
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-header">Fejlesztési célkitűzés</div>
                                        {{ goal.goalText?.name }}
                                    </div>                            
                                    <div class="col-6">
                                        <div class="form-header">Felettesem kapcsolódó csapatcélja</div>
                                        {{ goal.groupGoal?.goalText.name }}
                                    </div>                            
                                </div>                            
        
                            </div>
                            
                            <div class="goal-period-actions flex-v" *ngIf="goal.actions.length > 0">
                                <div class="subtitle">Tevékenységek</div>
                                <div class="row form-header">
                                    <div class="col-6 goal-action-gap">Mit és mikor kell megtennie a fejlődés érdekében?</div>
                                    <div class="col-2">Határidő</div>
                                    <div class="col-2">Önértékelés</div>
                                    <div class="col-2">{{ goal.actions[0]?.leader?.name }}</div>
                                </div>
                                
                                <div class="row form-row goal-action" *ngFor="let action of goal.actions">
                                    <div class="col-6 goal-action-gap">{{action.name}}</div>
                                    <div class="col-2">{{ getQuarter(action.deadline) }}</div>
                                    <div class="col-2">{{ action.progress }}<span>%</span></div>
                                    <div class="col-2">{{ action.leaderProgress }}<span *ngIf="action.leaderProgress">%</span></div>
                                </div>                        
                            </div>
                        </div>
        
                        <div class="panel-body bottom-border">
                            <div class="form-header">Vezetői megjegyzések</div>
                            <p>{{ reviewPeriodGoals.goalPeriod.comment }}</p>
                        </div>                
                    </div>

                </div>

                <h2>Egyéni projektcélok, KPI-ok</h2>
                <div>
                    <h5 *ngIf="reviewPeriodGoals.kpiList.length == 0" class="text-center">Ebben az időszakban nem voltak KPI-ok rögzítve!</h5>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-header">Projektcél vagy KPI</div>
                        </div>
                        <div class="col-3">
                            <div class="form-header">Kitűzött eredmény</div>
                        </div>
                        <div class="col-3">
                            <div class="form-header">Elért eredmény</div>
                        </div>
                    </div>                
                    <div class="row" *ngFor="let kpi of reviewPeriodGoals.kpiList; let i = index">
                        <div class="col-6">                          
                            {{ kpi.kpiType?.name }}
                        </div>
                        <div class="col-3">                            
                            {{ kpi.planned }} {{ kpi.kpiType.unit }}
                        </div>
                        <div class="col-3">
                            <span *ngIf="kpi.achieved">{{ kpi.achieved }} {{ kpi.kpiType.unit }}</span>
                        </div>
                    </div>
                    
                </div>
                <!-- <app-goal-kpi-edit
                    [currentPeriod]="reviewPeriodGoals"
                    [aboutEmployeeId]="currentEmployeeId"
                    [isSelf]="isSelf"
                    [readonly]="true"
                ></app-goal-kpi-edit> -->
                
            </mat-expansion-panel>
        </mat-accordion>

    </div>
    
</div>