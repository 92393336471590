<form [formGroup]="mainForm">
    <h1 mat-dialog-title>Új KPI típus létrehozása</h1>
    <div mat-dialog-content>
  
      <div class="flex-v">
        <mat-form-field appearance="outline">
          <mat-label>Megnevezés</mat-label>
          <input matInput formControlName="name" cdkFocusInitial>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>Egység</mat-label>
          <input matInput formControlName="unit">
        </mat-form-field>
      </div>
  
    </div>
    <div mat-dialog-actions class="flex-h flex-end">
      <button mat-flat-button color="primary" (click)="ok()" type="submit">Ok</button>
      <button mat-flat-button [mat-dialog-close]>Mégsem</button>
    </div>
  </form>
  
