<app-card #card [cardTitle]="componentTitle" [useExternalButtons]="true" [disabled]="!appState.company.value" [showEdit]="false" (editToggle)="onCardEditToggle($event)">

    <div slot="readonly">
        <ng-container *ngIf="list$ | async as list">
            <ng-container>
                <div class="flex-horizontal top-margin-off">
                    <mat-form-field appearance="outline" class="flex-fill">
                        <mat-select [(value)]="reviewPeriod" disableOptionCentering class="drop-fix select-multi-line" [readonly]="true" (selectionChange)="changePeriod($event)">                            
                            <mat-option *ngFor="let rp of list" [value]="rp">
                                {{ getDisplayText(rp) }}
                            </mat-option>                            
                        </mat-select>
                    </mat-form-field>
                    
                    <ng-container *ngIf="configMode">
                        <div *ngxPermissionsOnly="['SuperAdmin', 'Admin']" class="rp-buttons">
                            <button mat-icon-button color="primary" (click)="add()">
                                <mat-icon>add_circle_outline</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="edit()" [disabled]="!selectedReviewPeriod.value">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="delete(selectedReviewPeriod.value)" [disabled]="selectedReviewPeriod.value == null" title="Értékelés törlése">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
                
                <mat-form-field appearance="outline" class="full-width" *ngIf="configMode && reviewPeriod && !isSziluett(reviewPeriod.reviewType)">
                    <mat-label>Érvényességi időszak</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate [(ngModel)]="reviewPeriod.startDate" readonly placeholder="Kezdés">
                      <input matEndDate [(ngModel)]="reviewPeriod.endDate" readonly placeholder="Befejezés">
                    </mat-date-range-input>
                </mat-form-field>
        
                <ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                    <div *ngIf="configMode && selectedReviewPeriod.value && reviewPeriod" class="status">
                        <mat-form-field appearance="outline" class="flex-fill">
                            <mat-label>Állapot</mat-label>
                            <mat-select [(value)]="reviewPeriod.status" disableOptionCentering class="drop-fix" (selectionChange)="changeStatus($event)">
                                <mat-option *ngFor="let status of statusList" [value]="status.id">{{ status.name }}</mat-option>
                            </mat-select>                            
                        </mat-form-field>
                    </div>
                </ng-container>

                <div *ngIf="showRpButtonGroup" class="flex-h gap-2 flex-wrap">
                    <ng-container *ngIf="showStat">
                        <button mat-flat-button color="primary" (click)="downloadExport(selectedReviewPeriod.value.id)" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                            <mat-icon>article_outline</mat-icon> Statisztika
                        </button>
                    </ng-container>
                    
                    <ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                        <button mat-flat-button color="primary" (click)="showEmailAdmin(selectedReviewPeriod.value.id)">
                            <mat-icon>mail</mat-icon> E-mail
                        </button>
                        <button mat-flat-button color="primary" (click)="showUploadDialog(selectedReviewPeriod.value.id)">
                            <mat-icon>system_update_alt</mat-icon> Feltöltés
                        </button>
                        <button mat-flat-button color="primary" (click)="testDatabaseRestore()" *ngIf="isTestMode">
                            <mat-icon>list_alt_add</mat-icon> Éles adatok áthozása
                        </button>
                    </ng-container>                   
                </div>

            </ng-container>
        </ng-container>
    </div>

    <div slot="edit">
        <app-review-period-edit #editComponent [selectedReviewPeriod]="selectedReviewPeriod.value" (closeEdit)="closeEdit()" (saved)="dataSaved($event)"></app-review-period-edit>
    </div>
</app-card>
