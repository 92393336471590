import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { EmployeeAssessment } from 'src/app/models/employee-assessment.model';
import { Employee } from 'src/app/models/employee.model';
import { PagingRequest } from 'src/app/models/paging-request.model';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { Search } from 'src/app/models/search.model';
import { State } from 'src/app/models/state.model';
import { AssessmentService, StateService } from 'src/app/services';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';
import { SettingsState } from 'src/app/store/settings.state';

@UntilDestroy()
@Component({
  selector: 'app-completed-reviews',
  templateUrl: './completed-reviews.component.html',
  styleUrls: ['./completed-reviews.component.scss']
})
export class CompletedReviewsComponent implements OnInit {

  private readonly itemsSubject = new BehaviorSubject<ListItem<EmployeeAssessment>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();

  public searchControl: UntypedFormControl;
  public reviewPeriod: ReviewPeriod;
  public reviewTypes = ReviewTypeEnum;

  public minCharCount = 3;

  // paging
  public total = 0;
  public pageSize = 20;
  public pageSizeOptions = [3, 5, 20, 50, 100];
  public page = 0;

  public state: State;
  
  public searchMessage = '';
  
  public get isValidCharacterCount(): boolean {
    return this.searchControl.value.length === 0 || this.searchControl.value.length >= this.minCharCount;
  }

  @ViewChild('paginator')
  paginator: MatPaginator;
    
  public get searchValue(): string {
    return this.searchControl.value;
  }

  public setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  public get currentEmployee(): Employee {
    return this.state?.me.value?.employee;
  }

  public get isSziluettDiagramAllowed(): boolean {
    return this.state.isSuperAdmin || this.state.isAdmin || this.state.isAssistant || this.currentEmployee?.isSziluettDiagramAllowed;
  }

  constructor(
    public settingState: SettingsState, 
    public appState: AppState, 
    private assessmentService: AssessmentService,
    private stateService: StateService, 
    private msgService: ErrorMessageService) {
      this.state = stateService.state;
  }

  ngOnInit(): void {    
    this.searchControl = new UntypedFormControl('');
    
    combineLatest([this.settingState.reviewPeriod.obs$, this.settingState.reloadReviewPeriodEmployeeList.obs$])
    .pipe(untilDestroyed(this))
    .subscribe(([reviewPeriod, reload]) => {      
      if (reviewPeriod || reload)
      {
        this.reviewPeriod = reviewPeriod;
        this.loadList();
      }
    });

    this.searchControl.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.firstPage();
          this.setValidationMessage();
        }),
        switchMap(searchString => searchString.length === 0 || searchString.length >= this.minCharCount ? this.employeeSearch(searchString): of([])),
        untilDestroyed(this)
    ).subscribe(itemArray => {
      this.itemsSubject.next(itemArray);    
    });

    // this.settingState.reloadReviewPeriodEmployeeList.obs$.subscribe(data => {
    //   this.loadList();
    // });
  }

  public pagingSettingsChanged(data: PageEvent)
  {
    this.page = data.pageIndex;
    this.pageSize = data.pageSize;
    this.loadList();
  }

  private employeeSearch(searchString: string): Observable<ListItem<EmployeeAssessment>[]> {
    if (!this.settingState.reviewPeriod.value) return of([]);
    
    return this.assessmentService.getCompleted(this.buildPageRequest(searchString)).pipe(
      tap(data => {
        this.total = data.total;
        this.searchMessage = this.getResultMessage(data.list.length);
      }),
      map(result => result.list.map(x => new ListItem(x)) )
    );    
  }

  private buildPageRequest(searchString: string): Search {
    let data = {
      search: searchString,
      reviewPeriodId: this.settingState.reviewPeriod.value.id,
      pagingRequestInfo: {
        pageSize: this.pageSize,
        page: this.page + 1
      } as PagingRequest
    } as Search;

    return data;
  }

  public clearText() {
    this.searchControl.setValue('');
    //this.loadList();
  }

  private loadList() {
    this.employeeSearch(this.searchControl.value)
    .pipe(untilDestroyed(this))
    .subscribe(itemArray => {
      this.itemsSubject.next(itemArray);      
    });
  }
   
  public getStatus(status: number): string {
    return AssessmentHelper.getName(status);
  }

  public setValidationMessage() {
    if (!this.isValidCharacterCount)
      this.searchMessage = `Minimum ${ this.minCharCount } karakter szükséges`;
  }
  
  public getResultMessage(count: number): string {
      if (this.total > count)
        return `${ this.total } találatból ${ count } megjelenítve, kérem szűkítse a keresési feltételt.`;
      else if (count === 0)
        return 'Nincs találat';
      else
        return '';
  }

}
