import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { State } from 'src/app/models/state.model';
import { AuthService, SystemService } from 'src/app/services';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrl: './system.component.scss'
})
export class SystemComponent implements OnInit {

  constructor(
    private service: SystemService,
    private appState: AppState, 
    private authService: AuthService,
    private router: Router,
    private msgService: ErrorMessageService) { }
  
  public get isTestMode(): boolean {
    return this.appState?.company.value?.config?.testMode;
  } 

  ngOnInit(): void {
  }

  purgeCache() {
    this.service.purgeCache().subscribe(() => {
      this.msgService.showSnackBarMessage("cacheDeleted");
    });
  }

  testDatabaseRestore() {
    this.service.restoreTestDatabase().subscribe({ next: () => {
      
      this.msgService.showSnackBarMessage("restoreDatabaseSuccess");
      this.authService.logOut();
      this.router.navigate(['/login']);

    }, error: (err: IResultModel<ResultModel>) => {
      this.msgService.handleError(err);
    } });
  }
}
