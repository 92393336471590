<mat-card class="card-review" *ngIf="isMilio(item.reviewPeriod.reviewType)">
    <mat-card-content class="responsive-size">
        <div class="field-name">
            <div class="card-category">{{ getCategoryName(item) }}</div>
            <div class="card-title">{{ getItemName(item) }}</div>
        </div>
        <div class="field-details">
            <div class="field-block" *ngIf="!item.isRpeClosed && getRpeDeadline()">
                <div class="field-block-title">Határidő</div>
                <div class="field-block-value" [ngClass]="{'date-expired': isDateExpired(item.endDate)}">
                    {{ getRpeDeadline() | date:'yyyy.MM.dd' }}
                </div>
            </div>
            <div class="field-block" *ngIf="item.isRpeClosed">
                <div class="field-block-title">Kitöltve</div>
                <div class="field-block-value">
                    {{ getReviewDate() | date:'yyyy.MM.dd' }}
                </div>
            </div>
            <div class="field-block">
                <div class="field-block-title">Állapot</div>
                <div class="field-block-value">{{ item | assessmentStatus }}</div>
            </div>
        </div>
        <div class="card-actions">
            <button mat-flat-button class="review-button bg-alt2" *ngIf="isRpOpen(item.reviewPeriod) && !isRpeFinished(item.reviewPeriodEmployee)" (click)="loadAssessmentPage()">Kitöltés</button>
        </div>
    </mat-card-content>
</mat-card>

<mat-expansion-panel class="expansion-panel-review" hideToggle *ngIf="isSziluett(item.reviewPeriod.reviewType) || isPanorama(item.reviewPeriod.reviewType)" (opened)="changeExpand()" (closed)="changeExpand()">
    <mat-expansion-panel-header>
        <div class="field-name">
            <div class="card-category">{{ getCategoryName(item) }}</div>
            <div class="card-title">{{ getItemName(item) }}</div>
        </div>
        <div class="field-details">
            <div class="field-block">
                <div class="field-block-title">{{ item.isRpClosed ? 'Lezárva' : '' }}</div>
                <div class="field-block-value" [ngClass]="{'date-expired': isDateExpired(item.endDate)}">
                    {{ (item.isRpClosed ? item.reviewPeriod.endDate : null) | date:'yyyy.MM.dd' }}
                </div>
            </div>
            <div class="field-block" *ngIf="!isSziluett(item.reviewPeriod.reviewType) || isRpClosed(item.reviewPeriod)">
                <div class="field-block-title">Állapot</div>
                <div class="field-block-value">{{ item | assessmentStatus }}</div>
            </div>
        </div>
        <div class="card-actions">
            <button mat-flat-button color="primary" class="review-button bt-long-text">
                <mat-icon *ngIf="!expanded" >keyboard_arrow_down</mat-icon> 
                <mat-icon *ngIf="expanded">keyboard_arrow_up</mat-icon> 
                Részletek
            </button>
        </div>
    </mat-expansion-panel-header>
    <app-assessment-list-item-details [reviewPeriod]="item.reviewPeriod" [employee]="employee" [closedGroup]="closedGroup" *ngIf="loaded"></app-assessment-list-item-details>
</mat-expansion-panel>