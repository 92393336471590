<mat-card>
    <mat-card-content *ngIf="searchConfig">
        <div class="search-panel">
            <app-search title="Összehasonlítás"
                #search
                appearance="fill"
                (add)="addItem($event)"
    
                [inlineButtons]="true"
                [isAllowSearch]="true"
                [showAddButton]="true"
                [multilineDropdown]="true"
                [config]="searchConfig"></app-search>
        </div>

        <div *ngIf="items$ | async as items">
            <app-dynamic-list-item (delete)="removeItem(item)" [actionState]="actionState" [noEdit]="true" *ngFor="let item of items; index as i" [index]="i" [source]="item">                
                <div slot="readonly" class="flex-horizontal">
                    <span class="name"><strong>{{ item.itemSource.name }}</strong> ({{ item.itemSource.description }})</span>
                </div>
            </app-dynamic-list-item>
        </div>
    </mat-card-content>
</mat-card>
