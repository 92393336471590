<div *ngIf="config">

    <mat-card *ngIf="isError" class="card-light error-panel">
        <mat-card-title>Hiba</mat-card-title>
        <mat-card-content>
            {{ errorMessage }}<br>
            Az értékelés jelenleg nem elérhető.
        </mat-card-content>
    </mat-card>
    
    <div *ngIf="data">
        <div *ngIf="!success && displayReview">
            <section class="head-section">
                <div class="container container-extra no-horizontal-padding">                
                    <app-product-guide category="assessment" [productType]="data.reviewPeriodEmployee.reviewPeriod.reviewType"></app-product-guide>                    
                </div>
            </section>
            <section class="body-section">
                <div class="container container-extra">

                    <mat-toolbar *ngIf="isTestMode" class="test-actionbar">
                        <button mat-icon-button class="example-icon" aria-label="toolbar icon">
                            <mat-icon>menu</mat-icon>
                        </button>
                        <span>Teszt eszköztár</span>
                        <span class="actionbar-spacer"></span>
                        <button mat-flat-button color="primary" (click)="testComplete()" title="Automatikus kitöltés">Automata kitöltés</button>
                    </mat-toolbar>

                    <div *ngIf="reviewType === reviewTypes.TER" class="person-info-panel">
                        <h1 class="text-center text-34">
                            <span>{{ config.t.assessment.title }}</span>
                            <span *ngIf="config.c.assessment.showEmail">{{ data.colleague | employeeName:'identifier' }}</span>
                        </h1>
                        <h5 class="text-center" *ngIf="config.c.assessment.showReviewPeriod && reviewPeriodHasDate(data.reviewPeriodEmployee.reviewPeriod)">
                            Értékelési időszak: {{ data.reviewPeriodEmployee.reviewPeriod.name }}<br>
                            <div *ngIf="data.reviewPeriodEmployee.reviewPeriod?.endDate">
                                Kitöltési határidő: {{ data.reviewPeriodEmployee.reviewPeriod.endDate | date:'yyyy.MM.dd' }}
                            </div>
                        </h5>                        
                    </div>

                    <div *ngIf="reviewType === reviewTypes.MilioTeamDysfunction || reviewType === reviewTypes.MilioStressMap">
                        
                        <mat-card class="card-group card-light group-panel-margin">
                            <mat-card-content class="text-center info-block">
                                <div class="details">
                                    <div><span class="font-weight-bold">Csapatnév: </span>{{ data.reviewPeriodEmployee.reviewPeriod.name }}</div>
                                    <div><span class="font-weight-bold">Felmérésben résztvevők száma: </span>{{ data.employeeCount }}</div>
                                    <div *ngIf="data.reviewPeriodEmployee.reviewPeriod.startDate && data.reviewPeriodEmployee.reviewPeriod.endDate">
                                        <span class="font-weight-bold">Kitöltési időszak: </span>{{ data.reviewPeriodEmployee.reviewPeriod.startDate | date:'yyyy.MM.dd' }} - {{ data.reviewPeriodEmployee.reviewPeriod.endDate | date:'yyyy.MM.dd' }}
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="question card-light" *ngIf="reviewType === reviewTypes.MilioTeamDysfunction">
                            <mat-card-title>
                                Kérjük, hogy az alábbi kérdéseket figyelmesen olvasd el és őszintén válaszolj aszerint, hogy a személyes tapasztalataid és megélésed szerint az adott állítás mennyire jellemző a csapatotokra.
                            </mat-card-title>
                        </mat-card>

                        <mat-card class="question card-light" *ngIf="reviewType === reviewTypes.MilioStressMap">
                            <mat-card-title>
                                Kérjük, hogy olvasd el figyelmesen az alábbi stresszfaktorok leírását, és aszerint válaszolj, hogy az adott faktor milyen mértékben jellemzi jelenleg a mindennapi munkádat.<br>
                                Kérjük, hogy itt kifejezetten a saját személyes megéléseid alapján válaszolj, hogy rád hogyan hatnak ezek a faktorok, nem pedig a csapat egészére.
                            </mat-card-title>
                        </mat-card>
                        
                    </div>

                    <form>                            

                       
                        <app-question #questions *ngFor="let qModule of modules; index as i" 
                            [editable]="false" 
                            [textblock]="qModule"
                            [index]="i+1"
                            [questionOptions]="createQuestionOptions(data)"></app-question>
                       
            
                        <!-- <app-kpi-form #kpiform [disabled]="!isLastQuestionComplete" *ngIf="hasItems(data.keyPerformanceIndicators)" [list]="data.keyPerformanceIndicators"></app-kpi-form> -->
                       
                        <mat-form-field appearance="outline" *ngIf="isFreeTextAllowed(data.freeText)" class="freetext padding-lg">
                            <mat-label>Itt kiegészítheted a válaszaidat a szabadszavas véleményeddel, értékeléseddel is (nem kötelező), amely a riporton név nélkül fog megjelenni.</mat-label>
                            <textarea matInput name="freetext" [(ngModel)]="data.freeText.text" (change)="freeTextChanged(data.freeText)"></textarea>
                        </mat-form-field>
            
                        <validation-messages [msgList]="messages"></validation-messages>
            
                        <div class="actions">
                            <div class="button-panel">
                                <button mat-flat-button #finish color="accent" [disabled]="!isReviewCanComplete(data)" (click)="complete(data)" title="Lezárom az értékelést">Befejezés</button>
                            </div>
                            <div class="button-panel">
                                <button mat-flat-button (click)="cancel()">Vissza</button>
                            </div>
                        </div>
            
                    </form>
                </div>
            </section>
        </div>
        
        <div *ngIf="success" class="success-panel">
            <section class="head-section">
                <div class="container container-extra no-horizontal-padding">
                    <h3 class="text-center margin-bottom-xl" *ngIf="data.reviewPeriodEmployee.reviewPeriod.reviewType != reviewTypes.PersonalityTest">Köszönjük!</h3>

                    <article class="margin-bottom-xl" *ngIf="data.reviewPeriodEmployee.reviewPeriod.reviewType == reviewTypes.PersonalityTest">
                        <h3 class="text-center">Köszönjük, hogy kitöltötte felmérésünket!</h3>
                        <p class="text-center">Egyéni riportját megtekintheti, lementheti vagy kinyomtathatja itt a rendszerünkben.</p>
                    </article>
                </div>
            </section>
            <section class="body-section">
                <div class="container container-extra">
                    
                    <div class="feedback-panel">
                        <mat-card class="card-light" *ngIf="data.reviewPeriodEmployee.reviewPeriod.reviewType == reviewTypes.PersonalityTest">
                            <mat-card-header>
                                <mat-card-title>
                                    Visszajelzés
                                </mat-card-title>
                                <mat-card-subtitle>
                                    Ossza meg velünk bátran javaslatait, észrevételeit, megjegyzéseit a kérdőívvel kapcsolatban.<br>
                                    (opcionális)
                                </mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-form-field appearance="outline">
                                    <textarea matInput name="feedback" [(ngModel)]="feedback" title="Visszajelzés" [ngModelOptions]="{debounce: 1000, allowInvalid: false, standalone: true}" autocomplete="off" (change)="saveFeedback(data)"></textarea>
                                </mat-form-field>                        
                                
                            </mat-card-content>
                        </mat-card>
                        
                        <div class="actions">
                            <button mat-flat-button color="primary" [routerLink]="['/']">Vissza a főoldalra</button>
                            <button mat-flat-button color="primary" [routerLink]="getReportLink(data)" *ngIf="data.reviewPeriodEmployee.reviewPeriod.reviewType == reviewTypes.PersonalityTest">Riport megtekintése</button>
                        </div>
                    </div>

                </div>
            </section>           
                        
        </div>

    </div>    
</div>

<app-page-progress *ngIf="!success" [value]="progressValue" [currentItem]="completeCount"  [maxItems]="allQuestionsCount"></app-page-progress>