import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, debounceTime } from 'rxjs';
import { ActionModel } from 'src/app/models/action.model';
import { Employee } from 'src/app/models/employee.model';
import { Goal, GoalModel, InsertGoal } from 'src/app/models/goal.model';
import { GoalPeriod } from 'src/app/models/goalperiod.model';
import { GoalText, GoalTextModel } from 'src/app/models/goaltext.model';
import { GroupGoal } from 'src/app/models/group-goal.model';
import { GroupGoalsData } from 'src/app/models/group-goals-data.model';
import { GoalService } from 'src/app/services';
import { ISelectorDialogModel, ISelectorDialogResult, SelectorDialogComponent } from 'src/app/shared/components';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ISelectorListItem } from 'src/app/shared/models/selector-list-item.model';


@UntilDestroy()
@Component({
  selector: 'app-goal-edit',
  templateUrl: './goal-edit.component.html',
  styleUrls: ['./goal-edit.component.scss']
})
export class GoalEditComponent implements OnInit {
  
  @Input()  
  public goal: GoalModel;

  @Input()
  public data: GroupGoalsData;

  @Input()
  public leaders: Employee[];
    
  @Input()
  public currentPeriod: GoalPeriod;  

  @Input()
  public quartersOrder: string[];
  
  public goalTextOption: SearchOption<GoalText, number>;

  private goalModelChanged: Subject<string> = new Subject<string>();

  @Output()
  public added = new EventEmitter<Goal>();

  @Output()
  public updated = new EventEmitter<Goal>();

  @Output()
  public deleted = new EventEmitter<number>();

  constructor(private goalService: GoalService, public dialog: MatDialog) {
    
  }

  ngOnInit(): void {

    this.goalModelChanged.pipe(
      debounceTime(800),
      untilDestroyed(this)
    ).subscribe(() => {
      this.saveGoal(this.goal);
    });

  }
  
  isValidAddGoal(goal: Goal): boolean {
      return goal.goalText.name != '' || goal.questionId != null || goal.goalTextId != null;
  }     

  addGoal(newGoal: Goal) {
    let insertGoal = newGoal as InsertGoal;
    insertGoal.reviewPeriodId = this.currentPeriod.reviewPeriod.id;
    insertGoal.employeeId = this.currentPeriod.employeeId;

      this.data.loading = true;
      this.goalService.insertGoal(insertGoal).subscribe({ next: (goal) => {        
          this.data.loading = false;
          this.goal.id = goal.id;
          this.added.emit(goal);
      }, error: (err) => {
          console.log(err);
          this.data.loading = false;
      }});
  };
   
  deleteGoal() {
    if (this.goal.id == 0)
    {
        this.currentPeriod.goals = this.currentPeriod.goals.filter((item) => { return item.id != this.goal.id });
        this.deleted.emit(this.goal.id);
        return;
    }

    if (confirm("Biztosan törli a célkitűzést?")) {
        this.data.loading = true;

        this.goalService.deleteGoal(this.goal.id).subscribe({next: (result) => {
            this.currentPeriod.goals = this.currentPeriod.goals.filter((item) => { return item.id != this.goal.id });
            this.data.loading = false;
            this.deleted.emit(this.goal.id);
        }, error: (err) => {
            console.log(err);
            this.data.loading = false;
        }});
    }
};  

  updateGoal(goal: Goal) {
      if (goal.goalText.name || goal.questionId) {
          if (goal.targetValue !== null && goal.targetValue.toString() === "")
          {
            goal.targetValue = null;
          }

          this.data.loading = true;
          this.goalService.updateGoal(goal).subscribe({ next: (result) => {
              this.data.loading = false;
          }, error: (err) => {
              if (confirm('Hiba történt a mentés során, megpróbálja újra?')) {
                  this.updateGoal(goal);
              }
              console.log(err);
              this.data.loading = false;
          }});
      }
  };

  goalChanged() { 
    this.goalModelChanged.next('');
  }

  saveGoal(goal: Goal) {
      if (this.isValidAddGoal(goal)){
          goal.id ? this.updateGoal(goal) : this.addGoal(goal);
          console.log('goal saved');
      }
  }

  addAction(goal: GoalModel) {
    let found = goal.actions.find(x => x.id == 0);
    
    
    if (!found)
    {
        const newAction = new ActionModel();
        newAction.leader = this.leaders.length > 0 ? this.leaders[0] : [];
        newAction.leaderId = newAction.leader?.id;
        newAction.leaderProgress = 0;
        goal.actions.push(newAction);
    }
  }  
  
  groupGoalChanged(groupGoal: GroupGoal) {
    if (!groupGoal)
    {
        this.goal.groupGoalId = null;
    }
    else
    {
        this.goal.groupGoalId = groupGoal.id;
    }

    this.saveGoal(this.goal);
  }

  actionDeleted(id: number) {
    this.goal.actions = this.goal.actions.filter(x => x.id != id);
  }


  public toListItem(goalText: GoalText): ISelectorListItem {
    if (!goalText) return null;

    return goalText ? { id: goalText.id, text: goalText.name} as ISelectorListItem : null;
  }

  selectGoal() {
    //popup

    this.goalService.getGoalTexts().subscribe((result) => {
        
        let items = result.map(item => this.toListItem(item));

        items = [{ id: null, text: 'Nincs kiválasztva' }, ...items];

        const dialogRef = this.dialog.open(SelectorDialogComponent, {
            width: '840px',            
            data: { title: 'Minta célkitűzések', selected: this.toListItem(this.goal.goalText), items: items } as ISelectorDialogModel
          });
      
          dialogRef.afterClosed().subscribe( (dialogResult: ISelectorDialogResult) => {

            // ok lett nyomva
            if (dialogResult) {
              this.goal.goalText = dialogResult.selectedItem ? result.find(x => x.id === dialogResult.selectedItem) : new GoalTextModel();
              this.goal.goalTextId = this.goal.goalText.id;

              this.saveGoal(this.goal);
            }
          });
    });

  }
  
}
