import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { Employee } from 'src/app/models/employee.model';
import { ReviewPeriodForEmployee } from 'src/app/models/review-period-for-employee.model';
import { DateHelper } from 'src/app/shared/helpers';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-assessment-list-item',
  templateUrl: './assessment-list-item.component.html',
  styleUrl: './assessment-list-item.component.scss'
})
export class AssessmentListItemComponent {
  @Input() public item: ReviewPeriodForEmployee;
  @Input() public employee: Employee;
  public expanded = false;
  public loaded = false;

  @Input() public closedGroup = false;

  isSziluett = ReviewPeriodHelper.isSziluett;
  isMilio = ReviewPeriodHelper.isMilio;
  isPanorama = ReviewPeriodHelper.isPanorama;
  isRpOpen = ReviewPeriodHelper.isRpOpen;
  isRpClosed = ReviewPeriodHelper.isRpClosed;
  isRpeFinished = AssessmentHelper.isRpeFinished;

  constructor(private router: Router, private msgService: ErrorMessageService) { }

  getCategoryName(rpForEmployee: ReviewPeriodForEmployee): string {
      if (rpForEmployee.reviewPeriod.reviewType == ReviewTypeEnum.PersonalityTest)
          return 'Sziluett személyiségfelmérés';
      else 
          return ReviewPeriodHelper.getName(rpForEmployee.reviewPeriod.reviewType);
  }

  getItemName(rpForEmployee: ReviewPeriodForEmployee): string {
      if (rpForEmployee.reviewPeriod.reviewType == ReviewTypeEnum.PersonalityTest && rpForEmployee.reviewPeriod.name == "2.0")
          return 'Sziluett 2.0';
      else
          return rpForEmployee.reviewPeriod.name;
  }

  isDateExpired(date: Date): boolean {
    if (!date) return false;
    
    return DateHelper.isDateExpired(date);
  }

  getClosedOrExpirationDateRpe(): Date {
    return this.item.isRpeClosed ? this.item.reviewPeriodEmployee.endDate : this.item.reviewPeriodEmployee.deadline;
  }

  // ha nincs kitöltve akkor a határidő, egyébként a reviewPeriod kijelölt vége
  getRpeDeadline(): Date {
    return this.item.reviewPeriodEmployee.deadline ?? this.item.reviewPeriod.endDate;
  }

  // kitöltés dátuma
  getReviewDate(): Date {
    return this.item.reviewPeriodEmployee.closedOn;
  }

  loadAssessmentPage() {
    this.router.navigate(AssessmentHelper.getAssessmentLink(this.item.reviewPeriodEmployee.reviewPeriodEmployeeId));       
  }

  changeExpand() {
    this.expanded = !this.expanded;
    if (this.expanded && !this.loaded) {
        this.loaded = true;
    }
  }
  
}
